import { ImageSize, Ratio, addQueryParam, isRatio } from '@canalplus/mycanal-commons';
import { mapStaticKey } from '@canalplus/mycanal-util-react';
import { Template } from '@canalplus/sdk-hodor';
import { Tracking } from '@dce-front/hodor-types/api/v2/common/dto/tracking/definitions';
import { ApiV2ContentGrid } from '@dce-front/hodor-types/api/v2/content_grid/definitions';
import { PAGINATION_CONTENT_GRID } from '../../../constants/strates';
import { getContentWithContexts } from '../../../helpers/contents/contents-helper';
import { FromProp } from '../../../server/modules/fetchWithQuery/types';
import { hasPageCover } from '../../../store/slices/page';
import getFormattedIdentityCover from '../../../templates/helpers/IdentityV5Helpers/data/getFormattedIdentityCover';
import { ContentGridState } from './types';

export type FormatContentGridType = Omit<ApiV2ContentGrid, 'displayParameters'> & {
  displayParameters: ApiV2ContentGrid['displayParameters'] & {
    imageSize?: ImageSize;
  };
  tracking?: Tracking;
};

export const getFormattedContentGrid = (
  data: FormatContentGridType,
  dispatch?: Redux.Dispatch,
  featIdentityV5: boolean = false
): ContentGridState => {
  const { context, contents, tracking, paging, displayParameters, currentPage, covers } = data || {};
  const { imageRatio, titleDisplayMode, imageSize = 'normal' } = displayParameters || {};
  const contentWithStaticKey = mapStaticKey(contents, ['title', 'contentID']);

  const formatedCover = getFormattedIdentityCover(covers, featIdentityV5);

  if (formatedCover && !!dispatch) {
    dispatch(hasPageCover(true));
  }

  return {
    contents: contentWithStaticKey?.map((content) => {
      if (!content.onClick) {
        return content;
      }
      return getContentWithContexts(content, context);
    }),
    cover: formatedCover,
    currentPage,
    displayParameters: {
      imageRatio: isRatio(imageRatio) ? imageRatio : Ratio.Ratio169,
      titleDisplayMode,
      imageSize,
    },
    tracking,
    paging,
  };
};

type GetFormattedContentGridUrlParameters = { url?: string } & FromProp;
/**
 * The first url received in the contentgrid in gabaritlist 100 items
 * which is bad for performance, so we reduced to 50 items on first render
 */
export const getFormattedContentGridUrl = ({ from, url }: GetFormattedContentGridUrlParameters): string | undefined =>
  url && typeof url === 'string' && from === Template.GabaritList
    ? addQueryParam(url, 'get', String(PAGINATION_CONTENT_GRID))
    : url;
