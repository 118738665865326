import { useSelector } from 'react-redux';
import { navigationSelector } from '../../../selectors/application/application-selectors';
import { activeNavigationPathSelector } from '../../../selectors/header/header-selectors';
import HeaderNavigationTv from './HeaderNavigationTv';

export function HeaderNavigationTvConnected(): JSX.Element {
  const activePath = useSelector(activeNavigationPathSelector);
  const navigation = useSelector(navigationSelector);

  return <HeaderNavigationTv activePath={activePath} navigation={navigation} />;
}
