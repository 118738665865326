import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../../../../../helpers/hooks/useAppDispatch';
import { userChangeSettings } from '../../../../../../../../store/slices/user';
import { areAnimationsAutoplaySelector, settingsSelector } from '../../../../../../../../store/slices/user-selectors';

/**
 * Users can enable/disable the animations autoplay
 * This value is stored in local storage and redux store
 */
export const useAreAnimationsAutoplay = (): {
  areAnimationsAutoplay: boolean;
  toggleAreAnimationsAutoplay: () => void;
} => {
  const dispatch = useAppDispatch();

  const areAnimationsAutoplay = useSelector(areAnimationsAutoplaySelector);
  const settings = useSelector(settingsSelector);

  const toggleAreAnimationsAutoplay = () => {
    dispatch(userChangeSettings({ ...settings, areAnimationsAutoplay: !areAnimationsAutoplay }));
  };

  return {
    areAnimationsAutoplay,
    toggleAreAnimationsAutoplay,
  };
};
