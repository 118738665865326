import { ProfileModal } from '@canalplus/mycanal-commons';
import { PassMedia } from '@canalplus/mycanal-sdk';
import { saveProfile as fetchSaveProfile, requestApi } from '@canalplus/sdk-hodor';
import { ApiV2Profile } from '@dce-front/hodor-types/api/v2/me/profiles/definitions';
import { SyntheticEvent } from 'react';
import type { UseUpdateListProfiles } from '../../components/Profiles/ProfileSelectorMyCanal/hooks/useUpdateListProfiles';
import { ThemeColor } from '../../constants/themeColor';
import { ProfileEvents, ProfileType } from '../../constants/tracking';
import Logger from '../../helpers/logger/logger-helper';
import { sendProfileTrackingEvent } from '../../helpers/tracking/tracking-helper';
import { AvatarTrackingData } from '../../helpers/tracking/types';
import { getProfilesListUrl } from '../../helpers/url/url-helper';
import { hodorSdkConfigSelector, portailIdSelector } from '../../selectors/application/application-selectors';
import { displayTVModeSelector } from '../../store/slices/displayMode-selectors';
import { pageURLSelector } from '../../store/slices/page-selectors';
import { setProfileView } from '../../store/slices/profilesModal';
import { modalCurrentViewSelector } from '../../store/slices/profilesModal-selectors';
import { displayProfileMenu } from '../../store/slices/user';
import { isKidsProfileSelector, profilesSelector, segTypeSelector } from '../../store/slices/user-selectors';

export type ProfileToSend = Pick<ApiV2Profile, 'displayName' | 'isKidsProfile' | 'profileToken' | 'profileId'> & {
  avatar: {
    contentID: number;
  };
};

/**
 * Fetch Hodor profile Api to save profile
 * Send profile tracking event
 */
export const saveProfile =
  (
    profileToSend: ProfileToSend,
    event: SyntheticEvent,
    avatarTrackingData: AvatarTrackingData,
    updateListProfiles: UseUpdateListProfiles['updateListProfiles'],
    setOpenModal?: (isModalOpen: boolean) => void
  ): Redux.ThunkAction<Promise<void>> =>
  async (dispatch, getState) => {
    const state = getState();

    const hodorSdkConfig = hodorSdkConfigSelector(state);
    if (!hodorSdkConfig) {
      Logger.error('[SAVE_PROFILE] hodorSdkConfig is undefined');
      return;
    }

    const portailId = portailIdSelector(state);
    const url = getProfilesListUrl(PassMedia.Default, portailId);

    const newProfile = await fetchSaveProfile(hodorSdkConfig, {
      body: JSON.stringify(profileToSend),
      url,
    });

    await updateListProfiles();

    dispatch(setProfileView(ProfileModal.ProfileListView));

    const currentView = modalCurrentViewSelector(state);
    if (currentView === ProfileModal.ProfileCreationViewFromLanding) {
      dispatch(displayProfileMenu(true));
      setOpenModal?.(false);
    }

    const { profileId, isKidsProfile } = newProfile || {};
    if (profileId === undefined) {
      Logger.error("[SAVE_PROFILE] Tracking wasn't send: profileId is undefined");
      return;
    }

    sendProfileTrackingEvent({
      activeProfiles: profilesSelector(state).length,
      event,
      isKids: isKidsProfileSelector(state),
      pageURL: pageURLSelector(state),
      segType: segTypeSelector(state),
      themeColor: ThemeColor.Dark,
      version: $_BUILD_APP_VERSION,
      profileId: profileId.toString(),
      profileType: isKidsProfile ? ProfileType.Kids : ProfileType.Standard,
      type: ProfileEvents.Create,
      avatarTrackingData,
      isTvDevice: displayTVModeSelector(state),
    });
  };

/**
 * Fetch Hodor profile Api to update profile
 * Send profile tracking event
 */
export const updateProfile =
  (
    profileToSend: ProfileToSend,
    event: SyntheticEvent,
    avatarTrackingData: AvatarTrackingData,
    updateListProfiles: UseUpdateListProfiles['updateListProfiles'],
    setOpenModal?: (isModalOpen: boolean) => void
  ): Redux.ThunkAction<Promise<void>> =>
  async (dispatch, getState) => {
    const state = getState();

    const hodorSdkConfig = hodorSdkConfigSelector(state);
    if (!hodorSdkConfig) {
      Logger.error('[UPDATE_PROFILE] hodorSdkConfig is undefined');
      return;
    }

    const portailId = portailIdSelector(state);
    const url = getProfilesListUrl(PassMedia.Default, portailId);

    await requestApi(hodorSdkConfig, {
      apiName: 'updateProfile',
      body: JSON.stringify(profileToSend),
      hodorApiParameter: [{ id: 'tokenPass', in: 'header' }],
      method: 'POST',
      url,
    });

    await updateListProfiles();

    dispatch(setProfileView(ProfileModal.ProfileListView));

    const currentView = modalCurrentViewSelector(state);
    if (currentView === ProfileModal.ProfileCreationViewFromLanding) {
      dispatch(displayProfileMenu(true));
      setOpenModal?.(false);
    }

    const { profileId, isKidsProfile } = profileToSend;
    if (profileId === undefined) {
      Logger.error("[UPDATE_PROFILE] Tracking wasn't send: profileId is undefined");
      return;
    }

    sendProfileTrackingEvent({
      activeProfiles: profilesSelector(state).length,
      event,
      isKids: isKidsProfileSelector(state),
      pageURL: pageURLSelector(state),
      segType: segTypeSelector(state),
      themeColor: ThemeColor.Dark,
      version: $_BUILD_APP_VERSION,
      profileId: profileId.toString(),
      profileType: isKidsProfile ? ProfileType.Kids : ProfileType.Standard,
      type: ProfileEvents.Edit,
      avatarTrackingData,
      isTvDevice: displayTVModeSelector(state),
    });
  };

/**
 * Fetch Hodor profile Api to delete a profile
 * Send profile tracking event
 */
export const deleteProfile =
  (
    { isKidsProfile, profileToken, profileId }: Pick<ApiV2Profile, 'isKidsProfile' | 'profileToken' | 'profileId'>,
    updateListProfiles: UseUpdateListProfiles['updateListProfiles'],
    event: SyntheticEvent
  ): Redux.ThunkAction<Promise<void>> =>
  async (dispatch, getState) => {
    const state = getState();

    const hodorSdkConfig = hodorSdkConfigSelector(state);
    if (!hodorSdkConfig) {
      Logger.error('[DELETE_PROFILE] hodorSdkConfig is undefined');
      return;
    }

    const portailId = portailIdSelector(state);
    const url = getProfilesListUrl(PassMedia.Default, portailId);

    await requestApi(hodorSdkConfig, {
      apiName: 'deleteProfile',
      body: JSON.stringify({ profileToken }),
      hodorApiParameter: [{ id: 'tokenPass', in: 'header' }],
      method: 'DELETE',
      url,
    });

    await updateListProfiles();

    dispatch(setProfileView(ProfileModal.ProfileListView));

    if (profileId === undefined) {
      Logger.error("[DELETE_PROFILE] Tracking wasn't send: profileId is undefined");
      return;
    }

    sendProfileTrackingEvent({
      activeProfiles: profilesSelector(state).length,
      event,
      isKids: isKidsProfileSelector(state),
      pageURL: pageURLSelector(state),
      segType: segTypeSelector(state),
      themeColor: ThemeColor.Dark,
      version: $_BUILD_APP_VERSION,
      profileId: profileId?.toString(),
      profileType: isKidsProfile ? ProfileType.Kids : ProfileType.Standard,
      type: ProfileEvents.Delete,
      isTvDevice: displayTVModeSelector(state),
    });
  };
