import { bindActionCreators, type ActionCreatorsMapObject } from '@reduxjs/toolkit';
import { useMemo } from 'react';
import type { userListService } from '../../../services/PlaylistService';
import type { AllActionsTypes } from '../../../store/reducers/actions/All-actions-types';
import { useAppDispatch } from '../useAppDispatch';

/** Return a memoized version of the Redux `actionCreators`, bound to dispatch. */
export const useActionCreators = <
  T extends ActionCreatorsMapObject<AllActionsTypes | ReturnType<typeof userListService>>,
>(
  actionCreators: T
): T => {
  const dispatch = useAppDispatch();

  return useMemo(() => bindActionCreators(actionCreators, dispatch), [actionCreators, dispatch]);
};
