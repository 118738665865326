import { PlaysetQuality, isClientSide } from '@canalplus/mycanal-commons';
import { ApiV2Context } from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import { Tracking } from '@dce-front/hodor-types/api/v2/common/dto/tracking/definitions';
import { ApiV2PageTracking } from '@dce-front/hodor-types/api/v2/page/dtos/definitions';
import { PlayerPlatform } from '../../../constants/playerPlatforms';
import { ThemeColor } from '../../../constants/themeColor';
import Logger from '../../../helpers/logger/logger-helper';
import { isR7Available } from '../../../helpers/window/window-helper';
import { type PlayerInstance, initializePlayer } from '../../../store/slices/player';
import { getFinalPlayerSettings } from './getFinalPlayerSettings';
import { CommonProps } from './types';

export type CreatePlayerInstanceProps = CommonProps & {
  isFeatureTogglePositionSeconds: boolean;
  tracking: Tracking | ApiV2PageTracking;
  trackingContext: ApiV2Context;
  isKids: boolean;
};

export function createPlayerInstance({
  adToken,
  credentials,
  consentString,
  dispatch,
  enableAd,
  isFeatureTogglePositionSeconds,
  isTvDevice,
  isFeatDidomi,
  locale,
  offerLocation,
  offerZone,
  playerContainerElement,
  settings,
  targetedAds,
  tracking,
  trackingContext,
  onKillPlayer,
  isKids,
}: CreatePlayerInstanceProps): PlayerInstance | undefined {
  const { credentials: finalCredentials, settings: finalSettings } = getFinalPlayerSettings({
    baseSettings: settings,
    credentials,
    dispatch,
    enableAd,
    locale,
    offerLocation,
    offerZone,
    onKillPlayer,
  });

  const startAt = finalSettings.params?.startAt as number;
  if (isTvDevice && isClientSide() && isR7Available()) {
    const { tokenCMS } = finalCredentials;

    if (tracking.dataLayer) {
      const launchPlayerPageName = tracking?.dataLayer?.page_name?.replace(/Page/g, 'Lecture');

      window.R7('trackSendPageView', {
        data: {
          ...tracking.dataLayer,
          ...trackingContext,
          ...(trackingContext?.context_type && { prop17: trackingContext?.context_type }),
          ...(trackingContext?.contextDetail && { prop18: trackingContext?.contextDetail }),
          eVar37: 'ALD',
          eVar38: 'Regarder ALD',
          themeColor: ThemeColor.Dark,
          profile_kids: isKids,
          page_name: launchPlayerPageName,
        },
        name: launchPlayerPageName,
      });
    }

    if (finalSettings.platform === PlayerPlatform.Live) {
      window.R7('zap', {
        channelId: finalSettings.content,
        tokenCMS,
        ...(isFeatureTogglePositionSeconds && startAt ? { positionSeconds: startAt } : {}),
      });
    } else {
      window.R7(
        'navigate',
        {
          control: 'video_player',
          context: {
            broadcastID: finalSettings?.params?.broadcastId,
            consumptionPlatform: finalSettings.platform,
            contentFunctionalType: 'primary',
            contentId: finalSettings.content,
            mode: 'streaming',
            // "position" of R7('navigate') method of oneCore needs a value in milliseconds because it converts this value in second to onePlayer
            // TODO : Only use positionSeconds
            ...(isFeatureTogglePositionSeconds ? { positionSeconds: startAt } : { position: startAt * 1000 }),
            quality: PlaysetQuality.Hd,
          },
          tokenCMS,
        },
        (error?: Error) => {
          if (error) {
            Logger.error('an error occurred when trying to play the content:', error);
          }
        }
      );
    }
  } else {
    const player: PlayerInstance = new (global as any).OnePlayer();

    // setAdData should be called before loadVideo
    player.setAdData({
      gdpr: 1,
      targetedAds,
      ...(consentString && { consentString }),
      // conditionally add adToken to the adData object depending on the value of targetedAds and isFeatDidomi
      ...(((isFeatDidomi && adToken) || (adToken && targetedAds === '1')) && { targeting: adToken }),
    });
    player.loadVideo(finalSettings, finalCredentials, playerContainerElement);

    dispatch(initializePlayer({ playerInstance: player }));

    if (playerContainerElement) {
      playerContainerElement.focus();
    }

    return player;
  }

  return undefined;
}
