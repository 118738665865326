import classNames from 'classnames';
import { cloneElement } from 'react';
import { useDiveContext } from '../../../context';
import { MediaImage } from '../../Media';
import { HeaderLogoProps } from './HeaderLogo.types';

/**
 * A logo component to display in the header. It is essentially composed of:
 *
 * **Child component (itself)**:
 * - A simple `<img />` by passing the `src` prop and the `alt` prop.
 * - A custom component for the logo by passing the `customComponent` prop, like an SVG icon directly.
 *
 * **Wrapper:**
 * - `None` by default.
 * - Link `<a>` by passing the `href` prop.
 *
 * @example
 * <HeaderLogo
 *    alt="alt"
 *    src="https://[path].{svg|png|jpg|...}"
 * />
 */
export function HeaderLogo({
  className,
  alt,
  src,
  customComponent,
  href,
  'data-testid': dataTestId,
}: HeaderLogoProps): JSX.Element {
  const { isTv } = useDiveContext();
  const imgStyles = classNames('inline-block', isTv ? 'h-48 tv-focus-self:outline-none' : 'h-32');

  const component = customComponent ? (
    cloneElement(customComponent, { className: classNames(imgStyles, className) })
  ) : (
    <MediaImage height={isTv ? 48 : 32} width="auto" src={src} alt={alt} />
  );

  if (href) {
    return (
      <a data-testid={dataTestId} href={href} className={classNames(imgStyles, className)}>
        {component}
      </a>
    );
  }

  return component;
}
