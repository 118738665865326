import { memo, ReactNode } from 'react';
import styles from './SlideDown.css';

export type SlideDownProps = {
  children: ReactNode | null;
  height: string | number;
  isOpened: boolean;
};

function SlideDown({ children, isOpened, height }: SlideDownProps): JSX.Element {
  const style = { height: isOpened ? height : 0 };

  return (
    <div aria-hidden={!isOpened} className={styles.slideDown} style={style} role="alert">
      {children}
    </div>
  );
}

export default memo(SlideDown);
