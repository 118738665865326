import { useListItem, useMergeRefs } from '@floating-ui/react';
import { forwardRef } from 'react';
import { DropdownTriggerProps } from './Dropdown.types';
import { useDropdownContext } from './provider';

/**
 * DropdownTrigger triggers the opening of the Dropdown component.
 * Renders as a `<button>` element by default, but it can be customized using the `renderTrigger` prop.
 *
 * **Note:** Used internally already by the `Dropdown` component, but can be used separately as well
 * (e.g. nested in a DropdownMenu).
 *
 * Caveats of using renderTrigger:
 *  - The custom component must be able to forward the `ref`, and also accept additional props `{...props}`
 *    which might contain a11y props, event handlers, floating-ui data-attributes, etc.
 *  - The `isActive`, `isNested` and `triggerProps` will be passed to the custom component
 *    - `isActive` is true if the trigger is the active item
 *    - `isNested` is true if the trigger is inside a nested dropdown
 *    - `triggerProps` contains the necessary props to be spread on the custom component (required)
 *
 * @returns A focusable trigger with necessary A11y props and floating-ui data-attributes.
 * @example
 *   <DropdownTrigger label="My dropdown content" />
 *   <DropdownTrigger label="My dropdown content" disabled />
 *   <DropdownTrigger renderTrigger={({ isActive, isNested, triggerProps }) => <MyCustomComponent isActive isNested {...triggerProps} />} />
 */
export const DropdownTrigger = forwardRef<HTMLButtonElement, DropdownTriggerProps>(
  ({ label, className, disabled, renderTrigger, ...props }, forwardedRef): JSX.Element => {
    const { isOpen, isNested, activeIndex, getReferenceProps } = useDropdownContext();

    const { ref, index } = useListItem({ label: disabled ? null : label });
    const mergedRef = useMergeRefs([ref, forwardedRef]);
    const isActive = isNested && index === activeIndex;

    /**
     * Roving tabindex A11y management
     * @see https://www.w3.org/WAI/ARIA/apg/practices/keyboard-interface/#kbd_roving_tabindex
     */
    const nestedTabIndex = isActive ? 0 : -1;

    // @floating-ui necessary props
    const triggerProps = {
      ref: mergedRef,
      ...(isNested && { role: 'menuitem', 'data-nested': '', tabIndex: nestedTabIndex }),
      ...(isOpen && { 'data-open': '' }),
      ...getReferenceProps({
        ...props,
        onFocus(event: React.FocusEvent<HTMLButtonElement>) {
          props?.onFocus?.(event);
        },
      }),
    };

    if (renderTrigger) {
      return renderTrigger({ isActive, isNested, triggerProps });
    }

    return (
      <button type="button" className={className} disabled={disabled} {...triggerProps}>
        {label && (
          <>
            {label}
            {isNested && (
              <span aria-hidden className="ml-2 text-xs">
                ▶
              </span>
            )}
          </>
        )}
      </button>
    );
  }
);

DropdownTrigger.defaultProps = {
  disabled: false,
  className: undefined,
};
