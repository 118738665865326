import classNames from 'classnames';
import IconRightArrow from '../../assets/svg/rightArrow.svg';
import Button from '../Button/Button';
import styles from './ButtonSettings.css';

type ButtonSettingsProps = {
  handler: () => void;
  hasActionIcon?: boolean;
  subText?: string;
  text: string;
  isTvDevice?: boolean;
  ariaTitle?: string;
};

export function ButtonSettings({
  ariaTitle,
  handler,
  hasActionIcon = false,
  text,
  subText,
  isTvDevice = false,
}: ButtonSettingsProps): JSX.Element {
  return (
    <div className={classNames(styles.buttonSettings, 'buttonSettings')}>
      <Button
        ariaLabel={ariaTitle}
        className={classNames(styles.buttonSettings__button, 'buttonSettings__button')}
        handler={handler}
        text={text}
        subtext={subText}
        icon={
          hasActionIcon && isTvDevice ? (
            <IconRightArrow className={classNames(styles.buttonSettings__icon, 'buttonSettings__icon')} />
          ) : undefined
        }
        hasActionIcon={hasActionIcon}
      />
    </div>
  );
}
