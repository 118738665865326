import { QueryKey } from '@tanstack/react-query';
import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import type { FetchDetails } from '../../../services/types';
import Logger from '../../logger/logger-helper';

export const useGenerateQueryKey = (url: string | undefined, fetchDetails: FetchDetails): QueryKey => {
  const { template, options } = fetchDetails;

  // If no URL (queryKey), we use a random generated key with no caching
  // ex: ContentGridContainer, ContentRowPersoContainer
  const randomKey = useMemo(() => `${template}-${uuidv4()}`, [template]);

  const { profileId, isPerso, queryKeyPrefix } = options || {};
  let actualQueryKey: QueryKey;

  if (!url) {
    // Check queryKey and log if it's incorrect
    Logger.debug(`Your url is undefined, a randomKey was generated: ${randomKey}`);
    actualQueryKey = [randomKey];
  } else {
    actualQueryKey = [url, profileId, isPerso].filter((elem) => elem !== undefined);
  }

  // Prefixes queries with queryKeyPrefix when defined. This allows us to easily refetch by this query,
  // by partially targeting it with the queryKeyPrefix
  if (queryKeyPrefix) {
    actualQueryKey = [...(typeof queryKeyPrefix === 'string' ? [queryKeyPrefix] : queryKeyPrefix), ...actualQueryKey];
  }

  return actualQueryKey;
};
