import { createSelector } from '@reduxjs/toolkit';
import { initialState, type ContextFeatureState } from '../../store/slices/contextFeature';
import { IState } from '../../store/types/State-type';

const contextFeatureSelector = (state: IState): ContextFeatureState => state?.contextFeature ?? initialState;

export const isFeatIframeHeightAllowedSelector = createSelector(
  contextFeatureSelector,
  (contextFeature: ContextFeatureState): ContextFeatureState['isFeatIframeHeightAllowed'] =>
    contextFeature?.isFeatIframeHeightAllowed ?? initialState.isFeatIframeHeightAllowed
);

export const isFeatImmersiveFullAllowedSelector = createSelector(
  contextFeatureSelector,
  (contextFeature: ContextFeatureState): ContextFeatureState['isFeatImmersiveFullAllowed'] =>
    contextFeature?.isFeatImmersiveFullAllowed ?? initialState.isFeatImmersiveFullAllowed
);
