import { Binder, useActiveLayer, useStore } from '@canalplus/one-navigation';
import classNames from 'classnames';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../../../../helpers/hooks/useAppDispatch';
import { LAYER_MORE_SETTINGS_RECO } from '../../../../../../../helpers/oneNavigation/layers';
import I18n from '../../../../../../../lang';
import { displayTVModeSelector } from '../../../../../../../store/slices/displayMode-selectors';
import { hasUserDataCollectedSelector } from '../../../../../../../store/slices/user-selectors';
import { updateRecoPersoTV } from '../../../../../../../store/slices/user-thunk';
import DialogModal from '../../../../../../../templates/DetailV5/components/ActionLayout/DialogModal/DialogModal';
import { ButtonSettings } from '../../../../../../ButtonSettings/ButtonSettings';
import styles from './RecoPerso.css';

export type RecoPersoBinderProps = {
  id: string;
  children: React.ReactNode;
  isTvDevice: boolean;
};
export type RecoPersoProps = {
  title: string;
};

function RecoPerso(props: RecoPersoProps): JSX.Element {
  const { title } = props;

  const hasUserDataCollected = useSelector(hasUserDataCollectedSelector);
  const isTvDevice = useSelector(displayTVModeSelector);
  const dispatch = useAppDispatch();

  const { t } = I18n.useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const store = useStore();

  useActiveLayer(LAYER_MORE_SETTINGS_RECO, true);

  // Content of recommendation setting
  const recoList: JSX.Element[] = Array.from({ length: 6 }).map((_, index) => (
    <li key={t(`SettingsTemplate.list.item${index + 1}`)}>{t(`SettingsTemplate.list.item${index + 1}`)}</li>
  ));

  const handleRecoPerso = async () => {
    if (hasUserDataCollected) {
      return setIsModalOpen(true);
    }

    await dispatch(updateRecoPersoTV({ hasUserDataCollected: true }));
  };

  const handleCancel = () => {
    store.setActiveLayer(LAYER_MORE_SETTINGS_RECO);
    setIsModalOpen(false);
  };

  const handleConfirm = async () => {
    store.setActiveLayer(LAYER_MORE_SETTINGS_RECO);
    setIsModalOpen(false);

    await dispatch(updateRecoPersoTV({ hasUserDataCollected: false }));
  };

  return (
    <div className={styles.recoPerso__container}>
      <Binder layer={LAYER_MORE_SETTINGS_RECO}>
        <h1 className={styles.recoPerso__title}>{title}</h1>
        <ButtonSettings
          handler={handleRecoPerso}
          text={t('SettingsTemplate.recommandation')}
          subText={hasUserDataCollected ? t('SettingsTemplate.enabled') : t('SettingsTemplate.disabled')}
          hasActionIcon={false}
          isTvDevice={isTvDevice}
        />
        <p className={classNames(styles.recoPerso__text)}>{t('SettingsTemplate.personalizedExp')}</p>
        <ul className={classNames(styles.recoPerso__list)}>{recoList}</ul>
        <p className={classNames(styles.recoPerso__text)}>{t('SettingsTemplate.explanation')}</p>
        {isModalOpen && (
          <DialogModal
            wrapperClassName={styles.recoPerso__dialogModal}
            title={t('SettingsTemplate.recommandationConfirm.title')}
            description={t('SettingsTemplate.recommandationConfirm.description')}
            options={[
              {
                handleClick: handleCancel,
                label: t('SettingsTemplate.recommandationConfirm.cancel'),
                id: 'dialog-modal-cancel',
              },
              {
                handleClick: handleConfirm,
                label: t('SettingsTemplate.recommandationConfirm.confirm'),
                id: 'dialog-modal-confirm',
              },
            ]}
            setIsOpen={setIsModalOpen}
          />
        )}
      </Binder>
    </div>
  );
}

export default RecoPerso;
