import { ApiV2AvatarV1, ApiV2AvatarV1Content } from '@dce-front/hodor-types/api/v2/avatars/v1/definitions';
import { ApiV2AvatarsV2, ApiV2AvatarsV2Content } from '@dce-front/hodor-types/api/v2/avatars/v2/definitions';

export const isAvatarV2Content = (
  avatar: ApiV2AvatarV1Content | ApiV2AvatarsV2Content
): avatar is ApiV2AvatarsV2Content => {
  return (avatar as ApiV2AvatarsV2Content).avatarID !== undefined;
};

export type AvatarsData = {
  default: ApiV2AvatarV1 | ApiV2AvatarsV2;
  kids: ApiV2AvatarsV2;
};
