import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { newrelicSendPerformanceEntries } from '../../../helpers/newrelic/newrelic-helper';
import { isPerformanceApiEnabled } from '../../../helpers/performance/isPerformanceApiEnabled';
import { getFeatureToggleClientPerfMeasures } from '../../../selectors/application/application-selectors';

/**
 * Instantiate a new `PerformanceObserver` to send to NewRelic:
 *
 * - `mark` entries
 * - `longtask` entries
 * - `largest-contentful-paint` entries
 */
export const usePerformanceObserver = (): void => {
  const featClientPerfMeasures = useSelector(getFeatureToggleClientPerfMeasures);

  useEffect(() => {
    if (isPerformanceApiEnabled(featClientPerfMeasures)) {
      // Send stuff currently measured
      const marks = performance.getEntriesByType('mark');
      newrelicSendPerformanceEntries(marks);
      performance.clearMarks();
      const measures = performance.getEntriesByType('measure');
      newrelicSendPerformanceEntries(measures);
      performance.clearMeasures();

      // Observe newly added entries
      const observer = new PerformanceObserver((list) => {
        newrelicSendPerformanceEntries(list.getEntries());
      });

      observer.observe({
        type: 'mark',
        buffered: true,
      });

      observer.observe({ type: 'longtask', buffered: true });
      observer.observe({ type: 'largest-contentful-paint', buffered: true });

      return () => observer.disconnect();
    }

    return;
  }, [featClientPerfMeasures]);
};
