import { Template } from '@canalplus/sdk-hodor';
import { createSelector } from '@reduxjs/toolkit';
import { isDetailTemplate } from '../../helpers/page/page-helper';
import { immersiveDisplayTemplateSelector, isImmersiveSelector } from '../../store/slices/immersive-selectors';
import { pageDisplayTemplateSelector } from '../../store/slices/page-selectors';

export const isDetailV5DisplayTemplateSelector = createSelector(
  pageDisplayTemplateSelector,
  immersiveDisplayTemplateSelector,
  (displayTemplatePage, displayTemplateImmersive): boolean => {
    const isDetailV5FullPage = displayTemplatePage && isDetailTemplate(displayTemplatePage);
    const isDetailV5Immersive = displayTemplateImmersive && isDetailTemplate(displayTemplateImmersive as Template);
    return !!(isDetailV5FullPage || isDetailV5Immersive);
  }
);

export const isDetailV5DisplayTemplateInSSRSelector = createSelector(
  isDetailV5DisplayTemplateSelector,
  isImmersiveSelector,
  (isDetailV5DisplayTemplate, isImmersive): boolean => isDetailV5DisplayTemplate && !isImmersive
);
