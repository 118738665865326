import { createContext, ReactNode } from 'react';
import { Store } from '../store/Store';

export const StoreContext = createContext<Store | null>(null);

export type StoreProviderProps = {
  children: ReactNode;
  store: Store;
};

export function StoreProvider({ children, store }: StoreProviderProps): JSX.Element {
  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
}
