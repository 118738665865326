import { Template } from '@canalplus/sdk-hodor';
import { getPublicConfig } from '../config/config-helper';

/**
 * @param   {string} displayTemplate name of the template
 * @returns {bool}                   true if the template is detailSeason
 */
export const isSeasonTemplate = (displayTemplate: string): boolean =>
  displayTemplate === getPublicConfig().TEMPLATE.DETAIL_SEASON;

/**
 * Test if template is a detail template
 * @param template name of the template
 * @returns {bool} true if the template is a detail template
 */
export const isDetailTemplate = (template: string): boolean =>
  [
    Template.DetailPage.toString(),
    Template.DetailSeason.toString(),
    Template.DetailShow.toString(),
    Template.Quicktime.toString(),
  ].includes(template);

/**
 * Test if template is a creative media template
 * @param template name of the template
 * @returns {bool} true if the template is a creative media template
 */
export const isCreativeMediaTemplate = (template: string): boolean =>
  [Template.CreativeMedia.toString()].includes(template);
