import { Button } from '@canalplus/dive';
import { ModalV2, ModalV2Size } from '@canalplus/mycanal-sharedcomponent';
import { Binder, KEY_BACK, useKeyCatcher, useStore } from '@canalplus/one-navigation';
import { Template } from '@canalplus/sdk-hodor';
import {
  PrimaryActionOnClick,
  PrimaryActionOnClickOption,
} from '@dce-front/hodor-types/modules/action_layout/primary/definitions';
import classNames from 'classnames';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import Linker from '../../../../../components/Linker/Linker';
import { getLocationStateContext } from '../../../../../helpers/contents/contents-helper';
import { LAYER_DIALOG_MODAL } from '../../../../../helpers/oneNavigation/layers';
import { displayTVModeSelector } from '../../../../../store/slices/displayMode-selectors';
import styles from './DialogModal.css';

type Option = {
  id: string;
  label: string;
  disableOnClick?: boolean;
  handleClick?: () => void;
};

export type DialogModalProps = {
  setIsOpen: (openModal: boolean) => void;
  title?: string | null;
  description?: string | null;
  options: (PrimaryActionOnClickOption | Option)[];
  wrapperClassName?: string;
  playBtnHandler?: (onClick?: PrimaryActionOnClick) => void;
};

function DialogModal({
  setIsOpen,
  playBtnHandler,
  options,
  title,
  wrapperClassName,
  description,
}: DialogModalProps): JSX.Element {
  const isTvDevice = useSelector(displayTVModeSelector);
  const store = useStore();
  const { current: lastActiveLayer } = useRef(store.activeLayer);

  const handleClose = () => {
    store.setActiveLayer(lastActiveLayer);
    setIsOpen(false);
  };

  const handleOnClick = (onClick?: PrimaryActionOnClick) => {
    handleClose();
    // Launch Player for selected DialogModal option:
    if (onClick?.displayTemplate === Template.Player) {
      playBtnHandler?.(onClick);
    }
  };

  useEffect(() => {
    store.setActiveLayer(LAYER_DIALOG_MODAL);
    store.focusDefault();
  }, [lastActiveLayer, store]);

  useKeyCatcher(KEY_BACK, handleClose, LAYER_DIALOG_MODAL);

  return (
    <ModalV2
      title={title}
      className={classNames(styles.dialogModal, 'dialogModal', {
        [`${wrapperClassName}`]: !!wrapperClassName, // Allows to custom the dialogModal style through the caller styles
      })}
      isTvDevice={isTvDevice}
      onClose={handleClose}
      size={isTvDevice ? ModalV2Size.Medium : ModalV2Size.Small}
    >
      {description && <p className={styles.dialogModal__description}>{description}</p>}
      <Binder layer={LAYER_DIALOG_MODAL}>
        <ul data-testid="dialogModal-list" className={styles.dialogModal__options}>
          {options.map((option) => {
            const { label } = option;
            const handleClick = 'handleClick' in option ? option.handleClick : undefined;
            const onClick = 'onClick' in option ? option.onClick : undefined;

            return (
              <li key={`dialogModal__item__${label?.replace(' ', '_')}`}>
                {onClick ? (
                  <Button
                    renderWrapper={({ id, className, children, disabled }) => (
                      <Linker
                        id={id}
                        onClick={() => handleOnClick(onClick)}
                        data={{ mainOnClick: onClick, context: getLocationStateContext(onClick?.displayTemplate) }}
                        title={label}
                        className={className}
                        disabled={disabled}
                      >
                        {children}
                      </Linker>
                    )}
                    variant="secondary"
                    width="full"
                    font="hind"
                  >
                    {label}
                  </Button>
                ) : (
                  <Button onClick={handleClick} width="full" font="hind">
                    {label}
                  </Button>
                )}
              </li>
            );
          })}
        </ul>
      </Binder>
    </ModalV2>
  );
}

export default DialogModal;
