import { type AlternateRecord, setAlternateLinks } from '../../../store/slices/alternateLinks';
import { setImmersiveAlternateLinks } from '../../../store/slices/immersive';
import { isImmersiveSelector } from '../../../store/slices/immersive-selectors';

export const updateAndFormatAlternateLinks =
  (alternateLinks?: AlternateRecord): Redux.ThunkAction<void> =>
  (dispatch, getState) => {
    const state = getState();
    const isImmersive = isImmersiveSelector(state);

    dispatch(isImmersive ? setImmersiveAlternateLinks(alternateLinks) : setAlternateLinks(alternateLinks));
  };
