import { mapStaticKey } from '@canalplus/mycanal-util-react';
import { Tracking } from '@dce-front/hodor-types/api/v2/common/dto/tracking/definitions';
import {
  ApiV2MoreInfos,
  ApiV2MoreInfosCurrentPage,
  ApiV2MoreInfosSelector,
  ApiV2MoreInfosStrate,
} from '@dce-front/hodor-types/api/v2/more_infos/definitions';

export type MoreInfosState = {
  currentPage: ApiV2MoreInfosCurrentPage;
  copyright?: string;
  strates?: ApiV2MoreInfosStrate[];
  selector?: ApiV2MoreInfosSelector[];
  tracking?: Tracking;
};

const getFormattedMoreInfos = (data: ApiV2MoreInfos & { tracking?: Tracking }): MoreInfosState => {
  const { currentPage, copyright, strates, selector, tracking } = data;

  const formatedStrates = mapStaticKey(strates, ['type', 'title'])?.map((strate) => {
    // Type coalition again
    const contentWithStaticKey = mapStaticKey(strate.contents as never[], ['type', 'name']);

    return {
      ...strate,
      contents: contentWithStaticKey,
    };
  });

  return {
    currentPage,
    copyright,
    strates: formatedStrates,
    selector,
    tracking,
  };
};

export default getFormattedMoreInfos;
