import { fillTemplate } from '@canalplus/mycanal-commons';
import { AppKey } from '../../../config/application/types';
import { getPublicConfig } from '../config/config-helper';

export const getPrivacyManagerUrl = (cmsToken: string, appKey: AppKey): string => {
  const {
    baseUrl,
    paths: { privacy },
  } = getPublicConfig().api.hodor;

  return fillTemplate(`${baseUrl}${privacy}`, [
    ['appKey', appKey],
    ['cmsToken', cmsToken],
  ]);
};
