import { cva } from 'class-variance-authority';
import { DiveFontFamily } from '../../../types/Dive.types';
import { ButtonVariant, ButtonWidth } from './Button.types';

export const buttonCVA = cva(
  [
    'group flex items-center justify-center rounded-4',
    'border-none select-none', // ! tailwind-base-styles
    'no-underline',
    'hover:outline-none',
  ],
  {
    variants: {
      variant: {
        primary: undefined,
        secondary: undefined,
        tertiary: undefined,
        text: 'bg-transparent',
        slim: ['px-spacing-100 sm:px-spacing-200', 'h-32'],
      },
      device: {
        tv: undefined,
        web: undefined,
      },
      width: {
        fit: 'w-fit',
        fixed: ['box-border w-[10rem] md:w-[11rem]'],
        full: 'w-full',
      },
      disabled: {
        false: 'cursor-pointer',
        true: 'cursor-not-allowed',
      },
      iconPosition: {
        right: undefined,
        left: undefined,
      },
      hasIconAndLabel: {
        true: undefined,
        false: undefined,
      },
    },
    defaultVariants: {
      variant: ButtonVariant.Primary,
      width: ButtonWidth.Fit,
    },
    /* Variant shared styles */
    compoundVariants: [
      // * Padding:
      {
        variant: ['primary', 'secondary'],
        device: 'web',
        className: 'px-spacing-200',
      },
      {
        variant: ['tertiary'],
        device: 'web',
        className: 'px-spacing-400',
      },
      {
        variant: ['primary', 'secondary'],
        device: 'tv',
        className: 'px-spacing-500',
      },
      {
        variant: ['tertiary'],
        device: 'tv',
        className: 'px-spacing-400',
      },
      {
        variant: ['text', 'slim'],
        device: 'tv',
        className: 'px-spacing-200',
      },
      // * Icon spacing:
      {
        variant: ['primary', 'secondary', 'tertiary', 'slim'],
        device: 'web',
        hasIconAndLabel: true,
        className: 'space-x-spacing-100',
      },
      {
        variant: 'text',
        device: 'web',
        hasIconAndLabel: true,
        className: 'space-x-spacing-050',
      },
      {
        variant: 'text',
        device: 'web',
        className: 'p-0',
      },
      {
        variant: ['primary', 'secondary', 'tertiary', 'slim'],
        device: 'tv',
        hasIconAndLabel: true,
        className: 'space-x-spacing-200',
      },
      {
        hasIconAndLabel: true,
        iconPosition: 'right',
        className: ['flex-row-reverse', 'space-x-reverse'],
      },
      // * Sizes:
      {
        variant: ['primary', 'secondary'],
        device: 'web',
        className: 'h-[2.75rem] md:h-48',
      },
      {
        variant: ['tertiary'],
        device: 'web',
        className: 'h-[1.875rem] md:h-[2.125rem]',
      },
      {
        variant: ['primary', 'secondary'],
        device: 'tv',
        className: 'h-[4.75rem] md:h-[4.75rem]',
      },
      {
        variant: ['tertiary'],
        device: 'tv',
        className: 'h-[3.25rem] md:h-[3.25rem]',
      },
      {
        variant: ['text', 'slim'],
        device: 'tv',
        className: 'h-[2.875rem] md:h-[2.875rem]',
      },
      // * Backgrounds (:enabled)
      {
        variant: ['primary', 'slim'],
        device: 'web',
        disabled: false,
        className: [
          'bg-dt-theme-background-button-primary-accent',
          'hover:bg-dt-theme-background-button-primary-accent-hover',
          'active:bg-dt-theme-background-button-primary-accent-pressed',
        ],
      },
      {
        variant: ['secondary', 'tertiary'],
        device: 'web',
        disabled: false,
        className: [
          'bg-dt-theme-background-button-secondary-secondary',
          'hover:bg-dt-theme-background-button-secondary-secondary-hover',
          'active:bg-dt-theme-background-button-secondary-secondary-pressed',
        ],
      },
      {
        variant: ['primary', 'secondary', 'tertiary', 'slim'],
        device: 'tv',
        disabled: false,
        className: 'bg-dt-theme-tv-background-button-button-default',
      },
      // * disabled=true
      {
        variant: ['primary', 'slim'],
        device: 'web',
        disabled: true,
        className: ['bg-dt-theme-background-button-primary-accent', 'opacity-dt-opacity-50'],
      },
      {
        variant: 'text',
        device: 'web',
        disabled: true,
        className: 'opacity-dt-opacity-50',
      },
      {
        variant: ['secondary', 'tertiary'],
        device: 'web',
        disabled: true,
        className: ['bg-dt-theme-background-button-secondary-secondary', 'opacity-dt-opacity-50'],
      },
      {
        variant: ['primary', 'secondary', 'tertiary', 'slim'],
        device: 'tv',
        disabled: true,
        className: ['bg-dt-theme-tv-background-button-button-default', 'opacity-dt-opacity-50'],
      },
      {
        variant: 'text',
        device: 'tv',
        disabled: true,
        className: 'opacity-dt-opacity-50',
      },
      // * tv-focus
      {
        device: 'tv',
        disabled: false,
        className: 'tv-focus-self:scale-104',
      },
      {
        variant: ['primary', 'secondary', 'tertiary', 'slim'],
        device: 'tv',
        disabled: false,
        className: ['tv-focus-self:bg-dt-theme-tv-background-button-button-focus', 'tv-focus-self:outline-none'],
      },
      {
        variant: ['text'],
        device: 'tv',
        disabled: false,
        className: ['tv-focus-self:outline-none', 'tv-focus-self:ring-fallback-4'],
      },
      // * Width
      {
        width: Object.values(ButtonWidth),
        device: 'web',
        className: 'min-w-0',
      },
      {
        width: ['fit', 'full'],
        device: 'tv',
        className: 'min-w-0',
      },
      {
        width: 'fixed',
        device: 'tv',
        className: 'min-w-[30rem]',
      },
    ],
  }
);

export const buttonIconCVA = cva(['block', 'grow-0'], {
  variants: {
    variant: {
      primary: undefined,
      secondary: undefined,
      tertiary: undefined,
      text: undefined,
      slim: undefined,
    },
    device: { tv: undefined, web: undefined },
    disabled: {
      false: [],
    },
  },
  /* Variant shared styles */
  compoundVariants: [
    // * Sizes:
    {
      variant: Object.values(ButtonVariant),
      device: 'web',
      className: 'basis-24',
    },
    {
      variant: ['primary', 'secondary', 'text'],
      device: 'web',
      className: 'h-24',
    },
    {
      variant: ['tertiary', 'slim'],
      device: 'web',
      className: 'h-[1.25rem]',
    },
    {
      variant: ['primary', 'secondary'],
      device: 'tv',
      className: ['basis-40', 'h-40'],
    },
    {
      variant: 'tertiary',
      device: 'tv',
      className: 'basis-[2.375rem] h-[2.375rem]',
    },
    {
      variant: ['text', 'slim'],
      device: 'tv',
      className: 'basis-32 h-32',
    },
    // * Variant styles (Backgrounds, Text Colors, Borders)
    {
      variant: ['primary', 'slim'],
      device: 'web',
      disabled: false,
      className: 'fill-dt-theme-icon-button-primary-icon',
    },
    {
      variant: 'secondary',
      device: 'web',
      disabled: false,
      className: 'fill-dt-theme-icon-button-secondary-icon',
    },
    {
      variant: 'tertiary',
      device: 'web',
      disabled: false,
      className: 'fill-dt-theme-icon-button-tertiary-icon',
    },
    {
      variant: 'text',
      device: 'web',
      disabled: false,
      className: [
        'fill-dt-theme-icon-button-text-icon-accent-default',
        'group-hover:fill-dt-theme-icon-button-text-icon-accent-hover',
        'group-active:fill-dt-theme-icon-button-text-icon-accent-pressed',
      ],
    },
    {
      variant: ['primary', 'secondary', 'tertiary', 'text', 'slim'],
      device: 'tv',
      disabled: false,
      className: 'fill-dt-theme-tv-icon-button-icon',
    },
    // * Disabled:
    {
      variant: ['primary', 'slim'],
      device: 'web',
      disabled: true,
      className: 'fill-dt-theme-icon-button-primary-icon',
    },
    {
      variant: 'secondary',
      device: 'web',
      disabled: true,
      className: 'fill-dt-theme-icon-button-secondary-icon',
    },
    {
      variant: 'tertiary',
      device: 'web',
      disabled: true,
      className: 'fill-dt-theme-icon-button-tertiary-icon',
    },
    {
      variant: 'text',
      device: 'web',
      disabled: true,
      className: 'fill-dt-theme-icon-button-text-icon-accent-pressed',
    },
    {
      variant: ['primary', 'secondary', 'tertiary', 'text', 'slim'],
      device: 'tv',
      disabled: true,
      className: 'fill-dt-theme-tv-icon-button-icon',
    },
    // * Focus:
    {
      variant: ['primary', 'secondary', 'tertiary', 'slim'],
      device: 'tv',
      disabled: false,
      className: ['group-hover:fill-primary-fg', 'group-focus:fill-dt-theme-tv-icon-button-icon-focus'],
    },
  ],
});

export const buttonLabelCVA = cva('truncate align-middle shrink-0', {
  variants: {
    variant: {
      primary: undefined,
      secondary: undefined,
      tertiary: undefined,
      text: undefined,
      slim: undefined,
    },
    font: {
      hind: ['font-hind font-semibold', 'pt-4'],
      canal: ['font-canal', 'uppercase'],
    },
    disabled: { true: undefined, false: undefined },
    device: { tv: undefined, web: undefined },
    isLongLabel: { true: undefined, false: undefined },
  },
  defaultVariants: {
    variant: ButtonVariant.Primary,
    font: DiveFontFamily.Canal,
  },
  compoundVariants: [
    // * Font size & line height:
    {
      isLongLabel: false,
      variant: ['primary', 'secondary', 'text'],
      device: 'web',
      className: 'text-16 md:text-18',
    },
    {
      isLongLabel: false,
      variant: ['tertiary'],
      device: 'web',
      className: ['text-14 md:text-16', 'leading-[0.875rem]'],
    },
    {
      isLongLabel: false,
      variant: ['slim'],
      device: 'web',
      className: ['text-12 md:text-14', 'leading-none'],
    },
    {
      isLongLabel: false,
      variant: ['primary', 'secondary'],
      device: 'tv',
      className: 'text-32',
    },
    {
      isLongLabel: false,
      variant: ['tertiary', 'text', 'slim'],
      font: 'canal',
      device: 'tv',
      className: 'text-28 leading-8',
    },
    {
      isLongLabel: false,
      variant: ['tertiary'],
      font: 'hind',
      device: 'tv',
      className: 'text-28 leading-8',
    },
    {
      isLongLabel: false,
      variant: ['text', 'slim'],
      font: 'hind',
      device: 'tv',
      className: 'text-24 leading-7',
    },
    {
      variant: Object.values(ButtonVariant),
      isLongLabel: true,
      device: 'tv',
      className: 'text-28',
    },
    {
      variant: Object.values(ButtonVariant),
      isLongLabel: true,
      device: 'web',
      className: 'text-14 md:text-16',
    },
    // * Font colors
    {
      variant: ['primary', 'slim'],
      device: 'web',
      disabled: false,
      className: 'text-dt-theme-text-button-primary-primary',
    },
    {
      variant: 'secondary',
      device: 'web',
      disabled: false,
      className: 'text-dt-theme-text-button-secondary-secondary',
    },
    {
      variant: 'tertiary',
      device: 'web',
      disabled: false,
      className: 'text-dt-theme-text-button-tertiary-tertiary',
    },
    {
      variant: 'text',
      device: 'web',
      disabled: false,
      className: [
        'group-active:text-dt-theme-text-button-text-accent-pressed',
        'group-hover:text-dt-theme-text-button-text-accent-hover',
      ],
    },
    {
      variant: ['primary', 'secondary', 'tertiary', 'slim'],
      device: 'tv',
      disabled: false,
      className: 'text-dt-theme-tv-text-button-button',
    },
    // * Disabled styles
    {
      variant: ['primary', 'slim'],
      device: 'web',
      disabled: true,
      className: 'text-dt-theme-text-button-primary-primary',
    },
    {
      variant: 'secondary',
      device: 'web',
      disabled: true,
      className: 'text-dt-theme-text-button-secondary-secondary',
    },
    {
      variant: 'tertiary',
      device: 'web',
      disabled: true,
      className: 'text-dt-theme-text-button-tertiary-tertiary',
    },
    {
      variant: 'text',
      device: 'web',
      className: 'text-dt-theme-text-button-text-accent-default',
    },
    {
      variant: ['primary', 'secondary', 'tertiary', 'slim', 'text'],
      device: 'tv',
      disabled: true,
      className: 'text-dt-theme-tv-text-button-button',
    },
    // * TV Focus:
    {
      variant: ['primary', 'secondary', 'tertiary', 'slim'],
      device: 'tv',
      disabled: false,
      className: 'tv-focus-parent:text-dt-theme-tv-text-button-button-focus',
    },
    {
      variant: 'tertiary',
      device: 'tv',
      disabled: false,
      className: 'leading-normal',
    },
    {
      variant: 'text',
      device: 'tv',
      disabled: false,
      className: 'text-dt-theme-tv-text-button-button',
    },
  ],
});
