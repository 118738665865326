import { HeaderLogo as DiveHeaderLogo } from '@canalplus/dive';
import { HeaderLogoProps } from '@canalplus/mycanal-sharedcomponent';
import styles from './HeaderLogoMyCanal.css';

export type HeaderLogoMyCanalProps = HeaderLogoProps & {
  isPageHeading: boolean;
};

function HeaderLogoMyCanal({
  logoAlt,
  className,
  linkUrl,
  isPageHeading,
  logoUrl,
}: HeaderLogoMyCanalProps): JSX.Element {
  const logo = <DiveHeaderLogo src={logoUrl} alt={logoAlt} href={linkUrl} className={className} />;

  if (isPageHeading) {
    return <h1 className={styles.HeaderLogoMyCanal__heading}>{logo}</h1>;
  }

  return logo;
}

export default HeaderLogoMyCanal;
