import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingList,
  FloatingNode,
  FloatingPortal,
  FloatingTree,
  offset,
  safePolygon,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useFloatingParentNodeId,
  useFloatingTree,
  useHover,
  useInteractions,
  useListNavigation,
  useMergeRefs,
  useRole,
  useTypeahead,
} from '@floating-ui/react';
import { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { DropdownProps } from './Dropdown.types';
import { DropdownTrigger } from './DropdownTrigger';
import { DropdownProvider } from './provider';

const DropdownComponent = forwardRef<HTMLButtonElement, DropdownProps>(
  (
    {
      children,
      label,
      triggerStyles = 'bg-transparent border-none p-0',
      menuStyles = 'outline-none z-[500]',
      options = {},
      renderTrigger,
      renderDismissButton,
      'data-testid': dataTestId = 'dropdown-trigger',
      placement = 'bottom-start',
    },
    forwardedRef
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    const elementsRef = useRef<Array<HTMLElement | null>>([]);
    const labelsRef = useRef<Array<string | null>>([]);

    const tree = useFloatingTree();
    const nodeId = useFloatingNodeId();
    const parentId = useFloatingParentNodeId();
    const isNested = parentId != null;

    const { floatingStyles, refs, context } = useFloating<HTMLElement>({
      nodeId,
      open: isOpen,
      onOpenChange: setIsOpen,
      placement: isNested ? 'right-start' : placement,
      middleware: [offset(options?.offset), flip(options?.flip), shift(options?.shift)],
      whileElementsMounted: autoUpdate,
    });

    const hover = useHover(context, {
      enabled: true,
      delay: { open: 75 },
      handleClose: safePolygon({ blockPointerEvents: true }),
      move: false, // Or else conflict with useDismiss({ referencePress: true })
    });
    const click = useClick(context, {
      event: 'mousedown',
      toggle: !isNested,
      ignoreMouse: isNested,
    });
    const role = useRole(context, { role: 'menu' });
    const dismiss = useDismiss(context, {
      bubbles: true,
      /**
       * referencePress conflicts with useHover({ move: true }) by nature.
       * @see https://floating-ui.com/docs/usedismiss#referencepress
       */
      referencePress: true,
    });
    const listNavigation = useListNavigation(context, {
      listRef: elementsRef,
      activeIndex,
      nested: isNested,
      onNavigate: setActiveIndex,
    });
    const typeahead = useTypeahead(context, {
      listRef: labelsRef,
      onMatch: isOpen ? setActiveIndex : undefined,
      activeIndex,
    });

    const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
      hover,
      click,
      role,
      dismiss,
      listNavigation,
      typeahead,
    ]);

    // Event emitter allows you to communicate across tree components.
    // This effect closes all menus when an item gets clicked anywhere
    // in the tree.
    useEffect(() => {
      if (!tree) return;

      function handleTreeClick() {
        setIsOpen(false);
      }

      function onSubMenuOpen(event: { nodeId: string; parentId: string }) {
        if (event.nodeId !== nodeId && event.parentId === parentId) {
          setIsOpen(false);
        }
      }

      tree.events.on('click', handleTreeClick);
      tree.events.on('menuopen', onSubMenuOpen);

      return () => {
        tree.events.off('click', handleTreeClick);
        tree.events.off('menuopen', onSubMenuOpen);
      };
    }, [tree, nodeId, parentId]);

    useEffect(() => {
      if (isOpen && tree) {
        tree.events.emit('menuopen', { parentId, nodeId });
      }
    }, [tree, isOpen, nodeId, parentId]);

    const contextValue = useMemo(
      () => ({
        isOpen,
        isNested,
        activeIndex,
        setActiveIndex,
        getReferenceProps,
        getItemProps,
      }),
      [isOpen, isNested, activeIndex, setActiveIndex, getItemProps, getReferenceProps]
    );

    const mergedRefs = useMergeRefs([refs.setReference, forwardedRef]);

    return (
      <FloatingNode id={nodeId}>
        <DropdownProvider {...contextValue}>
          {/* DropdownTrigger (Reference component) */}
          <DropdownTrigger
            ref={mergedRefs}
            className={triggerStyles}
            data-testid={dataTestId}
            {...(renderTrigger ? { renderTrigger } : { label })}
          />
          {/* DropdownMenu (Floating component) */}
          {isOpen && (
            <FloatingPortal>
              <FloatingFocusManager
                context={context}
                modal={false}
                initialFocus={isNested ? -1 : 0}
                returnFocus={!isNested}
              >
                <FloatingList elementsRef={elementsRef} labelsRef={labelsRef}>
                  <div ref={refs.setFloating} className={menuStyles} style={floatingStyles} {...getFloatingProps()}>
                    {renderDismissButton && renderDismissButton({ onClose: () => setIsOpen(false) })}
                    {children}
                  </div>
                </FloatingList>
              </FloatingFocusManager>
            </FloatingPortal>
          )}
        </DropdownProvider>
      </FloatingNode>
    );
  }
);

DropdownComponent.defaultProps = {
  triggerStyles: 'bg-transparent border-none p-0',
  menuStyles: 'outline-none z-10',
};

/**
 *
 * Dropdown component capable of rendering a **toggle** and a **portalled menu/card**.  It handles the focus management, the keyboard
 * navigation, the type ahead and dismiss event.
 * It is also capable to adapt the position of the menu/card to the viewport.
 *
 * > **Note:** It uses `@floating-ui/react` library internally for accessibility and positioning management.
 *
 * ### Accessibility:
 * - **ARIA:** `role="menu"` on the menu/card and `role="menuitem"` on the items.
 * - **Open:** (`Click`, `Hover` or `Space`) on the toggle (select the toggle with `Tab` first).
 * - **Navigation keys:** (`Up`, `Down`, `Left`, `Right`, `Home`, `End`) to navigate between items.
 * - **Type ahead:** (`a-z`, `A-Z`, `0-9`) to navigate to items starting with the typed character.
 * - **Dismiss:** (`Escape`, `Click outside` and on `Click menu item`) to close the menu/card.
 * - **Press:** (`Enter` or `Space`) to select/click an item.
 *
 * @example
 * <Dropdown label="My dropdown">
 *    <DropdownMenuItem>My dropdown content</div>
 * </Dropdown>
 *
 */
export const Dropdown = forwardRef<HTMLButtonElement, DropdownProps>((props, ref) => {
  const parentId = useFloatingParentNodeId();

  if (parentId === null) {
    return (
      <FloatingTree>
        <DropdownComponent {...props} ref={ref} />
      </FloatingTree>
    );
  }

  return <DropdownComponent {...props} ref={ref} />;
});

Dropdown.defaultProps = {
  triggerStyles: 'bg-transparent border-none p-0',
  menuStyles: 'outline-none z-[500]',
};
