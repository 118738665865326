import { useSelector } from 'react-redux';
import { userMenuSelector } from '../../../selectors/application/application-selectors';
import { activeNavigationPathSelector } from '../../../selectors/header/header-selectors';
import { displayTVModeSelector } from '../../../store/slices/displayMode-selectors';
import UserMenuMyCanal, { UserMenuMyCanalProps } from './UserMenuMyCanal';

export function UserMenuMyCanalConnected(
  props: Omit<UserMenuMyCanalProps, 'activePath' | 'isTvDevice' | 'userMenu'>
): JSX.Element {
  const activePath = useSelector(activeNavigationPathSelector);
  const isTvDevice = useSelector(displayTVModeSelector);
  const userMenu = useSelector(userMenuSelector);

  return <UserMenuMyCanal {...props} activePath={activePath} isTvDevice={isTvDevice} userMenu={userMenu} />;
}
