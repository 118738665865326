import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';
import { DEFAULT_CACHE_TIME, DEFAULT_STALE_TIME } from '../../constants/reactQuery';

const queryClientConfig = {
  defaultOptions: {
    queries: {
      retry: false,
      cacheTime: DEFAULT_CACHE_TIME,
      staleTime: DEFAULT_STALE_TIME,
    },
  },
};

type CreateQueryClientOptions = {
  cache?: QueryCache;
  mutationCache?: MutationCache;
};

export const createQueryClient = (options: CreateQueryClientOptions = {}): QueryClient =>
  new QueryClient({ ...queryClientConfig, ...options });
