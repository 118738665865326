import { IUserInformation } from '@canalplus/mycanal-sdk';
import { useEffect } from 'react';
import { useStore } from 'react-redux';
import { PassAuth } from '../../../client/components/passAuthentication';
import { checkPassTokenValidity } from '../../../client/components/passTokenValidity';
import { Queries } from '../../../constants/url';
import { initPassAuthSuccessCallback } from '../../../helpers/passAuthSuccessCallback/passAuthSuccessCallback-helper';
import { performHodorCinematicClient } from '../../../services/Hodor/hodor-services';
import type { IState } from '../../../store/types/State-type';

type UsePostRenderCinematicParameters = {
  userInfos?: IUserInformation;
};

/** To be used on mount only */
export const usePostRenderCinematic = ({ userInfos }: UsePostRenderCinematicParameters): void => {
  const store = useStore<IState>();

  useEffect(() => {
    (async function performPostRenderCinematic() {
      await PassAuth(store, userInfos);

      await performHodorCinematicClient({
        store,
      });

      const passAuthSuccessCallback = new URLSearchParams(window.location.search).get(Queries.PassAuthSuccessCallback);

      // Init window function to pass callback executed on authentication success
      initPassAuthSuccessCallback(passAuthSuccessCallback);

      // if url has the parameter passAuthSuccessCallback call window function auth success callback
      if (passAuthSuccessCallback) {
        window.passAuthSuccessCallback?.();
      }

      checkPassTokenValidity(store);
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};
