import { useEffect } from 'react';
import { useStore } from './useStore';

/**
 * Allows to switch to a layer in a declarative way via a hook
 * @param layer layer id to switch on
 * @param active if passed, the given layer is set active when true and inative when false
 *
 * @example
 *  // This will set layer 42 active when mounted and previous active layer is restored on unmount
 *  useActiveLayer(42);
 *
 * @example
 *  // This will set layer 42 active when enable is true and previous active layer is restored
 *  // when enable pass to false
 *  useActiveLayer(42);
 */
export function useActiveLayer(layer: number, active: boolean = true): void {
  const store = useStore();

  useEffect(() => {
    if (active) {
      const previousLayer = store.activeLayer;
      store.setActiveLayer(layer);
      store.focusDefault();

      return () => {
        if (store.activeLayer === layer) {
          store.setActiveLayer(previousLayer);
        }
      };
    }

    return undefined;
  }, [store, layer, active]);
}
