import { ApiV2PageTracking } from '@dce-front/hodor-types/api/v2/page/dtos/definitions';
import {
  ApiV2SearchCurrentPage,
  ApiV2SearchPage,
} from '@dce-front/hodor-types/api/v2/page/dtos/display_templates/search/definitions';

export interface ISearchStandaloneState {
  currentPage?: ApiV2SearchCurrentPage;
  tracking?: ApiV2PageTracking;
}

const getFormattedSearchStandalone = (data: ApiV2SearchPage): ISearchStandaloneState => {
  const { currentPage, tracking } = data || {};

  return {
    currentPage,
    tracking,
  };
};

export default getFormattedSearchStandalone;
