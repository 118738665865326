import { Route, withRouter } from 'react-router-dom';
import type { AppHistory, AppLocation } from '../../typings/routing';
import Page from '../Page/Page';
import DefaultRouteComponent from './components/DefaultRouteComponent/DefaultRouteComponent';

function Routes(): JSX.Element {
  return (
    <>
      <Page />
      <Route
        render={({ location, history }) => (
          <DefaultRouteComponent location={location as AppLocation} history={history as AppHistory} />
        )}
      />
    </>
  );
}

export default withRouter(Routes);
