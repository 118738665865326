import { IContentSections, ISectionsStrate } from '@canalplus/mycanal-sdk';
import { ContentType, Template } from '@canalplus/sdk-hodor';
import { I18nMyCanalFunction } from '../../../lang';

export const getMoreSettingsContentSection = (t: I18nMyCanalFunction): IContentSections => {
  return {
    title: t('SettingsTemplate.settings'),
    onClick: { displayTemplate: Template.MoreSettings },
  };
};

export const getMoreSettingsStrate = ({
  isPersoRecoAvailable,
  hasPromotionBanner,
  hasPromotionCover,
  hasTrailer,
  isFeatWhoIsWatching,
  isFeatHdr,
  isFeatLowLatency,
  t,
}: {
  isPersoRecoAvailable: boolean;
  hasPromotionBanner: boolean;
  hasPromotionCover: boolean;
  hasTrailer: boolean;
  isFeatWhoIsWatching: boolean;
  isFeatHdr: boolean;
  isFeatLowLatency: boolean;
  t: I18nMyCanalFunction;
}): ISectionsStrate[] => {
  const moreSettingsStates: ISectionsStrate[] = [];
  const contentsPref: IContentSections[] = [];
  const contentsVideoSettings: IContentSections[] = [];

  if (isPersoRecoAvailable) {
    contentsPref.push({
      title: t('SettingsTemplate.recommandation'),
      onClick: { displayTemplate: Template.RecoPerso },
    });
  }

  if (hasTrailer || hasPromotionBanner || hasPromotionCover) {
    contentsPref.push({
      title: t('SettingsTemplate.autoplay'),
      onClick: { displayTemplate: Template.AnimationsAutoplay },
    });
  }

  if (isFeatWhoIsWatching) {
    contentsPref.push({
      title: t('SettingsTemplate.whoIsWatching.title'),
      onClick: { displayTemplate: Template.WhoIsWatchingSetting },
    });
  }

  if (isFeatHdr) {
    contentsVideoSettings.push({
      title: t('SettingsTemplate.HDR.title'),
      onClick: { displayTemplate: Template.Hdr },
    });
  }

  if (isFeatLowLatency) {
    contentsVideoSettings.push({
      title: t('SettingsTemplate.realTimeStreaming.title'),
      onClick: { displayTemplate: Template.LowLatency },
    });
  }

  if (contentsPref.length > 0) {
    moreSettingsStates.push({
      title: t('SettingsTemplate.preferences'),
      type: ContentType.Sections,
      contents: contentsPref,
    });
  }

  if (contentsVideoSettings.length > 0) {
    moreSettingsStates.push({
      title: t('SettingsTemplate.videoSettings'),
      type: ContentType.Sections,
      contents: contentsVideoSettings,
    });
  }

  return moreSettingsStates;
};
