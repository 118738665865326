import { ApiV2NavigationItem } from '@dce-front/hodor-types/api/v2/authenticate/definitions';
import classNames from 'classnames';
import { useMemo } from 'react';
import { transformHeaderNavigation } from '../../../helpers/navigation/navigation-helper';
import styles from './HeaderNavigationTv.css';

export type HeaderNavigationTvProps = {
  activePath?: string;
  navigation?: ApiV2NavigationItem[];
};

function HeaderNavigationTv({ activePath = '', navigation }: HeaderNavigationTvProps): JSX.Element {
  const navigationList = useMemo(() => transformHeaderNavigation(navigation), [navigation, activePath]); // eslint-disable-line react-hooks/exhaustive-deps

  const navigationItem = navigationList?.map((item) => (
    <li
      data-is-active={item?.props?.data?.onClick?.path === activePath}
      className={classNames(styles.HeaderNavigationTv__item, 'HeaderNavigation')}
      key={item.key || item.props.href}
      id={`${item?.props?.data?.displayName || item?.props?.data?.onClick?.path}`}
    >
      {item}
    </li>
  ));

  return (
    <nav className={styles.HeaderNavigationTv}>
      <ul className={styles.HeaderNavigationTv__list}>{navigationItem}</ul>
    </nav>
  );
}

export default HeaderNavigationTv;
