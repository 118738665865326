import { Binder } from '@canalplus/one-navigation';
import { useSelector } from 'react-redux';
import { MIDDLEWARE_STRATE } from '../../../../../../../helpers/oneNavigation/middleware';
import I18n from '../../../../../../../lang';
import {
  featDisplayPersoRecoToggleSelector,
  getFeatureToggleHdr,
  getFeatureToggleLowLatency,
  getFeatureTogglePromotionBanner,
  getFeatureTogglePromotionCover,
  getFeatureToggleTrailerPreview,
  getFeatureToggleWhoIsWatching,
} from '../../../../../../../selectors/application/application-selectors';
import type { FromProp } from '../../../../../../../server/modules/fetchWithQuery/types';
import { displayTVModeSelector } from '../../../../../../../store/slices/displayMode-selectors';
import { getMoreSettingsStrate } from '../../../../../../../templates/SectionsList/data/constants';
import UserMenuSections from '../../../UserMenuSections';
import styles from './MoreSettings.css';

export type MoreSettingsBinderProps = {
  id: string;
  children: React.ReactNode;
  isTvDevice: boolean;
};

export type MoreSettingsProps = {
  title: string;
} & FromProp;

function MoreSettings({ from, title }: MoreSettingsProps): JSX.Element {
  const isTvDevice = useSelector(displayTVModeSelector);
  const featDisplayPersoRecoToggle = useSelector(featDisplayPersoRecoToggleSelector);
  const hasPromotionBanner = useSelector(getFeatureTogglePromotionBanner);
  const hasPromotionCover = useSelector(getFeatureTogglePromotionCover);
  const hasTrailer = useSelector(getFeatureToggleTrailerPreview);
  const isFeatWhoIsWatching = useSelector(getFeatureToggleWhoIsWatching);
  const isFeatHdr = useSelector(getFeatureToggleHdr);
  const isFeatLowLatency = useSelector(getFeatureToggleLowLatency);

  const { t } = I18n.useTranslation();
  const moreSettingsStrate = getMoreSettingsStrate({
    isPersoRecoAvailable: featDisplayPersoRecoToggle,
    hasPromotionBanner,
    hasPromotionCover,
    hasTrailer,
    isFeatWhoIsWatching,
    isFeatHdr,
    isFeatLowLatency,
    t,
  });

  return (
    <div className={styles.moreSettings__container}>
      <Binder middleware={MIDDLEWARE_STRATE}>
        <h1 className={styles.moreSettings__title}>{title}</h1>

        {moreSettingsStrate.map((strate) => (
          <UserMenuSections
            from={from}
            key={`moreSettings-strate-${strate.title}`}
            isTvDevice={isTvDevice}
            contents={strate.contents}
            sectionTitle={strate.title}
          />
        ))}
      </Binder>
    </div>
  );
}

export default MoreSettings;
