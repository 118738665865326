import { fillTemplate } from '@canalplus/mycanal-commons';
import { OfferLocation, PassPlatform, Platform } from '@canalplus/sdk-core';
import { LocationDescriptorObject } from 'history';
import { R7NavigateDataSkey } from 'r7extlib';
import { MouseEvent, SyntheticEvent } from 'react';
import type { IPublic } from '../../../../../config/application/types';
import { TemplateTypes } from '../../../../constants/templateTypes';
import { fillUrlWithPassId, isUrlContainsPassId } from '../../../../helpers/url/url-helper';
import { clientSideOneShopRedirection } from '../../../../helpers/user/user-helper';
import { isR7Available } from '../../../../helpers/window/window-helper';
import { handleExternalServiceTracking } from '../../../../store/reducers/actions/ExternalServices-actions';
import type { AppHistory, LocationState } from '../../../../typings/routing';
import { LinkerProps } from '../../types';

/**
 * Dispatch the good actions for displayTemplate === publicConfig.TEMPLATE.EXTERNAL_SITE
 * @param e event click
 * @param linkerProps
 * @param dispatch
 * @param isTvDevice
 * @param platform
 * @param offerLocation
 */
const onClickTemplateExternal = (
  e: MouseEvent<HTMLAnchorElement | HTMLButtonElement> | SyntheticEvent,
  linkerProps: LinkerProps,
  dispatch: Redux.Dispatch,
  isTvDevice: boolean,
  platform: Platform,
  offerLocation: OfferLocation
): void => {
  const { data, target = '_blank' } = linkerProps;
  const { contentID, mainOnClick: onClickHodor, trackingContext } = data || {};
  const { serviceID, URLPage, URLWebsite, target: onClickTarget } = onClickHodor || {};
  const linkTarget = isTvDevice ? '_self' : onClickTarget || target;
  // Handle tracking for external site context
  dispatch(
    handleExternalServiceTracking(
      URLPage,
      contentID || onClickHodor?.contentID,
      URLWebsite,
      trackingContext || onClickHodor?.trackingContext
    )
  );

  if (serviceID) {
    if (isTvDevice && isR7Available()) {
      e.preventDefault();
      window.R7('navigate', {
        skey: serviceID,
        // We should always use the contentID given in the onClick for deeplinks, the contentID on content is technical
        context: onClickHodor?.contentID ? { deeplink: { contentId: onClickHodor?.contentID } } : undefined,
      } as R7NavigateDataSkey);
    }
    if (!isTvDevice && serviceID === 'LAUNCH_PRIVACY_MANAGER' && window.launchPrivacyManager) {
      window.launchPrivacyManager();
    }
  }

  if (!isTvDevice && URLWebsite) {
    e.preventDefault();
    let urlToOpen = URLWebsite;

    if (isUrlContainsPassId(URLWebsite)) {
      urlToOpen = fillUrlWithPassId(urlToOpen);
    }

    urlToOpen = fillTemplate(urlToOpen, [
      ['media', PassPlatform[platform]],
      ['appLocation', offerLocation],
    ]);

    window.open(urlToOpen, linkTarget);
  }
};

/**
 * Generate the function onClick to execute for linker data `linkerProps`
 * @param linkerProps
 * @param dispatch
 * @param isTvDevice
 * @param isNextEpisodeAutoplay
 * @param offerZone
 * @param offerLocation
 * @param publicConfig
 * @param platform
 * @returns Function onClick to execute for linker data `linkerProps`
 */
export const getOnClickGenerated =
  (
    linkerProps: LinkerProps,
    dispatch: Redux.Dispatch,
    isTvDevice: boolean,
    offerLocation: OfferLocation,
    publicConfig: IPublic,
    platform: Platform
  ) =>
  (e: MouseEvent<HTMLAnchorElement | HTMLButtonElement> | SyntheticEvent): void => {
    const { data, onClick } = linkerProps;
    const { mainOnClick: onClickHodor } = data || {};

    const { displayTemplate } = onClickHodor || {};

    if (!onClickHodor && !onClick) {
      return e.preventDefault();
    }

    if (onClick) {
      onClick(e);
    }

    if (displayTemplate === publicConfig.TEMPLATE.EXTERNAL_SITE) {
      onClickTemplateExternal(e, linkerProps, dispatch, isTvDevice, platform, offerLocation);
      return;
    }

    if (displayTemplate === TemplateTypes.LAUNCH_ONE_SHOP) {
      clientSideOneShopRedirection(onClickHodor?.contentID);
      return;
    }
  };

/**
 * Navigate to a link using router
 * @param history Router History
 * @param to LocationDescriptorObject information of the target to navigate to
 * @param replace Indicate if replace or push in history
 */
export const redirectRouter = (
  history: AppHistory,
  to: LocationDescriptorObject<LocationState>,
  replace?: boolean
): void => {
  /* @todo use redirect from "react-router-dom" when we bump to react-router v6 */
  if (replace) {
    history.replace({ pathname: to.pathname, search: to.search }, to.state);
  } else {
    history.push({ pathname: to.pathname, search: to.search }, to.state);
  }
};
