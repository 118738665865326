import { isClientSide } from '@canalplus/mycanal-commons';
import { removeTokenCmsCookie } from '../application/application-helper';
import { deleteQueryString } from '../url/url-helper';

/**
 * Pass callback executed on authentication success
 *
 * Init window object passAuthSuccessCallback
 */
export const initPassAuthSuccessCallback = (passAuthSuccessCallback: string | null): void => {
  if (isClientSide()) {
    (window as any).passAuthSuccessCallback = () => {
      const domain = window.location.hostname;
      removeTokenCmsCookie(domain);
      // we need to wait for Pass to update cookies before the second roundtrip with server
      (window as any).waitForPassToken(() => {
        reloadPage(passAuthSuccessCallback);
      });
    };
  }
};

/**
 * clear tokens and cache part
 */
export const reloadPage = (passAuthSuccessCallback: string | null): void => {
  // Reload page if parameter contains 'reload' value
  if (passAuthSuccessCallback === 'reload' && isClientSide()) {
    window.location.href = deleteQueryString(window.location.href);
  }

  // Reload page to login any partners' users (Free, Orange, ...)
  // We need this because the authent is on the partner's side and the p_pass_token cookie is stored when the user is redirected on myCANAL
  if (
    (global as any).passJSON &&
    (global as any).passJSON.partner &&
    (global as any).passJSON.partner !== '' &&
    isClientSide()
  ) {
    window.location.reload();
  }
};
