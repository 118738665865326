import { useSelector } from 'react-redux';
import { getHeaderActiveIndex } from '../../../helpers/navigation/navigation-helper';
import { navigationSelector } from '../../../selectors/application/application-selectors';
import {
  activeNavigationPathSelector,
  isHeaderNavDropdownEnabledSelector,
  rootPathSelector,
} from '../../../selectors/header/header-selectors';
import HeaderNavigationMyCanal, { HeaderNavigationMyCanalProps } from './HeaderNavigationMyCanal';

export function HeaderNavigationMyCanalConnected(
  props: Omit<HeaderNavigationMyCanalProps, 'activeIndex' | 'navigation' | 'isDropdownEnabled'>
): JSX.Element {
  const activePath = useSelector(activeNavigationPathSelector);
  const isDropdownEnabled = useSelector(isHeaderNavDropdownEnabledSelector);
  const navigation = useSelector(navigationSelector);
  const rootPath = useSelector(rootPathSelector);

  const path = activePath || rootPath;
  const activeIndex = getHeaderActiveIndex(navigation, rootPath, path);

  return (
    <HeaderNavigationMyCanal
      {...props}
      activeIndex={activeIndex}
      isDropdownEnabled={isDropdownEnabled}
      navigation={navigation}
    />
  );
}
