import classNames from 'classnames';
import { forwardRef } from 'react';
import { useDiveContext } from '../../context';
import { AvatarProps, AvatarVariant } from './Avatar.types';
import { AvatarImage } from './AvatarImage/AvatarImage';
import { AvatarImageProps } from './AvatarImage/AvatarImage.types';

export type Ref = HTMLButtonElement | HTMLDivElement;

/**
 * Displays an Avatar image, with an optional label and contextual icons.
 *
 * @example
 * <Avatar
 *   isEdit
 *   avatarImg={{
 *     url: 'https://image.png',
 *     alt: 'Avatar alt label',
 *   }}
 * />
 */
export const Avatar = forwardRef<Ref, AvatarProps>(
  (
    {
      onClick,
      id,
      image,
      label,
      variant = AvatarVariant.Default,
      isKids = false,
      isEdit = false,
      isButton = true,
      className,
      'data-testid': dataTestId,
      ...a11y
    },
    forwardedRef
  ): JSX.Element => {
    const { isTv } = useDiveContext();

    const resetStyles = classNames('border-dt-border-none bg-transparent p-dt-spacing-none max-w-full max-h-full', {
      'focus:outline-none': isTv,
      'aspect-1': !label,
    });
    const avatarImageProps: AvatarImageProps = {
      variant,
      isKids,
      isEdit,
      label,
      isTv,
      image,
    };

    if (isButton) {
      return (
        <button
          ref={forwardedRef as React.Ref<HTMLButtonElement>}
          onClick={onClick}
          className={classNames(resetStyles, 'cursor-pointer', className)}
          id={id}
          type="button"
          data-testid={dataTestId}
          {...a11y}
        >
          <AvatarImage {...avatarImageProps} />
        </button>
      );
    }

    return (
      <div
        ref={forwardedRef as React.Ref<HTMLDivElement>}
        className={classNames(resetStyles, className)}
        id={id}
        data-testid={dataTestId}
        {...a11y}
      >
        <AvatarImage {...avatarImageProps} />
      </div>
    );
  }
);
