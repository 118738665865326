import { Footer, FooterLabels } from '@canalplus/mycanal-sharedcomponent';
import { ApiV2NavigationItem } from '@dce-front/hodor-types/api/v2/authenticate/definitions';
import { memo } from 'react';
import { getPublicConfig } from '../../helpers/config/config-helper';
import { getMainColumnContent } from '../../helpers/navigation/navigation-helper';
import ZoneSelector from '../ZoneSelector/ZoneSelector';

export type FooterContainerProps = {
  labels: FooterLabels;
  socialMedia: ApiV2NavigationItem[];
  tree: ApiV2NavigationItem[];
  isFeatSocialNetworksFooter?: boolean;
  isLocaleSwitcher?: boolean;
};

function FooterContainer({
  isFeatSocialNetworksFooter = false,
  isLocaleSwitcher = false,
  labels,
  socialMedia,
  tree,
}: FooterContainerProps): JSX.Element | null {
  if (!tree.length) {
    return null;
  }

  const isSocialLinksFromBO = !!socialMedia.length;
  const socialLinks = isSocialLinksFromBO ? socialMedia : getPublicConfig().footer.socialLinks;

  return (
    <Footer
      mainColumnContent={getMainColumnContent(tree)}
      socialLinks={socialLinks}
      zoneSelector={isLocaleSwitcher && <ZoneSelector />}
      isFeatSocialNetworksFooter={isFeatSocialNetworksFooter}
      isSocialLinksFromBO={isSocialLinksFromBO}
      labels={labels}
    />
  );
}

export default memo(FooterContainer);
