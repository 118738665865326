import { useSelector } from 'react-redux';
import { rewriteObjectUrls } from '../../../helpers/proxyUrls/proxyUrls';
import { userMenuExtendedNonProfileStratesSelector } from '../../../selectors/application/application-selectors';
import { displayTVModeSelector } from '../../../store/slices/displayMode-selectors';
import { isPhoneResolutionSelector } from '../../../store/slices/ui-selectors';
import {
  authenticatedSelector,
  hasProfilesLengthSelector,
  isKidsProfileSelector,
  profileImgSelector,
  profileNameSelector,
} from '../../../store/slices/user-selectors';
import HeaderProfileMyCanal, { HeaderProfileMyCanalProps } from './HeaderProfileMyCanal';

export function HeaderProfileMyCanalConnected(
  props: Omit<
    HeaderProfileMyCanalProps,
    | 'dark'
    | 'isKidsProfile'
    | 'isLoggedIn'
    | 'isProfileSelectorRendered'
    | 'isTvDevice'
    | 'isUserMenuFullscreen'
    | 'triggerImageAlt'
    | 'triggerImageUrl'
    | 'userMenuStrates'
  >
): JSX.Element {
  const isKidsProfile = useSelector(isKidsProfileSelector);
  const isLoggedIn = useSelector(authenticatedSelector);
  const isProfileSelectorRendered = useSelector(hasProfilesLengthSelector);
  const isTvDevice = useSelector(displayTVModeSelector);
  const isUserMenuFullscreen = useSelector(isPhoneResolutionSelector);
  const triggerImageAlt = useSelector(profileNameSelector);
  const userMenuStrates = useSelector(userMenuExtendedNonProfileStratesSelector);

  // To ensure that the URL of the userMenu avatar icon is properly proxied, we apply a guardrail as close as possible to the component
  let triggerImageUrl = useSelector(profileImgSelector);
  if ($_BUILD_DEVICE_ORANGEBOX) {
    triggerImageUrl = rewriteObjectUrls(triggerImageUrl);
  }

  return (
    <HeaderProfileMyCanal
      {...props}
      isKidsProfile={isKidsProfile}
      isLoggedIn={isLoggedIn}
      isProfileSelectorRendered={isProfileSelectorRendered}
      isTvDevice={isTvDevice}
      isUserMenuFullscreen={isUserMenuFullscreen}
      triggerImageAlt={triggerImageAlt}
      triggerImageUrl={triggerImageUrl}
      userMenuStrates={userMenuStrates}
    />
  );
}
