import { DottedLanguageObjectStringPaths } from '@canalplus/mycanal-commons';
import { createI18n, I18nTranslationFunction } from '@canalplus/mycanal-i18n';
import { getLocalTranslations } from '../helpers/lang/lang-helper';
import { MyCanalTranslations } from './types';

export type MyCanalTranslationsStringPaths = DottedLanguageObjectStringPaths<MyCanalTranslations>;

export type I18nMyCanalFunction = I18nTranslationFunction<MyCanalTranslationsStringPaths>;

const { provider, consumer, context, useTranslation } = createI18n<MyCanalTranslationsStringPaths>();

const Wrapper = ({ children }: { locale: string; children: React.ReactElement }) => children;
const Provider = provider(getLocalTranslations)(Wrapper);

const I18n = { Provider, consumer, context, useTranslation };

export default I18n;
