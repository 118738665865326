import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getDarkLogoURLSelector,
  getLightLogoURLSelector,
  isProspectSelector,
  kidsHomeUrlSelector,
  logoSelector,
} from '../../../selectors/application/application-selectors';
import { isHeaderDarkSelector, isHomePageSelector, rootPathSelector } from '../../../selectors/header/header-selectors';
import { HOME_KIDS_PATHNAME } from '../../../store/constants';
import { displayTVModeSelector } from '../../../store/slices/displayMode-selectors';
import { isKidsProfileSelector } from '../../../store/slices/user-selectors';
import HeaderLogoMyCanal, { HeaderLogoMyCanalProps } from './HeaderLogoMyCanal';

export function HeaderLogoMyCanalConnected(
  props: Omit<HeaderLogoMyCanalProps, 'logoAlt' | 'linkUrl' | 'isPageHeading' | 'logoUrl'> & {
    isHeaderTransparent?: boolean;
  }
): JSX.Element {
  const { isHeaderTransparent = false, ...restProps } = props;

  const { altImage = '', URLImageForDarkMode, URLImageForLightMode } = useSelector(logoSelector);

  const isTvDevice = useSelector(displayTVModeSelector);
  const isKidsProfile = useSelector(isKidsProfileSelector);
  const isProspect = useSelector(isProspectSelector);
  const isHomePage = useSelector(isHomePageSelector);
  const isHeaderDark = useSelector(isHeaderDarkSelector);
  const darkLogoUrl = useSelector(getDarkLogoURLSelector);
  const lightLogoUrl = useSelector(getLightLogoURLSelector);
  const kidsHomeUrl = useSelector(kidsHomeUrlSelector);

  const isPageHeading = isProspect && isHomePage;
  const rootPath = useSelector(rootPathSelector);

  const logoUrl =
    (isHeaderTransparent || isHeaderDark ? darkLogoUrl || URLImageForDarkMode : lightLogoUrl || URLImageForLightMode) ||
    '';

  const [linkUrl, setLinkUrl] = useState<string>();

  useEffect(() => {
    if (!isTvDevice) {
      // Hodor sends us to the kids page with a URL "/theme/pid110530-home-profils-kids.html"
      //  which causes us to lose the "/home-kids/" route when we click on the icon.
      setLinkUrl(isKidsProfile ? rootPath?.replace(kidsHomeUrl, HOME_KIDS_PATHNAME) : rootPath);
    }
  }, [isKidsProfile, isTvDevice, rootPath, kidsHomeUrl]);

  return (
    <HeaderLogoMyCanal
      {...restProps}
      logoAlt={altImage}
      linkUrl={linkUrl}
      isPageHeading={isPageHeading}
      logoUrl={logoUrl}
    />
  );
}
