import { DIMENSIONS, PROFILE } from '@canalplus/mycanal-commons';
import { ProfileAvatar } from '@canalplus/mycanal-sharedcomponent';
import { Binder } from '@canalplus/one-navigation';
import { ApiV2Profile } from '@dce-front/hodor-types/api/v2/me/profiles/definitions';
import classNames from 'classnames';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { LAYER_SETTINGS_MANAGE_PROFILES } from '../../../helpers/oneNavigation/layers';
import { MIDDLEWARE_PROFILES_LIST } from '../../../helpers/oneNavigation/middleware';
import I18n from '../../../lang';
import { displayTVModeSelector } from '../../../store/slices/displayMode-selectors';
import styles from './ProfilesListTV.css';

/**
 * ProfilesList
 *
 * Show lists of profiles on TV device.
 *
 * @returns {JSX.Element}
 */

export type ProfilesListTVProps = {
  handleClick: (profile: ApiV2Profile) => void;
  profilesList: ApiV2Profile[];
};

function ProfilesListTV({ handleClick, profilesList }: ProfilesListTVProps): JSX.Element {
  const { t } = useContext(I18n.context);
  const isTvDevice = useSelector(displayTVModeSelector);

  const profileAvatarDimensions = DIMENSIONS.PROFILE_AVATAR.extraLarge;

  return (
    <div className={styles.profilesListTV}>
      <Binder layer={LAYER_SETTINGS_MANAGE_PROFILES} middleware={MIDDLEWARE_PROFILES_LIST}>
        <h2 className={styles.profilesListTV__title}>{t('ProfileManager.edit')}</h2>
        <ul className={classNames(styles.profilesListTV__list)}>
          {profilesList.map(
            (profile) =>
              profile.type === PROFILE && (
                <li
                  className={styles.profilesListTV__item}
                  id={`ProfilesListTV_${profile.profileId}`}
                  key={profile.profileId}
                >
                  <button
                    className={classNames(styles.profilesListTV__avatar, 'globalProfilesListTV')}
                    id={`ProfilesListTV_${profile.profileId}_onclick`}
                    type="button"
                    onClick={() => handleClick(profile)}
                  >
                    <ProfileAvatar
                      avatar={profile.URLImage || ''}
                      altImage={profile.displayName || ''}
                      customClass={styles.profilesListTV__profileAvatar}
                      dimensions={profileAvatarDimensions}
                      isEditable
                      isKidsProfile={profile.isKidsProfile}
                      isTvDevice={isTvDevice}
                    />
                  </button>
                  <div className={styles.profilesListTV__nameWrapper}>
                    <span className={styles.profilesListTV__name}>{profile.displayName}</span>
                  </div>
                </li>
              )
          )}
        </ul>
      </Binder>
    </div>
  );
}

export default ProfilesListTV;
