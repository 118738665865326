import { Tracking } from '@dce-front/hodor-types/api/v2/common/dto/tracking/definitions';
import { ApiV2LiveGridPage } from '@dce-front/hodor-types/api/v2/live_grid/definitions';
import { ILiveGridState } from './types';

const getFormattedLiveGrid = (data: ApiV2LiveGridPage & { tracking?: Tracking }): ILiveGridState => {
  const { channels = [], context, features = [], currentPage, paging, tracking } = data || {};
  return {
    channels,
    context,
    features,
    isAdult: !!currentPage?.adult,
    paging,
    tracking,
  };
};

export default getFormattedLiveGrid;
