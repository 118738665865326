import { getCookie, removeCookie, setCookie } from '@canalplus/mycanal-commons';
import { CookieKey } from '../../constants/cookie';
import { getNotificationExpires } from '../../helpers/date/date-helper';
import type { UserNotification } from '../../store/slices/user-type';

export const storeNotification = (notification: UserNotification): void => {
  const cacheNotification = getCookie<UserNotification>(CookieKey.Notification);
  const isNotificationHidden = notification?.hidden || false;
  const isCacheNotificationHidden = cacheNotification?.hidden || false;

  if (isNotificationHidden && !isCacheNotificationHidden) {
    removeCookie(CookieKey.Notification);

    setCookie(CookieKey.Notification, notification, {
      expires: getNotificationExpires(notification.refreshInterval || 0),
      sameSite: 'lax',
    });
  }
};
