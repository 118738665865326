import { AcmSdkConfig } from '@canalplus/sdk-acm';
import { AcmSdkContext } from '@canalplus/sdk-acm-react';

const { Provider } = AcmSdkContext;

type AcmSdkProviderProps = {
  children: React.ReactNode;
  acmSdkConfig: AcmSdkConfig;
};

export function AcmSdkProvider({ children, acmSdkConfig }: AcmSdkProviderProps): JSX.Element {
  return <Provider value={acmSdkConfig}>{children}</Provider>;
}
