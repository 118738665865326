import { PageContainerContentPromotionVideo } from '@dce-front/hodor-types/modules/page_container_content/manual/bran/promotion/definitions';

const isVideoEncrypted = (video: PageContainerContentPromotionVideo): boolean => video.encryption !== 'clear';

export type PromotionVideoContent = {
  encryption?: 'encrypted' | 'clear';
  format?: string;
  url?: string;
};

/**
 * This helper retrieves the correct video to be used depending on the encryption and formats available.
 * It follows three rules :
 *  1. Choose not encrypted video (encryption field equals `clear`) before others
 *  2. Choose dash format before hls format and before mp4 as default fallback
 *  3. Choose first rule before the second
 * @param videos - array of videos with for each the encryption and format
 * @returns the video object
 */
const getPromotionVideoContent = (
  videos: PageContainerContentPromotionVideo[] = []
): PromotionVideoContent | undefined => {
  if (!videos.length) {
    return;
  }

  // Sort videos array based on rules
  videos.sort((currentVideo, nextVideo) => {
    const isCurrentVideoEncrypted = isVideoEncrypted(currentVideo);
    const isNextVideoEncrypted = isVideoEncrypted(nextVideo);

    if (isCurrentVideoEncrypted !== isNextVideoEncrypted) {
      // Rule 1 : Choose not encrypted videos before others
      return isCurrentVideoEncrypted ? 1 : -1;
    } else if (currentVideo.format !== nextVideo.format) {
      // Rule 2: Choose videos with 'dash' format before 'hls' format, and 'hls' before 'mp4'
      if (currentVideo.format === 'dash') {
        return -1;
      } else if (nextVideo.format === 'dash') {
        return 1;
      } else if (currentVideo.format === 'hls') {
        return -1;
      } else if (nextVideo.format === 'hls') {
        return 1;
      } else {
        return 0;
      }
    } else {
      // Rule 3: Choose first rule before the second
      return 0;
    }
  });

  // Return the first video of the sorted array
  return {
    encryption: isVideoEncrypted(videos[0]) ? 'encrypted' : 'clear',
    url: videos[0].URLVideo,
    format: videos[0].format,
  };
};

export default getPromotionVideoContent;
