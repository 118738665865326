import { VirtualKeyboard as VirtualKeyboardLib } from '@canalplus/mycanal-sharedcomponent';
import { VirtualKeyboardProps } from '@canalplus/mycanal-sharedcomponent/dist/es/components/VirtualKeyboard/VirtualKeyboard';
import { OfferLocation } from '@canalplus/sdk-core';
import { useSelector } from 'react-redux';
import { offerLocationSelector } from '../../selectors/application/application-selectors';

export function VirtualKeyboard(keyboardProps: VirtualKeyboardProps): JSX.Element {
  const offerLocation = useSelector(offerLocationSelector);
  const isPolish = offerLocation === OfferLocation.pl;
  const layout = isPolish ? ['polish', 'big'] : 'big';

  return <VirtualKeyboardLib isPolish={isPolish} layout={layout} {...keyboardProps} />;
}

export { virtualKeyboardContext, VirtualKeyboardProvider } from '@canalplus/mycanal-sharedcomponent';
