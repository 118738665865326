import { useSelector } from 'react-redux';
import { stickyHeaderBarSelector, stickyOriginalBarSelector } from '../../store/slices/stickyBar-selectors';
import StickyBar from './StickyBar';

export function StickyBarConnected(): JSX.Element {
  const getStickyBar = useSelector(stickyHeaderBarSelector);
  const getStickyBarOriginal = useSelector(stickyOriginalBarSelector);

  return <StickyBar getStickyBar={getStickyBar} getStickyBarOriginal={getStickyBarOriginal} />;
}
