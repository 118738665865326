import { Template } from '@canalplus/sdk-hodor';
import { ApiV2NavigationNotification } from '@dce-front/hodor-types/api/v2/authenticate/definitions';
import { JsonLocalStorage } from '../../helpers/jsonLocalStorage/jsonLocalStorage-helper';

export const DayInMs = 24 * 60 * 60 * 1000;
export const MaxDayStorage = 365; // 1 year
export const SlideshowStorageDateKey = 'slideshowNextDateDisplay';

/**
 * Check if the notification startup respect theses conditions :
 * - appNextStartup is true
 * - displayTemplate is Template.Slideshow
 * - daysBetweenTriggers === 0 (display each time)
 * - if a nextDateDisplaySlideshow is not present in local storage
 * - if a nextDateDisplaySlideshow is present in local storage, the current date time will be superior or equal of it
 * @param notificationSlideshow {ApiV2NavigationNotification} the notifiaction startup object
 * @returns true if the notification respecting the conditions
 */
export const isSlideshowToDisplay = (notificationStartup: ApiV2NavigationNotification, accountId: number): boolean => {
  const { contentID, onTrigger, triggers } = notificationStartup;
  const { displayTemplate } = onTrigger || {};
  // @todo remove 'as any' when hodor types 'triggers' will have property daysBetweenTriggers
  const { appNextStartup, daysBetweenTriggers } = triggers as any;

  if (displayTemplate !== Template.Slideshow || !appNextStartup) {
    return false;
  }

  // get  from local storage the next date the slideshow have to be displayed
  const slideshowDates: Record<string, number> = JsonLocalStorage.get(`${SlideshowStorageDateKey}${accountId}`) || {};
  const nextDateDisplaySlideshow = contentID ? slideshowDates[contentID] : undefined;

  return daysBetweenTriggers === 0 || !nextDateDisplaySlideshow || nextDateDisplaySlideshow <= Date.now();
};

/**
 * Get the first startup slideshow from the list respecting conditions :
 * - appNextStartup is true
 * - displayTemplate is Template.Slideshow
 * - daysBetweenTriggers === 0 (display each time)
 * - if a nextDateDisplaySlideshow is not present in local storage
 * - if a nextDateDisplaySlideshow is present in local storage, the current date time will be superior or equal of it
 * @param startupNotifications list of ApiV2NavigationNotification
 * @returns ApiV2NavigationNotification
 */
export const getStartupSlideshowToDisplay = (
  startupNotifications: ApiV2NavigationNotification[],
  accountId: number
): ApiV2NavigationNotification | undefined => {
  return startupNotifications.find((startupNotification) => isSlideshowToDisplay(startupNotification, accountId));
};

/**
 * Get a copy of slideshowDates whithout the expired entries (date slideshowDates <= dateTime )
 * @param slideshowDates object Record<string, number>
 * @param dateTime dateTime in ms
 * @returns object Record<string, number> filtered
 */
export const getSlideshowStorageDatesFiltered = (
  slideshowDates: Record<string, number>,
  dateTime: number = Date.now()
): Record<string, number> => {
  const newSlideshowDates: Record<string, number> = {};

  Object.keys(slideshowDates).forEach((key) => {
    if (slideshowDates[key] > dateTime) {
      newSlideshowDates[key] = slideshowDates[key];
    }
  });

  return newSlideshowDates;
};

/**
 * Save in local storage the startupSlideshow next date time (current date + daysBetweenTriggers)
 * @param contentID id of the notification slideshow
 * @param startupSlideshowToDisplay ApiV2NavigationNotification
 */
export const saveSlideshowDisplayDate = (
  startupSlideshowToDisplay: ApiV2NavigationNotification,
  accountId: number
): void => {
  const { contentID, triggers } = startupSlideshowToDisplay;
  // @todo remove 'as any' when hodor types 'triggers' will have property daysBetweenTriggers
  const { daysBetweenTriggers } = triggers as any;

  if (daysBetweenTriggers === 0 || !contentID) {
    return;
  }

  const slideshowDates: Record<string, number> = JsonLocalStorage.get(`${SlideshowStorageDateKey}${accountId}`) || {};

  // if daysBetweenTriggers is undefined, we have to display 1 time the slideshow. Set the next date at the max MaxDayStorage
  const now = Date.now();
  if (daysBetweenTriggers) {
    slideshowDates[contentID] = now + daysBetweenTriggers * DayInMs;
  } else {
    slideshowDates[contentID] = now + MaxDayStorage * DayInMs;
  }

  JsonLocalStorage.set(`${SlideshowStorageDateKey}${accountId}`, getSlideshowStorageDatesFiltered(slideshowDates, now));
};
