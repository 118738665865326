export const KEY_UP = 0;
export const KEY_DOWN = 1;
export const KEY_LEFT = 2;
export const KEY_RIGHT = 3;
export const KEY_ENTER = 4;
export const KEY_BACK = 5;

export type Key =
  | typeof KEY_UP
  | typeof KEY_DOWN
  | typeof KEY_LEFT
  | typeof KEY_RIGHT
  | typeof KEY_ENTER
  | typeof KEY_BACK;

const DIRECTION_KEYS = [KEY_UP, KEY_DOWN, KEY_LEFT, KEY_RIGHT] as const;

export type DirectionKey = (typeof DIRECTION_KEYS)[number];
