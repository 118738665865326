import { canUseNewrelic, isR7Available } from '../window/window-helper';

const wait = (ms: number): Promise<any> => new Promise((resolve) => setTimeout(resolve, ms)); // eslint-disable-line no-promise-executor-return

/**
 * Wait for global object `newrelic` to exist with a timeout
 */
const waitNewrelic = async (tryCount: number = 0): Promise<void> => {
  if (!canUseNewrelic()) {
    await wait(1000 * tryCount); // wait 1 second * tryCount
    await waitNewrelic(tryCount + 1);
  }
};

/**
 * Retrieve platform properties from one-core api (if available) to send to newrelic
 */
const newrelicGetPlatformProperties = async (): Promise<{ name: string; value: string }[]> => {
  return new Promise((resolve, reject) => {
    if (isR7Available()) {
      window.R7('getSystem', (err, data) => {
        if (err) {
          return reject(err);
        }

        resolve([
          { name: 'target', value: data.target },
          { name: 'targetGroup', value: data.target_group || 'unknown' },
          { name: 'platform', value: data.platform || 'unknown' },
          { name: 'manufacturerName', value: data.manufacturer_name || 'unknown' },
          { name: 'manufacturerVersion', value: data.manufacturer_version || 'unknown' },
        ]);
      });
    } else {
      resolve([{ name: 'target', value: 'web' }]);
    }
  });
};

/**
 * Send newrelic global information
 * @param  {string}  webappVersion - webapplication version
 */
export const newrelicSendGlobalInfo = async (webappVersion: string): Promise<void> => {
  await waitNewrelic();
  // eslint-disable-next-line @typescript-eslint/dot-notation
  const newrelic = window['newrelic'];
  // add the version of the OS
  const osVersionResult = navigator.userAgent.match(/\((.+?)\)/);
  const userOsVersion = osVersionResult && osVersionResult.length >= 1 ? osVersionResult[1] : 'Unknown';
  newrelic.setCustomAttribute('userOsVersion', userOsVersion);

  if (typeof (global as any).OnePlayer !== 'undefined') {
    newrelic.setCustomAttribute('playerVersion', (global as any).OnePlayer.__version__); // eslint-disable-line no-underscore-dangle
  }

  // Add the webapp version
  newrelic.setCustomAttribute('webappVersion', webappVersion);
  newrelic.addRelease('webappVersion', webappVersion);

  // Add device specific information
  try {
    const properties = await newrelicGetPlatformProperties();

    properties.forEach(({ name, value }) => {
      newrelic.setCustomAttribute(name, value);
    });
  } catch (error) {
    console.error(error);
  }
};

/**
 * Send anonymous user information
 * @param  {object}  userData - userData object
 * @param  {string}  pageType - Page type
 * @param {string}   pageName - Page name
 * @param {string}   offerLocation - offerLocation
 */
export const newrelicSendUserInfo = async (
  userData: any,
  pageType: string,
  pageName: string,
  offerLocation: string
): Promise<void> => {
  await waitNewrelic();
  // Add anonymous userInfo
  const micros = userData.microEligibility;
  // eslint-disable-next-line no-nested-ternary
  const userStatus = micros.indexOf('O_ABO') >= 0 ? 'Abonne' : userData.subscriberId ? 'Prospect' : 'Inconnu';

  // eslint-disable-next-line @typescript-eslint/dot-notation
  const newrelic = window['newrelic'];

  newrelic.setCustomAttribute('userStatus', userStatus);
  newrelic.setCustomAttribute('userId', userData.subscriberId);
  newrelic.setCustomAttribute('userAuthLevel', userData.auth_level);
  newrelic.setCustomAttribute('userActivationStatus', userData.activation_status);
  // We are temporarily removing information about Micros from newrelic metrics \
  // to confirm the hypothesis that this is the cause of the unexplained volume increase since March 2021.
  // newrelic.setCustomAttribute('userMicros', userData.microEligibility);
  newrelic.setCustomAttribute('app_zone', offerLocation);
  newrelic.setPageViewName(pageName);
  if (pageType) {
    newrelic.setCustomAttribute('pageType', pageType);
  }
};

export const newrelicSendPerformanceEntries = (entries: PerformanceEntryList): void => {
  waitNewrelic()
    .then(() => {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      const newrelic = window['newrelic'];

      entries.forEach((entry) => {
        newrelic.addToTrace({
          name: entry.name,
          type: entry.entryType,
          start: entry.startTime,
          end: entry.entryType === 'measure' ? entry.startTime + entry.duration : undefined,
        });
      });
    })
    // We don't care if its not sent
    .catch(() => {});
};
