import { ApiV2AvatarV1Content } from '@dce-front/hodor-types/api/v2/avatars/v1/definitions';
import { ApiV2AvatarsV2Content, ApiV2AvatarsV2Strate } from '@dce-front/hodor-types/api/v2/avatars/v2/definitions';

/**
 * This method generates a list of avatar contents from data passed in parameters.
 * If isAvatarV2 is truthy, the method considers the data as a list of strates and take all of the avatar contents
 * from each strate to return a single array containing all of the items.
 * If isAvatarV2 is falsy, the method considers the data as an already existing avatar contents list and simply returns it.
 * @example
 * // Returns [{ URLImage: 'URLImage', contentID: 1 }]
 * getFlattenedAvatarContents({ data: [{ URLImage: 'URLImage', contentID: 1 }], isAvatarV2: false })
 * // Returns [{ URLImage: 'URLImage1', contentID: 1 }, { URLImage: 'URLImage2', contentID: 2 }]
 * getFlattenedAvatarContents({
 *   isAvatarV2: false
 *   data: [{
 *     title: 'strateTitle1',
 *     contents: [{
 *         URLImage: 'URLImage',
 *         contentID: 1
 *     }]
 *   },
 *   {
 *     title: 'strateTitle2',
 *     contents: [{
 *         URLImage: 'URLImage2',
 *         contentID: 2
 *     }]
 *   }],
 * })
 */
export const getFlattenedAvatarContents = ({
  data,
  isAvatarV2,
}: {
  data?: ApiV2AvatarV1Content[] | ApiV2AvatarsV2Strate[];
  isAvatarV2: boolean;
}): ApiV2AvatarsV2Content[] | ApiV2AvatarV1Content[] => {
  if (!data) {
    return [];
  }

  if (isAvatarV2) {
    return (data as ApiV2AvatarsV2Strate[]).reduce((contents: ApiV2AvatarsV2Content[], strate) => {
      if (strate.contents) {
        contents.push(...strate.contents);
      }

      return contents;
    }, []);
  }

  return data as ApiV2AvatarV1Content[];
};
