import { IContentSections, ICurrentPage, ITracking } from '@canalplus/mycanal-sdk';
import { ContentType } from '@canalplus/sdk-hodor';
import { ISectionsListState } from './types';

type SectionListStrate = {
  type: ContentType;
  title?: string;
  contents: IContentSections[];
};

export type SectionListPayload = {
  currentPage: ICurrentPage;
  strates: SectionListStrate[];
  tracking: ITracking;
};

const getFormattedSectionsList = (data: SectionListPayload): ISectionsListState => {
  return {
    currentPage: data.currentPage,
    strates: data?.strates.filter(({ type }) => type !== ContentType.Profiles),
    tracking: data.tracking,
  } as ISectionsListState;
  // We need "as" here due to custom payload, we can't really find a perfect type
  // Hodor response type is always any
};

export default getFormattedSectionsList;
