import { HeaderNavDropdown } from '@canalplus/dive';
import { setCookie } from '@canalplus/mycanal-commons';
import { mapStaticKey } from '@canalplus/mycanal-util-react';
import { ApiV2NavigationItem } from '@dce-front/hodor-types/api/v2/authenticate/definitions';
import classNames from 'classnames';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import IconSubscribe from '../../../assets/svg/subscribe.svg';
import Linker from '../../../components/Linker/Linker';
import { CookieKey } from '../../../constants/cookie';
import { computeApiV2OnClickFromNavigationItemOnClick } from '../../../helpers/hodor/computeApiV2OnClickFromNavigationItemOnClick';
import { userMenuLanguagesSelector, zoneInfoSelector } from '../../../selectors/application/application-selectors';
import { isPhoneResolutionSelector } from '../../../store/slices/ui-selectors';
import { SKIP_LINK_ID } from '../../SkipLink/constants';
import { HeaderProfileMyCanalConnected } from '../HeaderProfileMyCanal/HeaderProfileMyCanalConnected';
import { HeaderSearchButtonMyCanalConnected } from '../HeaderSearchButtonMyCanal/HeaderSearchButtonMyCanalConnected';
import styles from './UserMenuMyCanal.css';
import { useIsOverflowing } from './useIsOverflowing';

export type UserMenuMyCanalProps = {
  activePath: string;
  isTvDevice?: boolean;
  userMenu: ApiV2NavigationItem[];
};

function UserMenuMyCanal({ activePath, isTvDevice = false, userMenu }: UserMenuMyCanalProps): JSX.Element {
  const userMenuWithStaticKeys = mapStaticKey(userMenu, 'type');
  const isPhoneResolution = useSelector(isPhoneResolutionSelector);
  const userMenuLanguages = useSelector(userMenuLanguagesSelector);
  const zoneInfo = useSelector(zoneInfoSelector);
  const containerRef = useRef<HTMLDivElement>(null);
  const subscribeLabelRef = useRef<HTMLDivElement>(null);

  const isOverflowingOnMobile = useIsOverflowing({
    ref: subscribeLabelRef,
    containerRef,
    enabled: isPhoneResolution,
    computeAvailableSpace: (containerEl) => {
      // Because of justify-content: flex-end, we calculate the available space in container by subtracting the
      // distance between the container.DOMRect.left and the first element.DOMRect.left
      const containerFirstElementLeft = containerEl.firstElementChild?.getBoundingClientRect().left || 0;
      const containerLeft = containerEl.getBoundingClientRect().left || 0;
      return Math.abs(containerLeft - containerFirstElementLeft);
    },
  });

  return (
    <div ref={containerRef} className={styles.UserMenuMyCanal}>
      {userMenuWithStaticKeys.map((item) => {
        const { type, picto, onClick, hash, buttonLabel } = item;

        switch (type) {
          case 'picto':
            if (picto === 'search') {
              return (
                <div className="Search" id={isTvDevice ? type : undefined} key="search-icon" data-e2e="search">
                  <HeaderSearchButtonMyCanalConnected
                    data={item}
                    id={isTvDevice ? `${type}_onclick` : SKIP_LINK_ID.appHeaderSearch}
                    isActive={activePath === onClick?.path}
                    Linker={Linker}
                    navigateOnClick={isTvDevice}
                  />
                </div>
              );
            }

            return null;

          case 'currentProfile':
            return (
              <div className="CurrentProfile" key="current-profile" data-e2e="currentProfile">
                <HeaderProfileMyCanalConnected
                  id={`${type}-${onClick?.displayName || ''}`}
                  navigateOnClick={isTvDevice}
                  data={item}
                  isActive={activePath === onClick?.path}
                />
              </div>
            );

          case 'button':
            return (
              <Linker
                data={{ mainOnClick: onClick ? computeApiV2OnClickFromNavigationItemOnClick(onClick) : undefined }}
                className={styles.UserMenuMyCanal__button}
                id={`${type}-${buttonLabel}`}
                data-e2e="userMenuButton"
                key={hash}
              >
                <IconSubscribe
                  className={classNames(styles.UserMenuMyCanal__button__icon, {
                    [styles['UserMenuMyCanal__button__icon--overflowing']]: isOverflowingOnMobile,
                  })}
                />
                <div
                  ref={subscribeLabelRef}
                  className={classNames(styles.UserMenuMyCanal__button__label, {
                    [styles['UserMenuMyCanal__button__label--overflowing']]: isOverflowingOnMobile,
                  })}
                >
                  {buttonLabel}
                </div>
              </Linker>
            );

          case 'text': {
            if (userMenuLanguages && onClick?.displayTemplate === 'languageSelector') {
              const currentLang = zoneInfo.lang?.toUpperCase();

              const onSetOfferZone = (offerZone: string) => {
                setCookie(CookieKey.Offerzone, offerZone);
              };

              return (
                <HeaderNavDropdown
                  key="language-selector"
                  items={userMenuLanguages.languages[0]
                    .filter((lang) => lang.language.toUpperCase() !== currentLang)
                    .map((language) => ({
                      label: language.languageLabel,
                      href: language.languagePath,
                      onClick: () => onSetOfferZone(language.offerZone),
                    }))}
                  label={currentLang}
                  tabIndex={0}
                  dropdownPlacement="bottom-end"
                />
              );
            }

            return null;
          }

          default:
            return null;
        }
      })}
    </div>
  );
}

export default UserMenuMyCanal;
