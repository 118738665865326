import { isSomeEnum } from '@canalplus/mycanal-commons';
import { Template } from '@canalplus/sdk-hodor';
import { ApiV2StrateContentButton } from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import { ApiV2PageStrate } from '@dce-front/hodor-types/api/v2/page/dtos/definitions';
import { ApiV2PageStratePromotion } from '@dce-front/hodor-types/api/v2/page/dtos/strates/promotion/definitions';
import { getLocationStateContext, setContextForOnClick } from '../../../../../helpers/contents/contents-helper';
import {
  INFO_DISPLAY_MODE,
  PROMOTION_STRATE_TYPES,
} from '../../../../../templates/LandingV5/components/PromotionStrate/constants';
import {
  ButtonData,
  PromotionStrateContentCatchlines,
  PromotionStrateData,
} from '../../../../../templates/LandingV5/components/PromotionStrate/data/types';
import { PromotionStateTypes } from '../../../../../templates/LandingV5/components/PromotionStrate/types';
import getPromotionVideoContent from './getPromotionVideoContent';

const isTemplate = isSomeEnum(Template);

const isStratePromotion = (strate?: ApiV2PageStrate): strate is ApiV2PageStratePromotion =>
  strate?.type === PROMOTION_STRATE_TYPES.cover || strate?.type === PROMOTION_STRATE_TYPES.banner;

const formatCatchlines = (
  catchlines?: PromotionStrateContentCatchlines
): NonNullable<PromotionStrateContentCatchlines> => {
  if (!catchlines) {
    return [];
  }

  const filteredCatchlines = catchlines.filter((catchline) => catchline.label && catchline.type);
  const formattedCatchlines: PromotionStrateContentCatchlines = [];

  filteredCatchlines.forEach((catchline) => {
    String(catchline.label)
      .split('\n')
      .forEach((label) => {
        formattedCatchlines.push({
          type: catchline.type,
          label,
        });
      });
  });

  return formattedCatchlines;
};

const formatButtonWithContext = (button: ApiV2StrateContentButton): ButtonData => {
  const { onClick } = button;
  return setContextForOnClick({
    ...button,
    onClick: {
      ...onClick,
      ...(onClick?.contentID && { contentID: onClick?.contentID }), // Button action: display the player with a content
      ...(onClick?.EpgId && { EpgId: onClick?.EpgId }), // Button action: display a live channel
      ...(isTemplate(onClick?.displayTemplate) && { context: getLocationStateContext(onClick.displayTemplate) }),
    },
  });
};

const getFormattedPromotionStrate = (strate?: ApiV2PageStrate): PromotionStrateData | undefined => {
  if (!isStratePromotion(strate) || !strate.contents?.[0]) {
    return;
  }

  const content = strate.contents[0];

  const { altLogoChannel, altLogoType, altText, catchlines, contentID, cover, URLLogoChannel, URLLogoType } = content;

  const button = content.button as ApiV2StrateContentButton;

  if (!contentID || !cover?.regular?.URLImageDefault || !button?.label || !button?.onClick?.parameters) {
    return;
  }

  const infoDisplayMode =
    !strate.displayParameters || !strate.displayParameters.infoDisplayMode
      ? INFO_DISPLAY_MODE.persistent
      : strate.displayParameters.infoDisplayMode;

  return {
    promotionType: strate.type as PromotionStateTypes,
    content: {
      contentID,
      isOutroOnly: infoDisplayMode === INFO_DISPLAY_MODE.outroOnly,
      altLogoChannel,
      URLLogoChannel,
      altLogoType,
      altText,
      URLLogoType,
      catchlines: formatCatchlines(catchlines),
      button: formatButtonWithContext(button),
      media: {
        regular: {
          URLImageDefault: cover.regular.URLImageDefault,
          video: getPromotionVideoContent(cover.regular.videos),
          videoOutro: getPromotionVideoContent(cover.regular.videosOutro),
        },
        ...(cover.compact && {
          compact: {
            URLImageDefault: cover.compact.URLImageDefault || cover.regular.URLImageDefault,
            video: getPromotionVideoContent(cover.compact.videos),
            videoOutro: getPromotionVideoContent(cover.compact.videosOutro),
          },
        }),
      },
    },
  };
};

export default getFormattedPromotionStrate;
