import { IChannel, IContent, IPaging, ITvodErrorMessage } from '@canalplus/mycanal-sdk';
import { ApiV2Context } from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import type { LocationStateContext } from '../../typings/routing';
import { getPublicConfig } from '../config/config-helper';
import { getCurrentTimestamp } from '../date/date-helper';
import { mapContextListItemPosition } from '../tracking/tracking-helper';

/**
 * @param  {string} url   original url
 * @param  {string} key   params to replace
 * @param  {string} value replace value
 * @returns {string}
 */
export const updateQueryStringParameter = (url: string, key: string, value: string | number): string => {
  const re = new RegExp(`([?&])${key}=.*?(&|$)`, 'i');
  const separator = url.indexOf('?') !== -1 ? '&' : '?';
  if (url.match(re)) {
    return url.replace(re, `$1${key}=${value}$2`);
  }
  return `${url}${separator}${key}=${value}`;
};

/**
 * @param  {object} [paging] index iterationType paging object
 * @returns {bool}            true if content grid has more contents to load
 */
export const isLoadingMore = (paging: IPaging): boolean => {
  if (paging.indexEnd && paging.totalAvailableContents) {
    const { indexEnd, totalAvailableContents } = paging;
    return indexEnd + 1 < totalAvailableContents;
  }
  return false;
};

/**
 * isPaginationIndexType
 *
 * helper to check what kind of pagination is in place
 * since we have two different type of webservice
 * it's basically a switch between the old and new pagination system
 *
 * @param  {object} [paging] paging object
 * @returns {bool}         true if paging system is index
 */
export const isPaginationIndexType = (paging: IPaging): boolean => paging.iterationType === 'index';

/**
 * getNextContentsUrlIndexType
 *
 * return the next url for index iterationType
 *
 * @param  {object} [paging] index iterationType paging object
 * @returns {string}          url to fetch to get next contents in a content grid template
 */
export const getNextContentsUrlIndexType = (paging: IPaging): string | undefined => {
  if (!isLoadingMore(paging)) {
    return undefined;
  }
  const { URLPage, indexEnd, nbContents = 0, nbContent = 0 } = paging;
  if (URLPage && indexEnd) {
    let next = updateQueryStringParameter(URLPage, 'get', nbContents || nbContent);
    next = updateQueryStringParameter(next, 'from', indexEnd + 1);
    return next;
  } else {
    return undefined;
  }
};

/**
 * getNextContentsUrl
 *
 * this method creates a switch between the two types
 * of pagination and returns the correct next url
 *
 * @param  {object} [paging] paging object if any
 * @returns {string}          returns next url or undefined
 */
export const getNextContentsUrl = (paging: IPaging): any => {
  if (isPaginationIndexType(paging)) {
    return getNextContentsUrlIndexType(paging);
  }

  return paging.hasNextPage ? paging.URLPage : undefined;
};

export const getLocationStateContext = (displayTemplate?: string): LocationStateContext => {
  const publicConfig = getPublicConfig();

  switch (displayTemplate) {
    case publicConfig.TEMPLATE.DETAIL_LIGHT:
    case publicConfig.TEMPLATE.DETAIL_PAGE:
    case publicConfig.TEMPLATE.DETAIL_SEASON:
    case publicConfig.TEMPLATE.DETAIL_SHOW:
    case publicConfig.TEMPLATE.QUICKTIME:
    case publicConfig.TEMPLATE.CREATIVE_MEDIA:
    case publicConfig.TEMPLATE.SLIDESHOW:
    case publicConfig.TEMPLATE.CONTEXTUAL_OFFER:
    case publicConfig.TEMPLATE.PAYMENT_MEANS:
    case publicConfig.TEMPLATE.STUB:
      return 'openImmersive';

    default:
      return undefined;
  }
};

export const mergeContents = (currentContents: any[], contents: any[] = [], pagination: boolean = false): IContent[] =>
  currentContents && pagination ? [...currentContents, ...contents] : contents;

/**
 * buildLabel
 *
 * @param  {string|array} [labels] label of content error
 * @returns {array}        formatted label with content and href keys in object
 */

export const buildLabel = (labels: string | any[]): ITvodErrorMessage[] => {
  if (Array.isArray(labels)) {
    const formattedLabels = labels.map((label) => {
      if (Array.isArray(label)) {
        const [content, href] = label;
        return { content, href };
      } else {
        return { content: label, href: undefined };
      }
    });
    return formattedLabels;
  }

  return [{ content: labels, href: undefined }];
};

/**
 * setContextForOnClick
 *
 * @param {object} button
 * @returns {object}
 */
export const setContextForOnClick = (button: any): any => {
  const newButton = { ...button };
  if (newButton.onClick) {
    newButton.context = getLocationStateContext(newButton.onClick.displayTemplate);
  }
  return newButton;
};

/**
 * Add to a content:
 * - **trackingContext** if needed
 * - **locationStateContext**
 */
export const getContentWithContexts = <
  T extends {
    onClick?: Routing.IMyCanalOnClick;
    button?: { onClick?: Routing.IMyCanalOnClick };
    [T: string]: unknown;
  },
>(
  content: T,
  trackingContext?: ApiV2Context
): T => {
  const { onClick, button } = content;
  const hasOnClickButton = !onClick && button && button.onClick;
  const onClickElement = hasOnClickButton ? button.onClick : onClick;
  const { displayTemplate } = onClickElement || {};
  const locationStateContext = getLocationStateContext(displayTemplate);
  const publicConfig = getPublicConfig();

  const hasTrackingContext =
    locationStateContext === 'openImmersive' ||
    displayTemplate === publicConfig.TEMPLATE.FULLSCREEN ||
    displayTemplate === publicConfig.TEMPLATE.LANDING ||
    displayTemplate === publicConfig.TEMPLATE.CONTENT_GRID ||
    displayTemplate === publicConfig.TEMPLATE.GABARIT_LIST ||
    displayTemplate === publicConfig.TEMPLATE.EXTERNAL_SITE;

  const trackingContextData = hasTrackingContext && trackingContext && { trackingContext };

  if (hasOnClickButton) {
    return {
      ...content,
      ...(button && {
        button: {
          ...button,
          onClick: {
            ...onClickElement,
            ...trackingContextData,
          },
        },
      }),
      context: locationStateContext,
    };
  }

  return {
    ...content,
    ...(onClick && {
      onClick: {
        ...onClick,
        ...trackingContextData,
      },
    }),
    context: locationStateContext,
  };
};

/**
 * getContentsWithContext
 *
 * @param {array} contents
 * @param {object} trackingContext used to track the context of reading a content
 * @returns {array}
 */
export const getContentsWithContext = (contents: any[] = [], trackingContext?: any): any[] => {
  const contentsWithContext = contents.map((content) => getContentWithContexts(content, trackingContext));
  return mapContextListItemPosition(contentsWithContext);
};

/**
 * getContentsOnlyWithTrackingContext
 *
 * @param {array} contents
 * @param {object} trackingContext used to track the context of reading a content
 * @returns {array}
 */
// TODO: Type `contents` with a union of types it can take so the return type of the function can be determined by itself
// After that, we'll be able to remove the `T` typing
export const getContentsOnlyWithTrackingContext = <T>(contents: any[], trackingContext: ApiV2Context): T[] =>
  contents.map((content) => {
    if (content.onClick && trackingContext) {
      content.onClick.trackingContext = trackingContext;
    }
    return content;
  });

/**
 * hasValidLiveTVContents
 *
 * @param {array} contents
 * @param {number} currentTime used to check if there is currently on air content
 * @returns {array}
 */

export const hasValidLiveTVContents = (contents: any[], currentTime: number = 0): boolean => {
  const onAirContent = contents.some(
    ({ startTime, endTime }) => startTime > 0 && endTime > 0 && currentTime >= startTime && currentTime <= endTime
  );

  return onAirContent;
};

/**
 * getChannelCurrentLiveContent
 *
 * Find the content that is currently live within the contents available for the current channel
 *
 * @param {object} channel data from contentRow
 * @returns {object}
 */
export const getChannelCurrentLiveContent = (channel: IChannel): IContent | null => {
  if (!channel?.contents) return null;

  const currentContent = channel.contents.find((content) => {
    const { startTime = 0, endTime = 0 } = content;
    const currentTimestamp = getCurrentTimestamp();

    return startTime <= currentTimestamp && currentTimestamp < endTime;
  });

  return {
    ...currentContent,
    URLLogoChannel: channel.URLLogoChannel || channel.LogoUrl,
    channel: { epgID: channel.epgID },
    epgID: channel.epgID,
  };
};
