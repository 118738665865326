import { HeaderWithSearchLayout, HeaderWithSearchLayoutProps } from '@canalplus/mycanal-sharedcomponent';
import { useSelector } from 'react-redux';
import { useActionCreators } from '../../../helpers/hooks/useActionCreators';
import I18n from '../../../lang';
import { closeSearch, querySearch } from '../../../store/slices/search';
import { isSearchActiveSelector, searchQuerySelector } from '../../../store/slices/search-selectors';

export function HeaderWithSearchLayoutMyCanalConnected(
  props: Omit<
    HeaderWithSearchLayoutProps,
    'onClose' | 'onSearch' | 'dark' | 'isSearchOpen' | 'searchDefaultQuery' | 'closeAriaLabel' | 'searchAriaLabel'
  >
): JSX.Element {
  const { t } = I18n.useTranslation();

  const isSearchOpen = useSelector(isSearchActiveSelector);
  const searchDefaultQuery = useSelector(searchQuerySelector);

  const actions = useActionCreators({ onClose: closeSearch, onSearch: querySearch });

  const closeAriaLabel = t('Search.closeSearchTriggerLabel');
  const searchAriaLabel = t('Search.searchInputLabel');

  return (
    <HeaderWithSearchLayout
      {...props}
      {...actions}
      closeAriaLabel={closeAriaLabel}
      isSearchOpen={isSearchOpen}
      searchAriaLabel={searchAriaLabel}
      searchDefaultQuery={searchDefaultQuery}
    />
  );
}
