import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../../../../../helpers/hooks/useAppDispatch';
import { userChangeSettings } from '../../../../../../../../store/slices/user';
import { isTrailerAutoplaySelector, settingsSelector } from '../../../../../../../../store/slices/user-selectors';

/**
 * Users can enable/disable the trailers autoplay
 * This value is stored in local storage and redux store
 */
export const useIsTrailerAutoplay = (): {
  isTrailerAutoplay: boolean;
  toggleIsTrailerAutoplay: () => void;
} => {
  const dispatch = useAppDispatch();

  const isTrailerAutoplay = useSelector(isTrailerAutoplaySelector);
  const settings = useSelector(settingsSelector);

  const toggleIsTrailerAutoplay = () => {
    dispatch(userChangeSettings({ ...settings, isTrailerAutoplay: !isTrailerAutoplay }));
  };

  return {
    isTrailerAutoplay,
    toggleIsTrailerAutoplay,
  };
};
