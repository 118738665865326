import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../helpers/hooks/useAppDispatch';
import { userChangeSettings } from '../../../store/slices/user';
import {
  areAnimationsAutoplaySelector,
  isTrailerAutoplaySelector,
  settingsSelector,
} from '../../../store/slices/user-selectors';

/**
 * For accessibility, we must allow the users to enable/disable all animations by one action
 * "All animations" here targets trailers, highlighOffer and should be use in future to manage others animated components
 * This value is stored in local storage and redux store
 */
export const useAreAllAnimationsAutoplay = (): {
  areAllAnimationsAutoplay: boolean;
  changeAreAllAnimationsAutoplay: () => void;
} => {
  const dispatch = useAppDispatch();

  const areAnimationsAutoplay = useSelector(areAnimationsAutoplaySelector);
  const isTrailerAutoplay = useSelector(isTrailerAutoplaySelector);
  const settings = useSelector(settingsSelector);
  const areEnabled = areAnimationsAutoplay || isTrailerAutoplay;

  const changeAreAllAnimationsAutoplay = () => {
    dispatch(userChangeSettings({ ...settings, areAnimationsAutoplay: !areEnabled, isTrailerAutoplay: !areEnabled }));
  };

  return {
    areAllAnimationsAutoplay: areEnabled,
    changeAreAllAnimationsAutoplay,
  };
};
