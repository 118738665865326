import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { newrelicSendGlobalInfo } from '../../../helpers/newrelic/newrelic-helper';
import { getFeatureToggleNewrelic } from '../../../selectors/application/application-selectors';

/**
 * Send Newrelic global info\
 * Used on mount only.
 */
export const useSendNewrelicGlobalInfo = (): void => {
  const isNewrelicEnabled = useSelector(getFeatureToggleNewrelic);

  useEffect(() => {
    (async function performSendNewrelicGlobalInfo() {
      if (isNewrelicEnabled && $_BUILD_APP_VERSION) {
        await newrelicSendGlobalInfo($_BUILD_APP_VERSION);
      }
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};
