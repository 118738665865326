import { UserMenuOptionGroup } from '@canalplus/mycanal-sharedcomponent';
import { Binder, useActiveLayer } from '@canalplus/one-navigation';
import { useSelector } from 'react-redux';
import { LAYER_MORE_SETTINGS_ANIMATIONS } from '../../../../../../../helpers/oneNavigation/layers';
import I18n from '../../../../../../../lang';
import {
  getFeatureTogglePromotionBanner,
  getFeatureTogglePromotionCover,
  getFeatureToggleTrailerPreview,
} from '../../../../../../../selectors/application/application-selectors';
import { displayTVModeSelector } from '../../../../../../../store/slices/displayMode-selectors';
import { ButtonSettings } from '../../../../../../ButtonSettings/ButtonSettings';
import Linker from '../../../../../../Linker/Linker';
import styles from './AnimationsAutoplay.css';
import { useAreAnimationsAutoplay } from './hooks/useAreAnimationsAutoplay';
import { useAreAnimationsMuted } from './hooks/useAreAnimationsMuted';
import { useIsTrailerAutoplay } from './hooks/useIsTrailerAutoplay';
import { useIsTrailerMuted } from './hooks/useIsTrailerMuted';

export type AnimationsAutoplayBinderProps = {
  id: string;
  children: React.ReactNode;
  isTvDevice: boolean;
};
export type AnimationsAutoplayProps = {
  title: string;
};

function AnimationsAutoplay(props: AnimationsAutoplayProps): JSX.Element {
  const { title } = props;
  const hasPromotionBanner = useSelector(getFeatureTogglePromotionBanner);
  const hasPromotionCover = useSelector(getFeatureTogglePromotionCover);
  const hasTrailer = useSelector(getFeatureToggleTrailerPreview);
  const { isTrailerAutoplay, toggleIsTrailerAutoplay } = useIsTrailerAutoplay();
  const { isTrailerMuted, toggleIsTrailerMuted } = useIsTrailerMuted();
  const { areAnimationsAutoplay, toggleAreAnimationsAutoplay } = useAreAnimationsAutoplay();
  const { areAnimationsMuted, toggleAreAnimationsMuted } = useAreAnimationsMuted();
  const isTvDevice = useSelector(displayTVModeSelector);
  const { t } = I18n.useTranslation();

  useActiveLayer(LAYER_MORE_SETTINGS_ANIMATIONS, true);

  return (
    <div className={styles.animationsAutoplay__container}>
      <Binder layer={LAYER_MORE_SETTINGS_ANIMATIONS}>
        <h1 className={styles.animationsAutoplay__title}>{title}</h1>
        {hasTrailer && (
          <div className={styles.animationsAutoplay__group}>
            <UserMenuOptionGroup title={t('SettingsTemplate.trailer')} key="sections-strate-trailer" Linker={Linker}>
              <ButtonSettings
                handler={toggleIsTrailerAutoplay}
                isTvDevice={isTvDevice}
                subText={isTrailerAutoplay ? t('SettingsTemplate.enabled') : t('SettingsTemplate.disabled')}
                text={t('SettingsTemplate.trailerAutoplayExplanation')}
              />
              <ButtonSettings
                handler={toggleIsTrailerMuted}
                isTvDevice={isTvDevice}
                subText={!isTrailerMuted ? t('SettingsTemplate.enabled') : t('SettingsTemplate.disabled')}
                text={t('SettingsTemplate.trailerSoundExplanation')}
              />
            </UserMenuOptionGroup>
          </div>
        )}
        {(hasPromotionBanner || hasPromotionCover) && (
          <div className={styles.animationsAutoplay__group}>
            <UserMenuOptionGroup title={t('SettingsTemplate.animations')} key="sections-strate-trailer" Linker={Linker}>
              <ButtonSettings
                handler={toggleAreAnimationsAutoplay}
                isTvDevice={isTvDevice}
                subText={areAnimationsAutoplay ? t('SettingsTemplate.enabled') : t('SettingsTemplate.disabled')}
                text={t('SettingsTemplate.animationsAutoplayExplanation')}
              />
              <ButtonSettings
                handler={toggleAreAnimationsMuted}
                isTvDevice={isTvDevice}
                subText={!areAnimationsMuted ? t('SettingsTemplate.enabled') : t('SettingsTemplate.disabled')}
                text={t('SettingsTemplate.animationsSoundExplanation')}
              />
            </UserMenuOptionGroup>
          </div>
        )}
      </Binder>
    </div>
  );
}

export default AnimationsAutoplay;
