import { HeaderSearchButton, HeaderSearchButtonProps } from '@canalplus/mycanal-sharedcomponent';
import { useActionCreators } from '../../../helpers/hooks/useActionCreators';
import I18n from '../../../lang';
import { setSearchActive } from '../../../store/slices/search';

export function HeaderSearchButtonMyCanalConnected(
  props: Omit<HeaderSearchButtonProps, 'ariaLabel' | 'onClick'>
): JSX.Element {
  const { t } = I18n.useTranslation();
  const actions = useActionCreators({ onClick: setSearchActive });

  return <HeaderSearchButton {...props} {...actions} ariaLabel={t('Search.activateSearchTriggerLabel')} />;
}
