import { isClientSide } from '@canalplus/mycanal-commons';
import { ApiV2Context } from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import { Tracking } from '@dce-front/hodor-types/api/v2/common/dto/tracking/definitions';
import { ApiV2PageTracking } from '@dce-front/hodor-types/api/v2/page/dtos/definitions';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ThemeColor } from '../../../constants/themeColor';
import {
  initTrackingSelector,
  offerLocationSelector,
  slideshowNotificationsTrackingSelector,
} from '../../../selectors/application/application-selectors';
import {
  updateSlideshowTracking,
  updateSlideshowTrackingContext,
} from '../../../store/reducers/actions/Application-actions';
import { displayTVModeSelector } from '../../../store/slices/displayMode-selectors';
import { setImmersiveTracking } from '../../../store/slices/immersive';
import { updatePageTracking } from '../../../store/slices/page';
import { pageURLSelector } from '../../../store/slices/page-selectors';
import { purchaseInfoSelector } from '../../../store/slices/purchaseCode-selectors';
import { updateSearchTracking } from '../../../store/slices/search';
import { effectiveTrackingContextSelector } from '../../../store/slices/tracking-selectors';
import {
  abTestingPopulationSelector,
  isKidsProfileSelector,
  segTypeSelector,
} from '../../../store/slices/user-selectors';
import type { LocationStateContext } from '../../../typings/routing';
import { sendPlayTrackingEvent, sendTrackingHitOnPage, sendTrackingHitOnSearch } from '../../tracking/tracking-helper';
import type { RawTracking } from '../../tracking/types';
import { useAppDispatch } from '../useAppDispatch';

type OptionsTracking = {
  trackingContext?: ApiV2Context;
  favoritesEpgIds?: number[];
  platform?: string;
  startFromBeginning?: boolean;
  routingContext?: LocationStateContext;
  resultsLength?: number;
};

export type TrackingArgs = {
  tracking?: Tracking | ApiV2PageTracking;
  options?: OptionsTracking;
};

export type UseTrackingReturnType = {
  sendTracking(trackingArgs?: TrackingArgs): void;
  sendPlayTracking(tracking?: Tracking, options?: OptionsTracking): void;
};

/**
 * useTracking
 *
 * Tracking hook that contains all necessary tracking handlers, each with their own logic
 * @return Handlers to be used inside the components/hooks
 */
export const useTracking = (): UseTrackingReturnType => {
  const dispatch = useAppDispatch();
  const abTestingPopulation = useSelector(abTestingPopulationSelector);
  const isKids = useSelector(isKidsProfileSelector);
  const isTvDevice = useSelector(displayTVModeSelector);
  const offerLocation = useSelector(offerLocationSelector);
  const pageURL = useSelector(pageURLSelector);
  const segType = useSelector(segTypeSelector);
  const trackingContext = useSelector(effectiveTrackingContextSelector);
  const initTracking = useSelector(initTrackingSelector);
  const slideshowTracking = useSelector(slideshowNotificationsTrackingSelector);
  const purchaseInfo = useSelector(purchaseInfoSelector);
  /**
   * Generic tracking handler capable of sending Page/Search/Immersive tracking data depending on the context.
   * In the case of non-immersive context, such as homepage or search context page, the tracking data is also
   * stored in redux-store for future purposes, e.g.: when closing an immersive, the background page/search
   * tracking (that was previously stored in redux-store) should be re-sent.
   * Note: Tracking only sent on client side
   * @param trackingArgs.tracking New tracking data to be sent and save to the store (if not immersive tracking)
   * @param trackingArgs.options Options for the tracking, such as context, epgIds, ...
   */
  const sendTracking = useCallback(
    (trackingArgs?: TrackingArgs) => {
      const { tracking, options } = trackingArgs || {};
      const { resultsLength, routingContext, trackingContext: trackingContextOption } = options || {};
      const isPageContext = routingContext === 'page';
      const isImmersiveContext = routingContext === 'openImmersive';
      const isSearchContext = routingContext === 'search';
      const isSlideshowNotificationsContext = routingContext === 'slideshowNotifications';

      const isTvodFunnelContext = tracking?.dataLayer?.page_level_3 === 'Confirmation';
      if (!isClientSide || !tracking || !Object.keys(tracking).length) {
        return;
      }

      const isTrackingContextAvailable = trackingContext && Object.keys(trackingContext).length;
      const isInitTrackingAvailable = initTracking && Object.keys(initTracking).length;

      const { favoritesEpgIds } = options || {};

      const rawTracking = {
        abTestingPopulation,
        isKids,
        tracking,
        offerLocation,
        pageURL,
        segType,
        themeColor: ThemeColor.Dark,
        version: $_BUILD_APP_VERSION,
        ...(isTvodFunnelContext ? { purchaseInfo } : {}),
        ...(isTrackingContextAvailable ? { trackingContext } : {}),
        ...(isInitTrackingAvailable ? { initTracking } : {}),
        ...(favoritesEpgIds && { favoritesEpgIds }),
      } satisfies RawTracking;

      // Tracking to store/update in case of full page or search page
      // We need to store it, only when closing an immersive
      if (isPageContext) {
        dispatch(updatePageTracking(tracking));
      }
      if (isImmersiveContext) {
        dispatch(setImmersiveTracking(tracking));
      }
      if (isSearchContext) {
        dispatch(updateSearchTracking(tracking));
      }
      if (isSlideshowNotificationsContext) {
        dispatch(updateSlideshowTracking(tracking));
        dispatch(updateSlideshowTrackingContext(trackingContextOption));
      } else if (slideshowTracking) {
        // important reset tracking slideshow to have good context for next tracking
        dispatch(updateSlideshowTracking(undefined));
        dispatch(updateSlideshowTrackingContext(undefined));
      }

      if (isSearchContext) {
        sendTrackingHitOnSearch({ ...rawTracking, isTvDevice, resultsLength });
      } else {
        sendTrackingHitOnPage(rawTracking, isTvDevice);
      }
    },
    [
      trackingContext,
      initTracking,
      abTestingPopulation,
      isKids,
      purchaseInfo,
      offerLocation,
      pageURL,
      segType,
      slideshowTracking,
      dispatch,
      isTvDevice,
    ]
  );

  /**
   * Player tracking handler that sends a tracking event on "Play"
   * e.g. LiveTV hit on play
   * @param tracking Tracking object data to be sent
   */
  const sendPlayTracking = useCallback(
    (tracking?: Tracking, options?: OptionsTracking) => {
      if (!tracking) {
        return;
      }

      const { platform, startFromBeginning } = options || {};

      sendPlayTrackingEvent({
        abTestingPopulation,
        isKids,
        pageURL,
        platform,
        segType,
        startFromBeginning,
        themeColor: ThemeColor.Dark,
        tracking,
        initTracking,
        version: $_BUILD_APP_VERSION,
      });
    },
    [initTracking, abTestingPopulation, isKids, pageURL, segType]
  );

  return { sendTracking, sendPlayTracking };
};
