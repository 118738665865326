import { UserMenuOption, UserMenuOptionProps } from '@canalplus/mycanal-sharedcomponent';
import { SyntheticEvent } from 'react';
import { useSelector } from 'react-redux';
import Linker from '../../../components/Linker/Linker';
import { useAuthFromExternalSiteWithIDPToken } from '../../../helpers/hooks/useAuthFromExternalSiteWithIDPToken';
import { idpTokenSelector } from '../../../store/slices/user-selectors';

type UserMenuOptionWithIDPTokenProps = UserMenuOptionProps & {
  authenticationURL: string;
};

export function UserMenuOptionAuthWithIDPToken({
  authenticationURL,
  ...userMenuOptionProps
}: UserMenuOptionWithIDPTokenProps): JSX.Element {
  const idpToken = useSelector(idpTokenSelector);

  const { authenticate } = useAuthFromExternalSiteWithIDPToken(idpToken);

  return (
    <UserMenuOption
      {...userMenuOptionProps}
      handleClick={(e: SyntheticEvent) => {
        e.preventDefault();

        authenticate(authenticationURL);
      }}
      Linker={Linker}
    />
  );
}
