import { DisplayMode } from '@canalplus/mycanal-commons';
import { IContent } from '@canalplus/mycanal-sdk';
import { TPlatform } from '@canalplus/oneplayer-types';
import { isTOfferLocation } from '@canalplus/sdk-core';
import { LinkerData } from '../../components/Linker/types';
import { PlayerPlatform } from '../../constants/playerPlatforms';
import { HTML5_PLAYER } from '../../constants/playerTypes';
import { StrateMode } from '../../constants/strates';
import { getPublicConfig } from '../../helpers/config/config-helper';
import { getContentID } from '../../helpers/fullscreen/fullscreen-helper';
import { FormatMultiLivePlayerData, ILiveGridPlayerData } from '../../helpers/liveTV/liveTV-helper';
import { getOnePlayerBaseUrl } from '../../helpers/url/url-helper';
import {
  fullLocaleSelector,
  getFeatureToggleHdr,
  getFeatureToggleLowLatency,
  getNBOLiveInformations,
  isAndroidSelector,
  offerLocationSelector,
  platformSelector,
  tokenCMSSelector,
} from '../../selectors/application/application-selectors';
import { isHDRSelector, isLowLatencySelector, offerZoneSelector } from '../../store/slices/user-selectors';
import { displayTVModeSelector } from './displayMode-selectors';
import { PlayerState, openFullScreen, setPlayerType, startPlayer } from './player';
import { setDisplayMode } from './ui';

export const launchPlayer =
  ({
    settings,
    infoTracking,
    event,
  }: Pick<PlayerState, 'settings' | 'infoTracking'> & { event?: React.SyntheticEvent }): Redux.ThunkAction<void> =>
  (dispatch) =>
    (window as any).waitForPassToken(() => {
      dispatch(startPlayer({ settings, infoTracking, event }));
    });

const PLAYER_PLATFORM = {
  [StrateMode.LiveTv]: PlayerPlatform.Live,
  [PlayerPlatform.Multi]: PlayerPlatform.Multi,
};

export const launchPlayerFullScreen =
  ({
    data,
    event,
    localSettings,
    trackingContext,
    type,
  }: {
    data: PlayerState['data'] | LinkerData;
    event?: React.SyntheticEvent;
    localSettings?: Partial<PlayerState['settings']>;
    trackingContext?: PlayerState['trackingContext'];
    type?: string;
  }): Redux.ThunkAction<void> =>
  (dispatch, getState) => {
    if (event) {
      event.preventDefault(); // Stop Linker redirect
    }

    const state = getState();
    const context = platformSelector(state);
    const offerZone = offerZoneSelector(state);
    const offerLocation = offerLocationSelector(state);
    const isAndroid = isAndroidSelector(state);
    const locale = fullLocaleSelector(state);
    const isTvDevice = displayTVModeSelector(state);
    const isFeatHdr = getFeatureToggleHdr(state);
    const isFeatLowLatency = getFeatureToggleLowLatency(state);

    const lowLatency = isLowLatencySelector(state);
    const hdr = isHDRSelector(state);

    const {
      NBOlivePlayerButtonLabel = ' ',
      NBOlivePlayerButtonUrl = '',
      NBOlivePlayerText = '',
    } = getNBOLiveInformations(state);

    if (isTvDevice && type === StrateMode.LiveTv) {
      const tokenCMS = tokenCMSSelector(state);
      window.R7('zap', { channelId: getContentID(data as IContent), tokenCMS });
      return;
    }

    const platform = (PLAYER_PLATFORM[type || ''] || PlayerPlatform.Hapi) as TPlatform;
    const trackingContextData =
      (data as ILiveGridPlayerData)?.onClick?.trackingContext ||
      (data as LinkerData)?.mainOnClick?.trackingContext ||
      trackingContext;
    const {
      contextType,
      context_type: contextTypeAlt,
      contextDetail,
      context_list_id: contextListId,
    } = trackingContextData || {};
    const trackingContextType = contextType || contextTypeAlt;
    const trackingContextDetail = contextDetail || contextListId;
    const publicConfig = getPublicConfig();
    dispatch(openFullScreen({ trackingContext: trackingContextData }));
    dispatch(setPlayerType({ playerType: HTML5_PLAYER }));
    dispatch(
      launchPlayer({
        settings: {
          platform,
          uiLanguage: locale,
          context: publicConfig.PLAYER.ONE_PLAYER.CONTEXT,
          env: publicConfig.PLAYER.ONE_PLAYER.ONE_PLAYER_ENV,
          base: getOnePlayerBaseUrl(context),
          content: (type === PlayerPlatform.Multi
            ? (data as FormatMultiLivePlayerData)
            : getContentID(data as IContent)) as NonNullable<PlayerState['settings']>['content'],
          params: {
            autoplay: true,
            offerZone,
            offerLocation: isTOfferLocation(offerLocation) ? offerLocation : undefined,
            startLiveProgramFromBeginning: (data as Record<string, boolean>).startLiveProgramFromBeginning,
            exitButton: {
              showFullScreen: true,
              showWindowed: true,
            },
            unauthorizedChannelErrorContent: {
              buttonLabel: NBOlivePlayerButtonLabel,
              buttonUrl: NBOlivePlayerButtonUrl,
              textLabel: NBOlivePlayerText,
            },
            ...(localSettings?.params && localSettings.params),
          },
          refererTrackingId: trackingContextType ? `${trackingContextType} ${trackingContextDetail}` : '',
          ...((isFeatLowLatency || isFeatHdr) && {
            featureFlags: { hdr: isFeatHdr && hdr, lowLatency: isFeatLowLatency && lowLatency },
          }),
          ...localSettings,
        },
        event,
      })
    );
    dispatch(setDisplayMode(isAndroid ? DisplayMode.FULLSCREEN : DisplayMode.FULLWINDOWED));
  };
