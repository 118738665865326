import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppHistory, useAppLocation } from '../../helpers/hooks/reactRouter';
import { useAppDispatch } from '../../helpers/hooks/useAppDispatch';
import { getFeatureTogglePerso } from '../../selectors/application/application-selectors';
import { displayTVModeSelector } from '../../store/slices/displayMode-selectors';
import { closeImmersive } from '../../store/slices/immersive';
import { clickedElementIdSelector, isImmersivePersoUpdated } from '../../store/slices/immersive-selectors';
import { refetchPerso } from '../../store/slices/page';
import { hasUserDataCollectedSelector } from '../../store/slices/user-selectors';

type CloseImmersive = ({ isBackNavigation }?: { isBackNavigation?: boolean }) => void;

export const useCloseImmersive = (): CloseImmersive => {
  const dispatch = useAppDispatch();
  const history = useAppHistory();
  const location = useAppLocation();

  const clickedElementId = useSelector(clickedElementIdSelector);
  const hasUserDataCollected = useSelector(hasUserDataCollectedSelector);
  const immersivePersoUpdated = useSelector(isImmersivePersoUpdated);
  const isTvDevice = useSelector(displayTVModeSelector);
  const isPersoActive = useSelector(getFeatureTogglePerso);

  const isLastImmersiveInStack = location.state?.immersive?.stackSize || 0 <= 1;

  const handleClose = useCallback<CloseImmersive>(
    ({ isBackNavigation } = { isBackNavigation: false }) => {
      if (!isBackNavigation) {
        if (isTvDevice) {
          // In OneDiscovery, we always go back and unstack immersives one by one
          history.goBack();
        } else {
          // In desktop, closing without back navigation means unstacking all immersives at once
          history.go(-(location.state?.immersive?.stackSize || 1));
        }
      }

      if (isLastImmersiveInStack) {
        dispatch(closeImmersive());

        if (isPersoActive && immersivePersoUpdated && hasUserDataCollected) {
          dispatch(refetchPerso(true));
        }
      }

      if (clickedElementId && !isTvDevice) {
        // Get back focused element for accessibility
        document.getElementById(clickedElementId)?.focus();
      }
    },
    [location, immersivePersoUpdated] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return handleClose;
};
