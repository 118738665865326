import classNames from 'classnames';
import { cloneElement } from 'react';
import { useDiveContext } from '../../context';
import { MediaAspectRatio, MediaProps } from './Media.types';

/**
 * `Media` is a component used to render assets (Images, Videos), status, and editorial content for a given resource.
 * For the `Media` asset to render:
 * - Choose an `aspectRatio`: `3/4`,  `4/3`, `16/9`, `16/6`, `23/6`
 * - Set fixed dimensions using props `width` and `heightAsset`
 * - Insert within sized parent element to occupy 100% of its width and height
 *
 * @example
 * <Media
 *   aspectRatio="169"
 *   image={<MediaImage alt="Avatar: The Way of Water" src="https://avatar.jpeg"/>}
 *   overlay={<MediaOverlay title="Avatar: The Way of Water"/>}
 * />
 */
export default function Media({
  id,
  aspectRatio,
  width,
  heightAsset,
  image,
  video,
  overlay,
  footer,
  className,
  'data-testid': dataTestId,
  showTvFocusStyles = true,
  variant = 'solid',
  ...a11y
}: MediaProps): JSX.Element {
  const { isTv } = useDiveContext();
  return (
    <div
      style={{ width }}
      className={classNames(
        'flex flex-col box-border',
        {
          // Default to parent element's size when no aspect ratio is set
          'h-full w-full': !aspectRatio,
        },
        className
      )}
      id={id}
      data-testid={dataTestId}
      {...a11y}
    >
      <div
        style={{ height: heightAsset }}
        className={classNames('relative overflow-hidden', isTv ? 'rounded-4' : 'rounded-2', {
          'bg-transparent': variant === 'ghost',
          'bg-content-tertiary': variant === 'solid',
          'aspect-w-3  aspect-h-4': aspectRatio === MediaAspectRatio.Portrait3x4,
          'aspect-w-3  aspect-h-5': aspectRatio === MediaAspectRatio.Portrait3x5,
          'aspect-w-4  aspect-h-3': aspectRatio === MediaAspectRatio.Landscape4x3,
          'aspect-w-16 aspect-h-9': aspectRatio === MediaAspectRatio.Landscape16x9,
          'aspect-w-16 aspect-h-8': aspectRatio === MediaAspectRatio.Landscape16x8,
          'aspect-w-16 aspect-h-6': aspectRatio === MediaAspectRatio.Landscape16x6,
          'aspect-w-23 aspect-h-6': aspectRatio === MediaAspectRatio.Landscape23x6,
          // Default to parent element's size when no aspect ratio is set
          'h-full w-full': !aspectRatio,
          // Styles to render when Media's actionnable parent element is focused, on a TV device:
          'tv-focus-parent:outline tv-focus-parent:outline-4 tv-focus-parent:outline-primary':
            isTv && showTvFocusStyles,
        })}
      >
        {overlay}
        {video}
        {image && cloneElement(image, { className: `object-cover` })}
      </div>
      {footer}
    </div>
  );
}
