import classNames from 'classnames';
import IconCheck from '../../assets/svg/check.svg';
import styles from './ChoiceButton.css';

export type ChoiceButtonProps = {
  classname?: string;
  handler?: (_e) => void;
  id: string;
  isChecked?: boolean;
  label?: string;
  statusLabel?: string;
};

function ChoiceButton({
  classname = undefined,
  handler = (_e) => {},
  id,
  isChecked = false,
  label = '',
  statusLabel = '',
}: ChoiceButtonProps): JSX.Element {
  return (
    <button className={classNames(styles.ChoiceButton, classname)} id={id} onClick={handler} type="button">
      {label && <span>{label}</span>}
      {statusLabel && <span className={styles.ChoiceButton__label}>{statusLabel}</span>}
      {isChecked && <IconCheck className={classNames(styles.ChoiceButton__icon, 'ChoiceButtonIcon')} />}
    </button>
  );
}

export default ChoiceButton;
