import { ForwardedRef, cloneElement, forwardRef } from 'react';
import { useDiveContext } from '../../../context';
import { DiveFontFamily, DiveIconPosition } from '../../../exports/types';
import { buttonCVA, buttonIconCVA, buttonLabelCVA } from './Button.cva';
import { ButtonProps, ButtonVariant, ButtonWidth } from './Button.types';

const LABEL_LONG_LENGTH_THRESHOLD = 15;

/**
 * A Button is a clickable Element that triggers an action.
 *
 * It contains a label passed as `children` and an optional `icon` Element:
 *
 * @example
 * ```tsx
 * <Button variant="primary" icon={<PlayIcon />} onClick={onClick} >
 *   Click to play
 * </Button>
 * ```
 *
 * Use the `renderWrapper` prop when you need the visual styles of the Button but not its default HTML Element `<button>`:
 *
 * @example
 * // Rendering an HTML Anchor that has the visual styles of a Button:
 * <Button
    renderWrapper={({ id, disabled, className, onClick, children }) => {
      return (
        <a
          id={id}
          className={className}
          onClick={onClick}
          aria-disabled={disabled}
          {...(disabled ? {} : { href: "https://canalplus.com/" })}
        >
          {children}
        </a>
      );
    }}
   >
     Label
   </Button>
 */
export const Button = forwardRef<HTMLElement, ButtonProps>(function Button(
  {
    children = '',
    icon,
    disabled = false,
    type = 'button',
    id,
    onClick,
    variant = ButtonVariant.Primary,
    iconPosition = DiveIconPosition.Left,
    width = ButtonWidth.Fit,
    font = DiveFontFamily.Canal,
    className,
    'data-testid': dataTestId,
    renderWrapper,
    role,
    ...a11y
  },
  ref
): JSX.Element {
  const { device } = useDiveContext();
  const hasStringChildren = typeof children === 'string';
  const hasIconAndLabel = !!icon && !!children;

  const wrapperStyles = buttonCVA({
    variant,
    disabled,
    width,
    device,
    hasIconAndLabel,
    iconPosition,
    className,
  });
  const iconStyles = buttonIconCVA({
    variant,
    device,
    disabled,
    className: icon?.props?.className, // Conserve the original className of the icon
  });

  const labelStyles = buttonLabelCVA({
    variant,
    disabled,
    font,
    device,
    isLongLabel: hasStringChildren && children.length >= LABEL_LONG_LENGTH_THRESHOLD,
    ...(!hasStringChildren && { className: children?.props?.className }),
  });

  const buttonContent = (
    <>
      {icon &&
        cloneElement(icon, {
          className: iconStyles,
          'aria-hidden': hasIconAndLabel,
        })}
      {hasStringChildren ? (
        <span className={labelStyles}>{children}</span>
      ) : (
        cloneElement(children, { className: labelStyles })
      )}
    </>
  );

  if (renderWrapper) {
    return renderWrapper({
      id,
      onClick,
      children: buttonContent,
      disabled,
      className: wrapperStyles,
      ...a11y,
    });
  }

  return (
    <button
      className={wrapperStyles}
      id={id}
      data-testid={dataTestId}
      // eslint-disable-next-line react/button-has-type
      type={type}
      disabled={disabled}
      aria-disabled={disabled}
      onClick={onClick}
      ref={ref as ForwardedRef<HTMLButtonElement>}
      role={role}
      {...a11y}
    >
      {buttonContent}
    </button>
  );
});
