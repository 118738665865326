import { OfferLocation } from '@canalplus/sdk-core';
import { useSelector, useStore } from 'react-redux';
import { getPublicConfig } from '../../helpers/config/config-helper';
import { getTwitterMetasFromState } from '../../helpers/metaTags/metaTags-helper';
import { deleteQueryString, getProductionHostname, getUrlWithoutDefaultLang } from '../../helpers/url/url-helper';
import {
  applicationDescriptionSelector,
  fullLocaleSelector,
  hostnameSelector,
  offerLocationSelector,
} from '../../selectors/application/application-selectors';
import { getAlternateLinksSelector } from '../../store/slices/alternateLinks-selectors';
import {
  getAlternateLinksSelector as getAlternateLinksForImmersiveSelector,
  getMetaSelector as immersiveGetMetaSelector,
  immersiveOrPageNameSelector,
  immersiveOrPagePathnameSelector,
  immersiveOrPageTemplateSelector,
  isImmersiveSelector,
} from '../../store/slices/immersive-selectors';
import { getMetaSelector as metaGetMetaSelector } from '../../store/slices/meta-selectors';
import { pageAdultSelector } from '../../store/slices/page-selectors';
import { pathnameSelector } from '../../store/slices/routing-selectors';
import type { IState } from '../../store/types/State-type';
import { MetaTags } from './MetaTags';

export function MetaTagsConnected(): JSX.Element {
  const publicConfig = getPublicConfig();
  const state = useStore<IState>().getState();

  const isImmersive = useSelector(isImmersiveSelector);
  const meta = useSelector(isImmersive ? immersiveGetMetaSelector : metaGetMetaSelector);
  const locale = useSelector(fullLocaleSelector);
  const contentMetaRobots = meta.robots;
  const immersiveOrPageName = useSelector(immersiveOrPageNameSelector);
  const displayName = meta.title || immersiveOrPageName;
  const displayTemplate = useSelector(immersiveOrPageTemplateSelector);
  const urlPage = useSelector(immersiveOrPagePathnameSelector);
  const offerLocation = useSelector(offerLocationSelector);

  const documentHeader =
    offerLocation === OfferLocation.pl || offerLocation === OfferLocation.en || offerLocation === OfferLocation.it
      ? publicConfig.documentHeader[offerLocation]
      : publicConfig.documentHeader.default;

  const defaultHostname = documentHeader.app.head.defaultHostname;
  const pathname = useSelector(pathnameSelector) || '';
  const currentUrl = useSelector(hostnameSelector);
  const alternateLinks = useSelector(getAlternateLinksSelector);
  const alternateLinksForImmersive = useSelector(getAlternateLinksForImmersiveSelector);
  const isAdult = useSelector(pageAdultSelector);

  /**
   * Force mycanal in canonical url for all overlays if template is DETAIL_PAGE or DETAIL_SEASON
   */
  const hostname =
    displayTemplate &&
    [publicConfig.TEMPLATE.DETAIL_PAGE, publicConfig.TEMPLATE.DETAIL_SEASON].indexOf(displayTemplate) > -1
      ? defaultHostname
      : currentUrl;

  const applicationDescription = useSelector(applicationDescriptionSelector);
  const description = meta.description || applicationDescription;

  let image;

  /**
   * Get the right summary depending on the type of template
   */
  switch (displayTemplate) {
    case publicConfig.TEMPLATE.DETAIL_PAGE:
    case publicConfig.TEMPLATE.QUICKTIME:
    case publicConfig.TEMPLATE.DETAIL_SEASON:
    case publicConfig.TEMPLATE.DETAIL_SHOW:
    case publicConfig.TEMPLATE.CREATIVE_MEDIA:
      image = meta.urlImage;
      break;

    default:
      image = documentHeader.app.head.meta.find((headerMeta) => headerMeta.property === 'og:image')?.content;
  }

  const canonicalUrl = `https://${getProductionHostname(hostname)}${
    meta.canonical || getUrlWithoutDefaultLang(deleteQueryString(urlPage))
  }`;

  const metasTwitter = getTwitterMetasFromState({
    state,
    title: displayName,
    description,
    urlImage: image,
  });

  return (
    <MetaTags
      config={documentHeader}
      contentMetaRobots={contentMetaRobots}
      alternateLinks={isImmersive ? alternateLinksForImmersive : alternateLinks}
      currentUrl={currentUrl}
      description={description}
      hostname={hostname}
      image={image}
      isAdult={isAdult}
      locale={locale}
      offerLocation={offerLocation}
      pathname={pathname}
      title={displayName}
      canonicalUrl={canonicalUrl}
      metasTwitter={metasTwitter}
    />
  );
}
