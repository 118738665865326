import { AriaAttributes, ButtonHTMLAttributes, ReactElement } from 'react';
import { DiveProps } from '../../types/Dive.types';
import { MediaImageSource } from '../Media/MediaImage/MediaImage.types';

export type AvatarImg = {
  url: string;
  alt: string;
  sources?: MediaImageSource[];
};

export enum AvatarVariant {
  Default = 'default',
  Add = 'add',
  Account = 'account',
}

export type AvatarProps = {
  /**
   * The id of the Avatar.
   */
  id?: string;
  /**
   * A label to be rendered below the Avatar.
   */
  label?: string;
  /**
   * Whether the Avatar should be render with a kids icon.
   */
  isKids?: boolean;
  /**
   * Whether the Avatar should be render with an edit icon.
   */
  isEdit?: boolean;
  /**
   * Whether the Avatar should be render as an html `<button>`.
   *
   * @default true
   */
  isButton?: boolean;
  /**
   * Takes a `MediaImage` component.
   *
   * Displays an image.
   */
  image?: ReactElement;
  /**
   * The style variant of the Avatar.
   *
   * @default 'default'
   */
  variant?: `${AvatarVariant}`;
  /**
   * The onClick event handler for the Avatar when isButton is true.
   */
  onClick?: ButtonHTMLAttributes<HTMLButtonElement>['onClick'];
  /**
   * Accessibility aria-label attribute.
   * @required
   */
  'aria-label': Required<AriaAttributes['aria-label']>;
} & DiveProps &
  Omit<AriaAttributes, 'aria-label'>;
