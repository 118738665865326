import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../../../../helpers/hooks/useAppDispatch';
import I18n from '../../../../../../../lang';
import { userChangeSettings } from '../../../../../../../store/slices/user';
import { isHDRSelector, settingsSelector } from '../../../../../../../store/slices/user-selectors';
import SettingDetail from '../SettingDetail/SettingDetail';

function HdrSetting(): JSX.Element {
  const { t } = I18n.useTranslation();
  const dispatch = useAppDispatch();
  const settings = useSelector(settingsSelector);
  const isHDR = useSelector(isHDRSelector);

  const handler = (newValue?: boolean) => {
    dispatch(userChangeSettings({ ...settings, isHDR: newValue }));
  };

  return (
    <SettingDetail
      title={t('SettingsTemplate.HDR.title')}
      description={t('SettingsTemplate.HDR.description')}
      currentValue={isHDR}
      options={[
        { label: 'SettingsTemplate.enabled', value: true },
        { label: 'SettingsTemplate.disabled', value: false },
        { label: 'SettingsTemplate.HDR.auto', value: undefined },
      ]}
      handler={handler}
    />
  );
}

export default HdrSetting;
