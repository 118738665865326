import { IButtonStrate, ISectionsStrate } from '@canalplus/mycanal-sdk';
import { ContentType } from '@canalplus/sdk-hodor';
import type { FromProp } from '../../../server/modules/fetchWithQuery/types';
import UserMenuButtons from './UserMenuButtons';
import styles from './UserMenuMyCanalExtended.css';
import UserMenuSections from './UserMenuSections/UserMenuSections';

export type TUserMenuStrate = ISectionsStrate | IButtonStrate;

export type UserMenuMyCanalExtendedProps = {
  strates?: TUserMenuStrate[];
  isTvDevice?: boolean;
} & FromProp;

function UserMenuMyCanalExtended({
  from,
  strates = [],
  isTvDevice = false,
}: UserMenuMyCanalExtendedProps): JSX.Element {
  return (
    <div className={styles.UserMenuMyCanalExtended}>
      {strates.map((strate) => {
        switch (strate.type) {
          case ContentType.Sections:
            return (
              <UserMenuSections
                from={from}
                key={`userMenuSections-strate-${strate.type}`}
                contents={strate.contents}
                sectionTitle={strate.title}
                isTvDevice={isTvDevice}
              />
            );
          case ContentType.Button:
            return (
              <UserMenuButtons
                key={`userMenuButtons-strate-${strate.type}`}
                contents={strate.contents}
                sectionTitle={strate.title}
                sectionSubtitle={strate.subtitle}
              />
            );
          default:
            return null;
        }
      })}
    </div>
  );
}

export default UserMenuMyCanalExtended;
