import { isClientSide } from '@canalplus/mycanal-commons';
import throttle from 'lodash/throttle';
import { useEffect, useRef, useState } from 'react';

/**
 * Helper that checks if the element's content is overflowing.
 * @param element - The element to check.
 * @returns True if the element's content overflows.
 */
const isElementContentOverflowing = (element: HTMLElement): boolean => {
  return element.offsetWidth < element.scrollWidth;
};

/**
 * Default function to compute the available space in the container.
 * @param containerEl - The container element.
 * @param labelWidth - The width of the label pre-registered when hook was mounted.
 * @returns The available space in the container.
 */
const computeAvailableSpaceDefault: UseIsOverflowingProps['computeAvailableSpace'] = (
  containerEl: HTMLElement
): number => {
  return containerEl.offsetWidth;
};

type UseIsOverflowingProps = {
  /** Element to watch */
  ref: React.RefObject<HTMLElement>;
  /** Container of the element to watch */
  containerRef: React.RefObject<HTMLElement>;
  /**
   * Function to compute the available space in the container.
   * @returns The available space in the container
   * @default (containerEl: HTMLElement) => containerEl.offsetWidth
   */
  computeAvailableSpace?: (containerEl: HTMLElement) => number;
  /** Boolean to enable/disable the hook */
  enabled?: boolean;
};

/**
 * Hook that detects if an element overflows outside of its container, by checking
 * the refs scrollWidth and container's available space.
 *
 * @param ref - The element to watch.
 * @param containerRef - The container of the element to watch.
 * @param enabled - Boolean to enable/disable the hook.
 * @returns True if the element overflows outside its container
 *
 * @example
 *  const subscribeLabelRef = useRef<HTMLDivElement>(null);
 *  const isOverflowingOnMobile = useIsOverflowing({
 *    ref: subscribeLabelRef,
 *    containerRef,
 *    enabled: isPhoneResolution
 *  });
 */
export const useIsOverflowing = ({
  ref,
  containerRef,
  computeAvailableSpace = computeAvailableSpaceDefault,
  enabled = true,
}: UseIsOverflowingProps): boolean => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const elementFullWidth = useRef<number>(0);
  const isPreviouslyOverflowing = useRef<boolean>(false);

  // Check if the ref is
  useEffect(() => {
    if (!enabled || !isClientSide() || !ref?.current || !containerRef?.current) {
      setIsOverflowing(false);
      return;
    }

    const element = ref.current;
    const container = containerRef?.current;

    // We store the full width of the label at the first render
    if (!elementFullWidth.current && element?.scrollWidth) {
      elementFullWidth.current = element?.scrollWidth;
    }

    const computeOverflow = throttle(() => {
      let isCurrentlyOverflowing;
      // Check if the element is truncated with ellipsis
      if (element) {
        // If it is already truncated, we don't need to check again, instead,
        // we rely on the hasEnoughSpace check
        if (isPreviouslyOverflowing.current) {
          const labelWidth = elementFullWidth.current || element?.scrollWidth || 0;
          // Calculate remaining space in the container
          const containerAvailableWidth = computeAvailableSpace(container);
          const hasEnoughSpace = labelWidth <= containerAvailableWidth;
          isCurrentlyOverflowing = !hasEnoughSpace;
        } else {
          // Since not currently truncated, we check if it is now
          isCurrentlyOverflowing = isElementContentOverflowing(element);
        }
      }
      isPreviouslyOverflowing.current = isCurrentlyOverflowing;
      setIsOverflowing(isCurrentlyOverflowing);
    }, 250);

    computeOverflow();
    window.addEventListener('resize', computeOverflow);
    return () => {
      window.removeEventListener('resize', computeOverflow);
    };
  }, [ref, containerRef, enabled, elementFullWidth, isPreviouslyOverflowing, setIsOverflowing, computeAvailableSpace]);

  return isOverflowing;
};
