import { ApiV2AvatarV1Content } from '@dce-front/hodor-types/api/v2/avatars/v1/definitions';
import { ApiV2AvatarsV2Content } from '@dce-front/hodor-types/api/v2/avatars/v2/definitions';
import { useSelector } from 'react-redux';
import { featAvatarV2Selector } from '../../../selectors/application/application-selectors';
import { profilesSelector } from '../../../store/slices/user-selectors';

/**
 * This hook returns the default avatar url to be used in a profile creation.
 * It takes as a parameter a list of avatar contents either v1 or v2.
 * When avatar feature toggle is enabled it filters the list in parameter to only consider the avatar which has not been already
 * used inside an existing profile, then takes a random avatar url in the filter list.
 * Otherwise when feature toggle is not enabled, it simply takes the first element from the list in parameter.
 * @example
 * // If feature avatar v2 is not enabled, returns 'URLImage1'
 * // If feature avatar v2 is enabled and no profiles already exist, returns randomly either 'URLImage1' or 'URLImage2'
 * useDefaultAvatarURL([{ URLImage: 'URLImage1'}, { URLImage: 'URLImage2 }]);
 */
export function useDefaultAvatarURL(avatarList: ApiV2AvatarV1Content[] | ApiV2AvatarsV2Content[]): string | undefined {
  const LAST_AVATAR_ID_FROM_ORIGINALS_STRATE = 400;

  const profiles = useSelector(profilesSelector);
  const isAvatarV2 = useSelector(featAvatarV2Selector);

  const profileAvatars = profiles.map((profile) => profile.URLImage);
  const avatarsNotSelected = avatarList.filter((avatar) => !profileAvatars.includes(avatar.URLImage));

  const avatarOriginalsNotSelected = isAvatarV2
    ? avatarsNotSelected.filter((avatar) => {
        const avatarId = (avatar as ApiV2AvatarsV2Content).avatarID;
        return avatarId && avatarId <= LAST_AVATAR_ID_FROM_ORIGINALS_STRATE;
      })
    : avatarsNotSelected;

  // In v1 select first avatar
  // In v2 select random avatar between the avatars not yet selected
  const defaultAvatarUrl = isAvatarV2
    ? avatarOriginalsNotSelected[Math.floor(Math.random() * avatarOriginalsNotSelected.length)]?.URLImage
    : avatarList[0]?.URLImage;

  return defaultAvatarUrl;
}
