import { mapStaticKey } from '@canalplus/mycanal-util-react';
import { ApiV2Context } from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import { ApiV2LiveGridPage } from '@dce-front/hodor-types/api/v2/live_grid/definitions';
import type {
  ApiV2BroadcastChannel,
  ApiV2BroadcastContent,
} from '@dce-front/hodor-types/api/v2/page/dtos/display_templates/live_grid/definitions';
import { PageTemplates, TemplateModes } from '@dce-front/hodor-types/constants';
import { getContentsOnlyWithTrackingContext, hasValidLiveTVContents } from '../../../helpers/contents/contents-helper';
import { getCurrentTimestamp } from '../../../helpers/date/date-helper';

export type ContentRowLiveData = {
  channels: ApiV2BroadcastChannel[];
  defaultChannelIndex?: number;
  hasMore: boolean;
};

export type ApiV2BroadcastContentWithTrackingContext = ApiV2BroadcastContent & {
  onClick: {
    displayTemplate: typeof PageTemplates.PlayerPage;
    templateMode?: typeof TemplateModes.LiveTV;
    URLPage?: string;
    URLInfo?: string;
    trackingContext: ApiV2Context;
  };
};

/**
 * ## getFormattedContentRowLiveData
 * Receive response from useQuery and format it to ContentRowLiveData
 *
 * @param strate the response from useQuery hook
 * @returns ContentRowLiveData
 */
const getFormattedContentRowLiveData = (
  strate: ApiV2LiveGridPage,
  trackingContext?: ApiV2Context
): ContentRowLiveData => {
  const channelsWithStaticKey = mapStaticKey(strate.channels, ['type', 'epgID']);
  const currentDate = getCurrentTimestamp();
  const channels = channelsWithStaticKey.reduce((acc: ApiV2BroadcastChannel[], channel) => {
    if (!channel.contents) return [];

    const isOnAir = hasValidLiveTVContents(channel.contents, currentDate);
    if (isOnAir) {
      // We are using getContentsOnlyWithTrackingContext here because the channels launch the player directly in fullscreen
      // so the isImmersive context should not be added in onClicks.
      acc.push({
        ...channel,
        contents: trackingContext
          ? getContentsOnlyWithTrackingContext<ApiV2BroadcastContentWithTrackingContext>(
              channel.contents,
              trackingContext
            )
          : channel.contents,
      });
    }
    return acc;
  }, []);

  const defaultChannelIndex = channels.findIndex((channel) => channel.default);

  return {
    channels,
    defaultChannelIndex: defaultChannelIndex >= 0 ? defaultChannelIndex : undefined,
    hasMore: !!strate.paging?.hasNextPage,
  };
};

export default getFormattedContentRowLiveData;
