import { IContentSections } from '@canalplus/mycanal-sdk';
import { ModalV2, UserMenuOption, UserMenuOptionGroup } from '@canalplus/mycanal-sharedcomponent';
import { KEY_BACK, Layer, useActiveLayer, useKeyCatcher, useStore } from '@canalplus/one-navigation';
import { Template } from '@canalplus/sdk-hodor';
import classNames from 'classnames';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import Linker from '../../../../components/Linker/Linker';
import {
  LAYER_MORE_SETTINGS_ANIMATIONS,
  LAYER_MORE_SETTINGS_RECO,
  LAYER_MORE_SETTINGS_VIDEO,
  LAYER_USER_MENU_SECTIONS_MODAL,
  LAYER_WHOISWATCHING,
} from '../../../../helpers/oneNavigation/layers';
import I18n from '../../../../lang';
import { getWhoIsWatchingUserSettingsSelector } from '../../../../selectors/application/application-selectors';
import type { FromProp } from '../../../../server/modules/fetchWithQuery/types';
import { hasUserDataCollectedSelector } from '../../../../store/slices/user-selectors';
import LoadablePrivacyManager from '../../../../templates/PrivacyManager';
import LoadableStub from '../../../../templates/Stub';
import LoadableTextBrut from '../../../../templates/TextBrut';
import { UserMenuOptionAuthWithIDPToken } from '../UserMenuOptionAuthWithIDPToken';
import IconRightArrow from './../../../../assets/svg/rightArrow.svg';
import styles from './UserMenuSections.css';
import AnimationsAutoplay from './components/SettingsTV/AnimationsAutoplay/AnimationsAutoplay';
import HdrSetting from './components/SettingsTV/HdrSetting/HdrSetting';
import LowLatencySetting from './components/SettingsTV/LowLatencySetting/LowLatencySetting';
import MoreSettings from './components/SettingsTV/MoreSettings/MoreSettings';
import RecoPerso from './components/SettingsTV/RecoPerso/RecoPerso';
import WhoIsWatchingSetting from './components/SettingsTV/WhoIsWatchingSetting/WhoIsWatchingSetting';

export type UserMenuSectionsProps = {
  contents?: IContentSections[];
  isTvDevice?: boolean;
  sectionTitle?: string;
} & FromProp;

function UserMenuSections({
  contents = [],
  from,
  isTvDevice = false,
  sectionTitle,
}: UserMenuSectionsProps): JSX.Element {
  const hasUserDataCollected = useSelector(hasUserDataCollectedSelector);
  const whoIsWatchingSettings = useSelector(getWhoIsWatchingUserSettingsSelector);

  const [currentContent, setCurrentContent] = useState<IContentSections | undefined>();
  const [moreSettingsContent, setMoreSettingsContent] = useState<IContentSections | undefined>();

  const renderChevronIcon = isTvDevice ? <IconRightArrow /> : undefined;
  const { t } = I18n.useTranslation();
  const store = useStore();

  const handleContent = (content: IContentSections | undefined, isMoreSettings = false) => {
    if (content?.onClick?.displayTemplate === Template.MoreSettings || isMoreSettings) {
      setMoreSettingsContent(content);
    }
    setCurrentContent(content);
  };

  useActiveLayer(
    LAYER_USER_MENU_SECTIONS_MODAL,
    !!currentContent &&
      currentContent.onClick.displayTemplate !== Template.PrivacyManager &&
      currentContent.onClick.displayTemplate !== Template.RecoPerso &&
      currentContent.onClick.displayTemplate !== Template.AnimationsAutoplay &&
      currentContent.onClick.displayTemplate !== Template.WhoIsWatchingSetting &&
      currentContent.onClick.displayTemplate !== Template.Hdr &&
      currentContent.onClick.displayTemplate !== Template.LowLatency
  );
  useKeyCatcher(KEY_BACK, () => handleContent(undefined, true), LAYER_USER_MENU_SECTIONS_MODAL);
  useKeyCatcher(KEY_BACK, () => setCurrentContent(moreSettingsContent), LAYER_MORE_SETTINGS_ANIMATIONS);
  useKeyCatcher(KEY_BACK, () => setCurrentContent(moreSettingsContent), LAYER_MORE_SETTINGS_RECO);
  useKeyCatcher(KEY_BACK, () => setCurrentContent(moreSettingsContent), LAYER_WHOISWATCHING);
  useKeyCatcher(KEY_BACK, () => setCurrentContent(moreSettingsContent), LAYER_MORE_SETTINGS_VIDEO);

  const handleFocusable = useCallback(() => {
    if (!store.current) {
      store.focusDefault();
    }
  }, [store]);

  const getCurrentContent = useCallback(() => {
    if (!currentContent) {
      return null;
    }

    switch (currentContent.onClick.displayTemplate) {
      case Template.TextBrut:
        return (
          <ModalV2 isTvDevice={isTvDevice} onClose={() => handleContent(undefined)} size="fullScreen">
            <div className={styles.userMenuSections__container}>
              <LoadableTextBrut from={from} url={currentContent.onClick.URLPage || ''} onFocusable={handleFocusable} />
            </div>
          </ModalV2>
        );
      case Template.PrivacyManager:
        return (
          <ModalV2 isTvDevice={isTvDevice} onClose={() => handleContent(undefined)} type="gdpr">
            <LoadablePrivacyManager
              from={from}
              closeModal={() => {
                setCurrentContent(undefined);
              }}
              url={currentContent.onClick.URLPage || ''}
            />
          </ModalV2>
        );
      case Template.Stub:
        return (
          <ModalV2 isTvDevice={isTvDevice} onClose={() => handleContent(undefined)} size="fullScreen">
            <LoadableStub url={currentContent.onClick.URLPage || ''} from={from} />
          </ModalV2>
        );
      case Template.MoreSettings:
        return (
          <ModalV2 isTvDevice={isTvDevice} onClose={() => handleContent(undefined)} size="fullScreen">
            <MoreSettings from={from} title={currentContent.title} />
          </ModalV2>
        );
      case Template.RecoPerso:
        return (
          <ModalV2 isTvDevice={isTvDevice} onClose={() => handleContent(undefined)} size="fullScreen">
            <RecoPerso title={currentContent.title} />
          </ModalV2>
        );
      case Template.AnimationsAutoplay:
        return (
          <ModalV2 isTvDevice={isTvDevice} onClose={() => handleContent(undefined)} size="fullScreen">
            <AnimationsAutoplay title={currentContent.title} />
          </ModalV2>
        );
      case Template.WhoIsWatchingSetting:
        return (
          <ModalV2 isTvDevice={isTvDevice} onClose={() => handleContent(undefined)} size="fullScreen">
            <WhoIsWatchingSetting title={currentContent.title} />
          </ModalV2>
        );
      case Template.Hdr:
        return (
          <ModalV2 isTvDevice={isTvDevice} onClose={() => setCurrentContent(undefined)} size="fullScreen">
            <HdrSetting />
          </ModalV2>
        );
      case Template.LowLatency:
        return (
          <ModalV2 isTvDevice={isTvDevice} onClose={() => setCurrentContent(undefined)} size="fullScreen">
            <LowLatencySetting />
          </ModalV2>
        );
      default:
        return null;
    }
  }, [currentContent, handleFocusable, isTvDevice, from]);

  return (
    <div className={classNames(styles.userMenuSections__sections, 'UserMenuMyCanalExtended__sections')}>
      <UserMenuOptionGroup title={sectionTitle} key={`sections-strate-${sectionTitle}`} Linker={Linker}>
        {contents.map((content) => {
          let isDisabled: boolean | undefined = false;

          if (content.onClick.displayTemplate === Template.RecoPerso) {
            isDisabled = !hasUserDataCollected;
          } else if (content.onClick.displayTemplate === Template.WhoIsWatchingSetting) {
            isDisabled = whoIsWatchingSettings.disabled;
          }
          switch (content.onClick.displayTemplate) {
            case Template.ExternalSiteWithIDPToken:
              return (
                <UserMenuOptionAuthWithIDPToken
                  authenticationURL={content.onClick.URLWebsite || ''}
                  title={content.title}
                  onClick={content.onClick}
                  URLImage={!isTvDevice ? content.URLImage : ''}
                  key={content.title}
                  id={content.title}
                  idOnClick={`${content.title}_onclick`}
                  showChevron={renderChevronIcon}
                  ariaTitle={content.title}
                />
              );
            case Template.Hdr:
            case Template.LowLatency:
            case Template.AnimationsAutoplay:
            case Template.PrivacyManager:
            case Template.TextBrut:
            case Template.MoreSettings:
            case Template.Stub:
              return (
                <UserMenuOption
                  title={content.title}
                  handleClick={() => handleContent(content)}
                  URLImage={!isTvDevice ? content.URLImage : ''}
                  key={content.title}
                  id={content.title}
                  idOnClick={`${content.title}_onclick`}
                  showChevron={renderChevronIcon}
                  ariaTitle={content.title}
                  Linker={Linker}
                />
              );
            case Template.WhoIsWatchingSetting:
            case Template.RecoPerso:
              return (
                <UserMenuOption
                  title={content.title}
                  handleClick={() => handleContent(content)}
                  key={content.title}
                  id={content.title}
                  idOnClick={`${content.title}_onclick`}
                  showChevron={renderChevronIcon}
                  ariaTitle={content.title}
                  subTitle={isDisabled ? t('SettingsTemplate.disabled') : t('SettingsTemplate.enabled')}
                  Linker={Linker}
                />
              );
            default:
              return (
                <UserMenuOption
                  ariaTitle={content.title}
                  id={content.title}
                  idOnClick={`${content.title}_onclick`}
                  key={content.title}
                  Linker={Linker}
                  newWindowAriaLabel={t('ProfileManager.newWindowAriaLabel')}
                  onClick={content.onClick}
                  showChevron={renderChevronIcon}
                  title={content.title}
                  URLImage={!isTvDevice ? content.URLImage : ''}
                />
              );
          }
        })}
      </UserMenuOptionGroup>
      {currentContent && <Layer layer={LAYER_USER_MENU_SECTIONS_MODAL}>{getCurrentContent()}</Layer>}
    </div>
  );
}

export default UserMenuSections;
