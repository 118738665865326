import { useSelector } from 'react-redux';
import {
  getFeatureToggleWhoIsWatching,
  shouldDisplayWhoIsWatchingSelector,
} from '../../../selectors/application/application-selectors';

type UseWhoIsWatching = {
  /**
   * Indicate if the screen WhoIsWatching is displayed or not
   */
  showWhoIsWatching: boolean;
};

/**
 * Hook that displays the WhoIsWatching screen at startup, if:
 * - User is authenticated
 * - The feature WhoIsWatching is enabled (featureToggleWhoIsWatching in settings)
 * - WhoIsWatching is not disabled in preference user
 * - WhoIsWatching is not displayed for a certain period of time (whoIsWatchingSession)
 * @returns object UseWhoIsWatching
 */
export const useWhoIsWatching = (): UseWhoIsWatching => {
  const featureToggleWhoIsWatching = useSelector(getFeatureToggleWhoIsWatching);
  const shouldDisplayWhoIsWatching = useSelector(shouldDisplayWhoIsWatchingSelector);

  return { showWhoIsWatching: featureToggleWhoIsWatching && shouldDisplayWhoIsWatching };
};
