import { fillTemplate } from '@canalplus/mycanal-commons';
import { OfferZone } from '@canalplus/sdk-core';
import { getPublicConfig } from '../../../../helpers/config/config-helper';

type GetNotificationsUrlParameters = {
  cgaNumber: string;
  offerZone: OfferZone;
  path: string;
};

export const getNotificationsUrl = ({ cgaNumber, offerZone, path }: GetNotificationsUrlParameters): string => {
  const {
    hodor: { baseUrl },
    pass: { portailId },
  } = getPublicConfig().api;

  const baseUrlWithoutAppKey = baseUrl.replace(/{appKey}/gi, '');

  const filledPath = fillTemplate(path, [
    ['cgaNumber', encodeURIComponent(cgaNumber)],
    ['offerZone', offerZone],
    ['portailId', portailId.toString()],
  ]);

  return `${baseUrlWithoutAppKey}${filledPath}`;
};
