import { executeOnNextFrame } from '@canalplus/mycanal-commons';
import { useCallback, useEffect, useRef, useState } from 'react';
import { DELAY_TO_DISPLAY_NOTIFICATION_BAR, DELAY_TO_HIDE_NOTIFICATION_BAR } from '../../constants/notifications';
import { ThemeColor } from '../../constants/themeColor';
import { sendTrackingHitOnNotification } from '../../helpers/tracking/tracking-helper';
import type { UserNotification } from '../../store/slices/user-type';
import Notification from '../Notification/Notification';
import SlideDown from '../SlideDown/SlideDown';
import { storeNotification } from './storeNotification';

export type NotificationDisplayerProps = {
  isKids: boolean;
  localeInfo?: string;
  notification?: UserNotification;
  pageURL: string;
};

function NotificationDisplayer({
  isKids,
  localeInfo,
  notification,
  pageURL,
}: NotificationDisplayerProps): JSX.Element | null {
  const notificationRef = useRef<HTMLDivElement | null>(null);
  const [isNotificationBarShown, setIsNotificationBarShown] = useState<boolean>(false);
  const [notificationBarHeight, setNotificationBarHeight] = useState<number>(0);

  const handleCloseNotification = useCallback(() => {
    if (notification) {
      storeNotification({ ...notification, hidden: true });
      setNotificationBarHeight(notificationBarHeight);
      /* Need to wait the end of the closing animation before clean notification */
      executeOnNextFrame(() => {
        setIsNotificationBarShown(false);
      }, DELAY_TO_HIDE_NOTIFICATION_BAR);
    }
  }, [notification, notificationBarHeight]);

  useEffect(() => {
    const { hidden = false, segType = null } = notification || {};

    if (!isNotificationBarShown && !hidden && notificationRef.current) {
      setNotificationBarHeight(notificationRef.current.clientHeight);

      executeOnNextFrame(() => {
        setIsNotificationBarShown(true);
      }, DELAY_TO_DISPLAY_NOTIFICATION_BAR);

      sendTrackingHitOnNotification({
        isKids,
        themeColor: ThemeColor.Dark,
        segType,
        version: $_BUILD_APP_VERSION,
        pageURL,
        offerLocation: localeInfo,
      });
    }
  }, [isNotificationBarShown, notification]); // eslint-disable-line react-hooks/exhaustive-deps

  return notification ? (
    // TODO: SlideDown should be improved to use a fixed height only when transitioning
    <SlideDown isOpened={isNotificationBarShown} height={notificationBarHeight}>
      <Notification ref={notificationRef} {...notification} closeAction={handleCloseNotification} />
    </SlideDown>
  ) : null;
}

export default NotificationDisplayer;
