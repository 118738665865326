export type SliderProps = {
  minValue: number;
  maxValue: number;
  currentValue: number;
  handleChange: () => void;
};

function Slider({ minValue, maxValue, currentValue, handleChange }: SliderProps): JSX.Element {
  return (
    <input
      type="range"
      className="slider"
      step={10}
      min={minValue}
      max={maxValue}
      value={currentValue}
      onChange={handleChange}
    />
  );
}

export default Slider;
