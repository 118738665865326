import { HeaderProfile, HeaderProfileProps } from '@canalplus/mycanal-sharedcomponent';
import Linker from '../../../components/Linker/Linker';
import I18n from '../../../lang';
import ProfileSelectorMyCanal from '../../Profiles/ProfileSelectorMyCanal/ProfileSelectorMyCanal';
import UserMenuMyCanalExtended from '../UserMenuMyCanalExtended/UserMenuMyCanalExtended';

export type HeaderProfileMyCanalProps = Omit<HeaderProfileProps, 'headerProfileIconAriaLabel'> & {
  isProfileSelectorRendered: boolean;
};

function HeaderProfileMyCanal({ isProfileSelectorRendered, ...props }: HeaderProfileMyCanalProps): JSX.Element {
  const { t } = I18n.useTranslation();
  const { userMenuStrates, isTvDevice } = props;

  return (
    <HeaderProfile {...props} Linker={Linker} headerProfileIconAriaLabel={t('ProfileManager.profileIconAriaLabel')}>
      {isProfileSelectorRendered && <ProfileSelectorMyCanal />}
      <UserMenuMyCanalExtended from="root" isTvDevice={isTvDevice} strates={userMenuStrates} />
    </HeaderProfile>
  );
}

export default HeaderProfileMyCanal;
