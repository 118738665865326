import { getCookie, isClientSide, setCookie } from '@canalplus/mycanal-commons';
import Cookies from 'js-cookie';
import { CookieKey } from '../../constants/cookie';
import { getPublicConfig } from '../../helpers/config/config-helper';

export function ABTestingCookiesHandler(abTestingPopulationValue?: number): void {
  if (isClientSide()) {
    const { abTestingPercentage } = getPublicConfig().api.hodor;
    const abTestingPopulationCookies = getCookie(CookieKey.AbTestingPopulation);

    if (!abTestingPopulationCookies && abTestingPercentage > 0 && abTestingPopulationValue) {
      setCookie(CookieKey.AbTestingPopulation, String(abTestingPopulationValue), {
        expires: 7,
        sameSite: 'Lax',
      });
    }

    if (abTestingPopulationCookies && parseInt(abTestingPopulationCookies, 10) && !abTestingPercentage) {
      Cookies.remove(CookieKey.AbTestingPopulation, {
        domain: window.location.hostname,
      });
    }
  }
}
