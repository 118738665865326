import {
  DiveViewportsWithDevice,
  MediaImage,
  THUMBOR_QUALITY_TO_DPR,
  getThumborSources,
  getThumborUrl,
  isDeviceToViewportToImageDimensions,
} from '@canalplus/dive';

import { MediaImageProps } from '@canalplus/dive/dist/types/components/Media/MediaImage/MediaImage.types';
import { DeviceToImageUrl, ThumborSourcesDimensions } from '@canalplus/dive/dist/types/thumbor/thumbor.types';
import { VIEWPORTS } from '@canalplus/mycanal-commons';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  imageQualityPercentageSelector,
  maxImageRatioSelector,
} from '../../selectors/application/application-selectors';
import { displayTVModeSelector } from '../../store/slices/displayMode-selectors';
import { imageQualityPercentageBySettingsSelector } from '../../store/slices/user-selectors';

export type ThumborMediaImageProps = {
  /**
   * The default image URL to request to Thumbor
   */
  url?: DeviceToImageUrl['default'];
  /**
   * The mobile-device-specific image URL to request to Thumbor
   */
  urlMobile?: DeviceToImageUrl['mobile'];
  /**
   * The image dimensions to request to Thumbor
   */
  dimensions: ThumborSourcesDimensions;
  /**
   * When not on TV device, whether the image only needs **desktop**-specific `<source>` media-queries.
   *
   * Useful for generating a lighter DOM.
   */
  isDesktopOnly?: boolean;
  /**
   * When not on TV device, whether the image only needs **mobile**-specific `<source>` media-queries.
   *
   * Useful for generating a lighter DOM.
   */
  isMobileOnly?: boolean;
  /**
   * Component test id
   */
  'data-testid'?: string;
  /**
   * Viewports to use
   *
   * If is undefined, it uses a default viewports :
   *  - `VIEWPORTS.ASCENDING` if `isMobileOnly` and `isDesktopOnly` are false
   *  - `VIEWPORTS.DESKTOP_ASCENDING` if `isDesktopOnly` is true
   *  - `VIEWPORTS.MOBILE_ASCENDING` if `isMobileOnly` is true
   */
  viewports?: DiveViewportsWithDevice;
} & Omit<MediaImageProps, 'src' | 'sources'>;

/**
 * Displays an image based on given `url` and `dimensions` using both:
 *
 * - Canal Plus' Thumbor image server, with myCanal-specific image settings
 * - DIVE's `MediaImage` component
 *
 * @see {@link https://canal-wiki.canal-plus.com/display/PE/Thumbor Thumbor server docs}
 * @see {@link https://deddev.gitlab-pages.canalplus.pro/dedmycanalwebapp/dive/?path=/docs/components-media-mediaimage--docs DIVE MediaImage docs}
 */
export default function ThumborMediaImage({
  url,
  urlMobile,
  dimensions,
  'data-testid': dataTestId,
  isDesktopOnly,
  isMobileOnly,
  viewports,
  ...rest
}: ThumborMediaImageProps): JSX.Element | null {
  const isTvDevice = useSelector(displayTVModeSelector);
  const maxDprSettings = useSelector(maxImageRatioSelector) || Infinity;
  const qualitySettings = useSelector(imageQualityPercentageSelector);
  const qualityUserSettings = useSelector(imageQualityPercentageBySettingsSelector);
  const defaultViewports = useMemo(() => {
    if (isDesktopOnly) return VIEWPORTS.DESKTOP_ASCENDING;
    if (isMobileOnly) return VIEWPORTS.MOBILE_ASCENDING;
    return VIEWPORTS.ASCENDING;
  }, [isDesktopOnly, isMobileOnly]);

  if (!url) {
    return null;
  }

  const quality = qualityUserSettings || qualitySettings;
  const devicePixelRatio = Math.min(THUMBOR_QUALITY_TO_DPR[quality], maxDprSettings);
  const dimensionsDefault = isDeviceToViewportToImageDimensions(dimensions)
    ? dimensions.default?.large
    : dimensions?.large;
  /**
   * The urls returned from myCanal's back-end already include a thumbor-compatible format:
   * Thumbor server url (https://thumb.canalplus.pro/) + Thumbor-allowed source url
   */
  const includesAllowedSourceInUrl = true;

  const src = getThumborUrl({
    url,
    dimensions: dimensionsDefault,
    quality,
    devicePixelRatio,
    includesAllowedSourceInUrl,
  });

  const sources = !isTvDevice
    ? getThumborSources({
        urls: { default: url, ...(urlMobile && { mobile: urlMobile }) },
        dimensions,
        quality,
        devicePixelRatio,
        viewports: viewports || defaultViewports,
        includesAllowedSourceInUrl,
      })
    : undefined;

  return (
    <MediaImage
      src={isTvDevice && src.urlRetina ? src.urlRetina : src.url}
      sources={sources}
      data-testid={dataTestId}
      {...rest}
    />
  );
}
