import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../../../../helpers/hooks/useAppDispatch';
import I18n from '../../../../../../../lang';
import { userChangeSettings } from '../../../../../../../store/slices/user';
import { isLowLatencySelector, settingsSelector } from '../../../../../../../store/slices/user-selectors';
import SettingDetail from '../SettingDetail/SettingDetail';

function LowLatencySetting(): JSX.Element {
  const { t } = I18n.useTranslation();
  const dispatch = useAppDispatch();
  const settings = useSelector(settingsSelector);
  const isLowLatency = useSelector(isLowLatencySelector);

  const handler = (newValue?: boolean) => {
    dispatch(userChangeSettings({ ...settings, isLowLatency: newValue }));
  };

  return (
    <SettingDetail
      title={t('SettingsTemplate.realTimeStreaming.title')}
      description={t('SettingsTemplate.realTimeStreaming.description')}
      currentValue={isLowLatency}
      options={[
        { label: 'SettingsTemplate.enabled', value: true },
        { label: 'SettingsTemplate.disabled', value: false },
      ]}
      handler={handler}
    />
  );
}

export default LowLatencySetting;
