import type { PlayerInstance } from '../../../store/slices/player';
import { getFinalPlayerSettings } from './getFinalPlayerSettings';
import { CommonProps } from './types';

export type UpdatePlayerSettingsType = CommonProps & {
  playerInstance: PlayerInstance;
};

export function updatePlayerSettings({
  adToken,
  settings,
  credentials,
  dispatch,
  enableAd,
  isTvDevice,
  isFeatDidomi,
  locale,
  offerLocation,
  offerZone,
  onKillPlayer,
  playerContainerElement,
  playerInstance,
  targetedAds,
  consentString,
}: UpdatePlayerSettingsType): void {
  const { credentials: finalCredentials, settings: finalSettings } = getFinalPlayerSettings({
    baseSettings: settings,
    credentials,
    dispatch,
    enableAd,
    locale,
    offerLocation,
    offerZone,
    onKillPlayer,
  });

  if (!isTvDevice) {
    playerInstance.setAdData({
      gdpr: 1,
      targetedAds,
      ...(consentString && { consentString }),
      // conditionally add adToken to the adData object depending on the value of targetedAds and isFeatDidomi
      ...(((isFeatDidomi && adToken) || (adToken && targetedAds === '1')) && { targeting: adToken }),
    });
    playerInstance.loadVideo(finalSettings, finalCredentials, playerContainerElement);
  }
}
