import { ApiV2NavigationItem } from '@dce-front/hodor-types/api/v2/authenticate/definitions';
import IconLiveTV from '../../assets/svg/liveTV.svg';
import Linker from '../../components/Linker/Linker';
import { getPublicConfig } from '../config/config-helper';
import {
  NavigationItemOnClickTemporary,
  computeApiV2OnClickFromNavigationItemOnClick,
} from '../hodor/computeApiV2OnClickFromNavigationItemOnClick';

const getOnClickByPageId = (navigation: ApiV2NavigationItem[], id: string): ApiV2NavigationItem | undefined => {
  const pageId = id.replace(/\//g, '');
  return navigation.find((nav) => {
    const path = nav.onClick?.path?.replace(/\//g, '');
    return path === pageId;
  });
};

export const getURLPageByPathname = (navigation: ApiV2NavigationItem[], pathname: string): string | undefined => {
  const nav = getOnClickByPageId(navigation, pathname);
  return nav?.onClick?.URLPage;
};

type Link = React.ReactNode;
type LinksColumn = Link[];

export type ColumnContent = {
  links: LinksColumn[];
  title?: string;
};

const getLinkElement = (onClick?: NavigationItemOnClickTemporary): Link => {
  if (!onClick) {
    return null;
  }

  const { URLWebsite, displayTemplate, serviceID, path, displayName, BOName } = onClick;
  const externalSite = displayTemplate === getPublicConfig().TEMPLATE.EXTERNAL_SITE;
  const isBlank = serviceID !== 'internal';

  if (URLWebsite && externalSite) {
    return (
      <a href={URLWebsite} target={isBlank ? '_blank' : ''} rel="noreferrer">
        {displayName || BOName}
      </a>
    );
  }

  return (
    <Linker
      data={{ mainOnClick: computeApiV2OnClickFromNavigationItemOnClick(onClick) }}
      href={path}
      title={displayName}
    >
      <span>{displayName || BOName}</span>
    </Linker>
  );
};

export const getColumns = (items: any[]): any[][] => {
  // create two sub-columns of links if there is more than 4 links
  if (items.length > 4) {
    const half = Math.ceil(items.length / 2);
    return [items.slice(0, half), items.slice(half, items.length)];
  }
  return [items];
};

export const getMainColumnContent = (tree: ApiV2NavigationItem[]): ColumnContent[] => {
  if (tree[0]?.children) {
    return tree.map((leaf) => ({
      title: leaf.displayName,
      links: leaf.children?.length ? getColumns(leaf.children.map((link) => getLinkElement(link.onClick))) : [[]],
    }));
  }

  // Supports V4 data display. TODO: remove when V5 will be definitive
  const columns = getColumns(tree);
  return columns.map((col) => ({
    links: getColumns(col.map((link) => getLinkElement(link.onClick))),
  }));
};

/**
 * Since new HF custom link in footer should be handle in this webapp
 * @param   navigation tree (could be empty in prospect mode)
 * @param   activeIndex to know if it's an **activeItem**
 * @returns new tree
 */
export const transformHeaderNavigation = (navigation: ApiV2NavigationItem[] = []): JSX.Element[] =>
  navigation.map(({ onClick, ...restItem }) => {
    const { picto, displayName } = restItem;
    const { path, displayName: onClickDisplayName } = onClick || {};

    return (
      <Linker
        data={{ mainOnClick: onClick ? computeApiV2OnClickFromNavigationItemOnClick(onClick) : undefined, ...restItem }}
        href={path}
        id={`${displayName || path}_onclick`}
        key={path}
        title={onClickDisplayName}
      >
        {picto === 'LiveTV' ? <IconLiveTV /> : <span>{displayName}</span>}
      </Linker>
    );
  });

/** Retrieve the index for the currently active item of the header navigation */
export const getHeaderActiveIndex = (navigation: ApiV2NavigationItem[], rootUrl: string, path: string): number =>
  (navigation || []).findIndex((navItem) => {
    const navItemPath = navItem.onClick?.path;

    if (!navItemPath) {
      return false;
    }

    if (navItemPath === rootUrl) {
      // navItem points to the root url, so we check for string equality with the pagePathname (see explaination below)
      return navItemPath === path;
    }

    // The current pagePathname may be a sub-route of a navItem path. For example:
    // pagePathname = /cinema/comedy/
    // navItemPath = /cinema/
    // 👆 this should be a match, hence we use String.startsWith
    // (this doesn't work when navItemPath is the root url, because EVERY pagePathname starts with the root url, which is why we test for that case above)
    return path.startsWith(navItemPath);
  });
