import { Template } from '@canalplus/sdk-hodor';
import { ApiV2DetailV5 } from '@dce-front/hodor-types/api/v2/detail/spyro/definitions';
import { getPublicConfig } from '../../../helpers/config/config-helper';

// We only accept a limited number of Template to avoid unexpected behavior
const WHITE_LIST_TABS_DISPLAYTEMPLATES: Template[] = [
  Template.ContentGrid,
  Template.EpisodesList,
  Template.Highlights,
  Template.Landing,
  Template.MoreInfos,
  Template.SportVitrine,
];

export const getDetailV5Formatted = ({
  data,
  isFeatShortVideoPlayerEnabled,
  isFeatTimelineEnabled,
}: {
  data: ApiV2DetailV5;
  isFeatShortVideoPlayerEnabled: boolean;
  isFeatTimelineEnabled: boolean;
}): ApiV2DetailV5 => {
  const publicConfig = getPublicConfig();
  const {
    tabs = [],
    detail: { moreInfos },
  } = data;

  if (!tabs.length && !moreInfos) {
    return data;
  }

  const isMoreInfosAlreadyAdded = tabs.some(
    ({ displayTemplate }) => displayTemplate === publicConfig.TEMPLATE.MORE_INFOS
  );

  if (!moreInfos || isMoreInfosAlreadyAdded) {
    return data;
  }

  // Remove label as it is unused by & non-compliant with ApiV2DetailTab
  const { label, ...rest } = moreInfos;

  let filterTabs = tabs.filter(
    (tab) => tab.displayTemplate && WHITE_LIST_TABS_DISPLAYTEMPLATES.includes(tab.displayTemplate as Template)
  );

  if (!isFeatShortVideoPlayerEnabled) {
    filterTabs = filterTabs.filter((tab) => tab.displayTemplate !== Template.Highlights);
  }

  if (!isFeatTimelineEnabled) {
    filterTabs = filterTabs.filter((tab) => tab.displayTemplate !== Template.SportVitrine);
  }

  return {
    ...data,
    // manually adds MoreInfos tab if absent from `tabs`
    tabs: [...filterTabs, { ...rest, displayName: label }],
  };
};
