import { KEYCODE } from '@canalplus/mycanal-commons';
import React, { useCallback, useEffect } from 'react';

export const getFocusableElements = (
  container: HTMLElement,
  firstElementIndex?: number,
  lastElementIndex?: number
): { firstFocusableElement: HTMLElement; lastFocusableElement: HTMLElement } => {
  const focusableElements = container.querySelectorAll(
    'button:enabled, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
  );

  const firstFocusableElement = (
    firstElementIndex !== undefined && Array.from(focusableElements).at(firstElementIndex)
      ? focusableElements[firstElementIndex]
      : focusableElements[0]
  ) as HTMLElement;
  const lastFocusableElement = (
    lastElementIndex !== undefined && Array.from(focusableElements).at(lastElementIndex)
      ? Array.from(focusableElements).at(lastElementIndex)
      : focusableElements[focusableElements.length - 1]
  ) as HTMLElement;

  return { firstFocusableElement, lastFocusableElement };
};

const focusTrap = (
  event: KeyboardEvent,
  firstFocusableElement: HTMLElement,
  lastFocusableElement: HTMLElement
): void => {
  // if shift key pressed for shift + tab combination
  if (event.shiftKey && (event.which === KEYCODE.TAB || event.key === 'Tab')) {
    // if focused has reached to first focusable element then focus last focusable element after pressing tab and shiftKey
    if (document.activeElement === firstFocusableElement) {
      event.preventDefault();
      lastFocusableElement?.focus();
    }
  }

  // if tab key is pressed
  // if focused has reached to last focusable element then focus first focusable element after pressing tab
  if ((event.which === KEYCODE.TAB || event.key === 'Tab') && !event.shiftKey) {
    if (document.activeElement === lastFocusableElement) {
      event.preventDefault();
      firstFocusableElement?.focus();
    }
  }
};

interface UseFocusTrapOptions {
  isTvDevice?: boolean;
  firstElementIndex?: number;
  lastElementIndex?: number;
}
export const useFocusTrap = (
  containerRef: React.RefObject<HTMLElement>,
  { firstElementIndex, lastElementIndex, isTvDevice = false }: UseFocusTrapOptions = {}
): void => {
  const handleKeydown = useCallback(
    (event: KeyboardEvent) => {
      let firstTabEvent = document.activeElement === containerRef.current;
      if (
        containerRef.current &&
        (event.which === KEYCODE.TAB || event.key === 'Tab' || event.which === KEYCODE.SHIFT || event.shiftKey)
      ) {
        const { firstFocusableElement, lastFocusableElement } = getFocusableElements(
          containerRef.current,
          firstElementIndex,
          lastElementIndex
        );

        // since we focus the container first, if we want to focus the customized first element, we will have to set it manually
        // Otherwise the first focusable "child" will be focused by default
        if (firstTabEvent) {
          event.preventDefault();
          firstFocusableElement.focus();
          firstTabEvent = false;
        } else {
          focusTrap(event, firstFocusableElement, lastFocusableElement);
        }
      }
    },
    [containerRef, firstElementIndex, lastElementIndex]
  );

  useEffect(() => {
    if (!containerRef.current || isTvDevice) {
      return;
    }

    // Focus element on every modal rendering
    containerRef.current.focus();

    document.body.addEventListener('keydown', handleKeydown);

    return () => document.body.removeEventListener('keydown', handleKeydown);
  });
};
