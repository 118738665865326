import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../../../../../helpers/hooks/useAppDispatch';
import { userChangeSettings } from '../../../../../../../../store/slices/user';
import { areAnimationsMutedSelector, settingsSelector } from '../../../../../../../../store/slices/user-selectors';

/**
 * Users can mute or unmute the animations
 * This value is stored in local storage and redux store
 */
export const useAreAnimationsMuted = (): {
  areAnimationsMuted: boolean;
  toggleAreAnimationsMuted: () => void;
} => {
  const dispatch = useAppDispatch();

  const areAnimationsMuted = useSelector(areAnimationsMutedSelector);
  const settings = useSelector(settingsSelector);

  const toggleAreAnimationsMuted = () => {
    dispatch(userChangeSettings({ ...settings, areAnimationsMuted: !areAnimationsMuted }));
  };

  return {
    areAnimationsMuted,
    toggleAreAnimationsMuted,
  };
};
