import { MediaImageDimensions } from '../../components/Media/MediaImage/MediaImage.types';
import { DevicePixelRatio, ThumborUrl } from '../thumbor.types';

export const URL_THUMBOR_SERVER_HTTP_LOADER =
  'https://thumb.canalplus.pro/http/unsafe/{resolutionXY}/filters:quality({imageQualityPercentage})';

export type GetThumborUrlParameter = {
  /**
   * URL to transform into a Thumbor-ready URL.
   *
   * **Important:** `url` should match one of the `ALLOWED_SOURCE` in the server's [HTTP loader configuration](https://gitlab.canalplus.pro/deddev/thumbor/-/blob/master/infra/local/thumbor/conf/thumbor-http.conf).
   */
  url: string;
  /**
   * Whether `url` already includes an `ALLOWED_SOURCE` as per the server's [HTTP loader configuration](https://gitlab.canalplus.pro/deddev/thumbor/-/blob/master/infra/local/thumbor/conf/thumbor-http.conf).
   *
   * - If true, `url` is already Thumbor-ready and directly interpolated based on given `quality`, `dimensions`, and `devicePixelRatio`.
   * - If false, `url` is first added to `URL_THUMBOR_SERVER_HTTP_LOADER` as an `ALLOWED_SOURCE`, and then interpolated.
   *
   * @see {@link https://thumbor.readthedocs.io/en/latest/image_loader.html#http-loader Thumbor docs - HTTP loader}
   */
  includesAllowedSourceInUrl: boolean;
  /**
   * An object containing number-typed keys `width` and `height`.
   *
   * Used by Thumbor to serve the requested image resized to the specified dimensions.
   *
   * @default "original image size"
   *
   * @see {@link https://thumbor.readthedocs.io/en/latest/usage.html#image-size Thumbor docs - image size}
   */
  dimensions?: MediaImageDimensions;
  /**
   * A number between `0` and `100`.
   *
   * Used by Thumbor to set the compression amount of served `webp`/`jpeg` images.
   *
   * @default 90 (server configuration)
   *
   * @see {@link https://thumbor.readthedocs.io/en/latest/quality.html Thumbor docs - quality}
   */
  quality?: number;
  /**
   * Ratio of the resolution in physical pixels to the resolution in CSS pixels for the current display device.
   *
   * Used to request images with Retina / HiDPI `dimensions`.
   */
  devicePixelRatio?: DevicePixelRatio;
};

/**
 * Concatenates the Thumbor server HTTP loader URL with given `urlAllowedSource`.
 *
 * Note that the server URL contains pre-populated `dimensions` and `quality` interpolation placeholders.
 *
 * @example
 * getUrlWithAllowedSource("http://img-hapi.canalplus.pro/ServiceImage/ImageID/68795346")
 * // "https://thumb.canalplus.pro/http/unsafe/{resolutionXY}/filters:quality({imageQualityPercentage})/img-hapi.canalplus.pro:80/ServiceImage/ImageID/68795346"
 */
export function getUrlWithAllowedSource(urlAllowedSource: string): string {
  return `${URL_THUMBOR_SERVER_HTTP_LOADER}/${
    // Protocol must be removed from the URL to be added as an ALLOWED_SOURCE
    urlAllowedSource.split('://')[1]
  }`;
}

/**
 * Returns an URL that is ready to be requested to the Thumbor server HTTP loader.
 *
 * 1. Concatenates Thumbor server URL with the `url` of requested image, unless `includesAllowedSourceInUrl` is true.
 * 2. Interpolates `quality`, `dimensions` and `devicePixelRatio` into returned ThumborUrl.
 *
 * **Important:**
 * - `url` should match one of the `ALLOWED_SOURCE` in the server's HTTP loader configuration.
 * - The format of served images defaults to `WebP` and fallbacks to `JPEG`,\
 * based on whether the request was sent with an `Accept: image/webp` header.
 * - Thumbor can handle a missing width or height and will automatically adjust the image to maintain its aspect ratio.
 *
 * @see {@link https://canal-wiki.canal-plus.com/display/PE/Thumbor Thumbor server docs}
 * @see {@link https://gitlab.canalplus.pro/deddev/thumbor/-/blob/master/infra/local/thumbor/conf/thumbor-http.conf Thumbor server configuration}
 * @see {@link https://thumbor.readthedocs.io/en/latest/image_loader.html#http-loader Thumbor docs - HTTP loader}
 * @see {@link https://thumbor.readthedocs.io/en/latest/usage.html#image-size Thumbor docs - image size}
 */
export const getThumborUrl = ({
  url,
  includesAllowedSourceInUrl,
  dimensions,
  quality,
  devicePixelRatio,
}: GetThumborUrlParameter): ThumborUrl => {
  const output: ThumborUrl = { url: includesAllowedSourceInUrl ? url : getUrlWithAllowedSource(url) };

  if (quality) {
    output.url = output.url.replace('{imageQualityPercentage}', `${quality}`);
  }

  if (dimensions) {
    if (devicePixelRatio && devicePixelRatio > 1) {
      const width = dimensions.width ? `${Math.round(dimensions.width * devicePixelRatio)}` : '0';
      const height = dimensions.height ? `${Math.round(dimensions.height * devicePixelRatio)}` : '0';
      output.urlRetina = output.url.replace('{resolutionXY}', `${width}x${height}`);
    }
    const width = dimensions.width ? dimensions.width : '0';
    const height = dimensions.height ? dimensions.height : '0';
    output.url = output.url.replace('{resolutionXY}/', `${width}x${height}/`);
  }

  return output;
};
