import { Binder, useActiveLayer } from '@canalplus/one-navigation';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../../../../helpers/hooks/useAppDispatch';
import { LAYER_WHOISWATCHING } from '../../../../../../../helpers/oneNavigation/layers';
import { setCookieWhoIsWatchingDisabled } from '../../../../../../../helpers/whoIsWatching/cookieHelper';
import I18n from '../../../../../../../lang';
import { getWhoIsWatchingUserSettingsSelector } from '../../../../../../../selectors/application/application-selectors';
import { setWhoIsWatchingDisabled } from '../../../../../../../store/reducers/actions/Application-actions';
import { displayTVModeSelector } from '../../../../../../../store/slices/displayMode-selectors';
import { ButtonSettings } from '../../../../../../ButtonSettings/ButtonSettings';
import styles from './WhoIsWatchingSetting.css';

export type WhoIsWatchingSettingProps = {
  title: string;
};

function WhoIsWatchingSetting(props: WhoIsWatchingSettingProps): JSX.Element {
  const { title } = props;
  const dispatch = useAppDispatch();
  const whoIsWatchingSettings = useSelector(getWhoIsWatchingUserSettingsSelector);

  const isTvDevice = useSelector(displayTVModeSelector);
  const { t } = I18n.useTranslation();

  useActiveLayer(LAYER_WHOISWATCHING, true);

  const onHandler = () => {
    setCookieWhoIsWatchingDisabled(isTvDevice, !whoIsWatchingSettings?.disabled);
    dispatch(setWhoIsWatchingDisabled(!whoIsWatchingSettings?.disabled));
  };

  return (
    <div className={styles.whoIsWatchingSetting__container}>
      <Binder layer={LAYER_WHOISWATCHING}>
        <h1 className={styles.whoIsWatchingSetting__title}>{title}</h1>
        <ButtonSettings
          handler={onHandler}
          isTvDevice={isTvDevice}
          hasActionIcon={false}
          subText={whoIsWatchingSettings?.disabled ? t('SettingsTemplate.disabled') : t('SettingsTemplate.enabled')}
          text={t('SettingsTemplate.whoIsWatching.title')}
        />
        <p className={classNames(styles.whoIsWatchingSetting__text)}>
          {t('SettingsTemplate.whoIsWatching.description')}
        </p>
      </Binder>
    </div>
  );
}

export default WhoIsWatchingSetting;
