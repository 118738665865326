import { useEffect, useReducer } from 'react';
import LoadableContentRowStandard from '../../templates/ContentRowStandard';
import LoadableLandingV5Connected from '../../templates/LandingV5';

/**
 * Preload some chunks for CSR render mode
 * - LandingV5Connected
 * - ContentRowStandard
 */
export const useChunksPreloading = (): void => {
  const [isChunksPreloaded, setIsChunksPreloaded] = useReducer(() => true, false);

  useEffect(() => {
    if ($_BUILD_RENDERMODE_CSR && !isChunksPreloaded) {
      LoadableLandingV5Connected.preload();
      LoadableContentRowStandard.preload();

      setIsChunksPreloaded();
    }
  }, [isChunksPreloaded]);
};
