import { Key, KEY_BACK, KEY_DOWN, KEY_ENTER } from '../constants/keys';
import { EventEmitter } from '../utils/EventEmitter';
import { nearest } from '../utils/nearest';
import { StoreBinder, StoreBinderInit } from './StoreBinder';
import { IStore } from './types';

/**
 * Implements a layer of binder, a group of binders accessibles to each other but not accessible
 * from other layers
 */
export class StoreLayer extends EventEmitter<void> {
  /**
   * Layer identifier
   */
  id: number;

  /**
   * Binders registered to this layer
   */
  binders: StoreBinder[] = [];

  /**
   * Currently focused binder
   */
  currentBinder: StoreBinder | undefined;

  /**
   * Currently focused element
   */
  current: HTMLElement | undefined;

  /**
   * Reference to parent store
   */
  store: IStore;

  constructor(store: IStore, id: number) {
    super();

    this.store = store;
    this.id = id;
  }

  /**
   * Create a new binder with basic infos
   * @param options who contains: { el, selector, enabled }
   * @returns created binder
   */
  addBinder(options: StoreBinderInit): StoreBinder {
    const binder = new StoreBinder(this.store, this, options);
    this.binders.push(binder);
    return binder;
  }

  /**
   * Delete a binder from the store and update the binders list
   * @param binder to delete
   */
  removeBinder(binder: StoreBinder): void {
    const index = this.binders.indexOf(binder);
    if (index > -1) {
      this.binders.splice(index, 1);

      binder.callDestroyHook();

      if (this.currentBinder === binder) {
        this.focus(undefined);
      }
    }
  }

  /**
   * Used to update enabled status of a binder
   * @param binder To update
   * @param enabled New status of binder enable
   */
  setBinderEnabled(binder: StoreBinder, enabled: boolean): void {
    const index = this.binders.indexOf(binder);
    if (index === -1) {
      console.warn('OneNavigation: binder not found for binder update');
      return;
    }

    this.binders[index].enabled = enabled;
    // Unfocus if binder was enabled and change
    if (this.currentBinder === binder && !binder.enabled) {
      console.warn('OneNavigation: unfocus the binder due to enable change');
      // Force focus to be undefined
      this.focus(undefined);
      // Now we can restart focusing to enabled binders
      // FIXME this should be handled in consumer code
      this.focusDefault();
    }
  }

  /**
   * Function used to return only enabled binders
   * @returns all enabled binders
   */
  getEnabledBinders(): StoreBinder[] {
    return this.binders.filter((binder) => binder.enabled);
  }

  /**
   * Switch focus to given binder and element
   * @param binder Binder to focus
   * @param element Binder element to focus
   */
  focus(binder?: StoreBinder, element?: HTMLElement): void {
    const previous = this.current;

    if (binder && element) {
      // Execute all focused hooks for this binder on this element
      binder.callFocusedHook(element);
    }

    this.currentBinder = binder;
    this.current = element;

    // Avoid running onFocusChange if current layer is not the active one
    if (this.store.onFocusChange && this.store.activeLayer === this.id) {
      this.store.onFocusChange(previous, element);
    }
  }

  /**
   * Focus default binder on this layer
   * @param inputBinder Optional binder to force default focus on
   */
  focusDefault(inputBinder?: StoreBinder): void {
    const binders = this.getEnabledBinders();

    if (!binders.length) {
      console.warn('OneNavigation: cannot focusDefault() when there is no binder');
      return;
    }

    const forceFocusBinders = binders.filter((b) => b.forceFocusOnMount);
    // FIXME To be removed once we enable nouncheckedindexedaccess in ts config
    const forceFocusBinder = forceFocusBinders[0] as StoreBinder | undefined;

    const hasCurrent = !!this.current;
    const isCurrentIsDOM = hasCurrent && !!this.current?.parentElement;

    if (hasCurrent && isCurrentIsDOM && !forceFocusBinder) {
      console.warn('OneNavigation: cannot focusDefault() when there is a selected element', this.current);
      return;
    }

    if (hasCurrent && !isCurrentIsDOM) {
      console.warn(
        'OneNavigation: you called focusDefault() when there is a selected element, but this element is no longer in the DOM tree.'
      );
    }

    if (forceFocusBinders.length > 1) {
      console.warn('OneNavigation: multiple forceFocusOnMount detected, only the first one is used', forceFocusBinders);
    }

    // Use first available or forced binder
    const binder = inputBinder || forceFocusBinder || nearest(KEY_DOWN, this.store.defaultElement, binders);

    if (!binder) {
      return;
    }

    const element = binder.findNextFocusable(KEY_DOWN, this.store.defaultElement);

    if (element) {
      if (forceFocusBinder) {
        // ⚠️ TODO: Avoid forcing focus on mount twice
        forceFocusBinder.forceFocusOnMount = false;
      }

      this.focus(binder, element);
    }
  }

  /**
   * Handles key inputs by triggering navigation logic
   * @param key Key to handle
   */
  handleKey(key: Key): void {
    this.emit(key);

    // Handling back
    if (key === KEY_BACK) {
      if (this.store.onBack) {
        this.store.onBack(this.current);
      }
      return;
    }

    // There is no point handling rest of keys if nothing is focused
    if (!this.currentBinder || !this.current) {
      return;
    }

    // Handling enter
    if (key === KEY_ENTER) {
      if (this.store.onEnter) {
        this.store.onEnter(this.current);
      }
      return;
    }

    // Handling directional keys

    // Try to find somthing to focus in the current binder
    let next = this.currentBinder.findNextFocusable(key, this.current, true);
    if (next) {
      this.focus(this.currentBinder, next);
      return;
    }

    const currentBinders = this.getEnabledBinders();

    // Avoid errors and throw error on missing enabled binders
    if (!currentBinders.length) {
      console.warn('OneNavigation: no active binder available to focus');
      return;
    }

    // Binder is exited, try to find the next binder in that direction
    const nextBinder = nearest(key, this.currentBinder, currentBinders);
    if (nextBinder) {
      next = nextBinder.findNextFocusable(key, this.current, false);
      if (next) {
        this.focus(nextBinder, next);
      }
    }
  }

  /**
   * Gets store binder by specific HTML id
   * @param id binder id
   */
  getBinderById(id: string): StoreBinder | undefined {
    return this.binders.find((binder) => binder.el.getAttribute('data-binder') === id);
  }
}
