import { isClientSide } from '@canalplus/mycanal-commons';
import { IAPIConfigRaw, IAPICredentialsRaw } from '@canalplus/oneplayer-types';
import { OfferLocation, OfferZone, isTOfferLocation } from '@canalplus/sdk-core';
import { TRAILER } from '../../../constants/contentTypes';
import { PlayerPlaybackState } from '../../../constants/playerStates';
import { getPublicConfig } from '../../../helpers/config/config-helper';
import { msToSeconds } from '../../../helpers/time/time-helper';
import { type LaunchingPlayerError, sendLaunchingPlayerError } from '../../../helpers/tracking/tracking-helper';
import { changeChannel, sendLaunchPlayerTracking } from '../../../store/slices/player';

type GetFinalPlayerSettingsProps = {
  baseSettings: IAPIConfigRaw | null;
  credentials: IAPICredentialsRaw;
  dispatch: Redux.Dispatch;
  enableAd: boolean;
  locale: string | undefined;
  offerLocation: OfferLocation | undefined;
  offerZone: OfferZone;
  onExit?: () => void;
  onKillPlayer?: () => void;
};

export function getFinalPlayerSettings({
  baseSettings,
  credentials,
  dispatch,
  enableAd,
  locale,
  offerLocation,
  offerZone,
  onExit,
  onKillPlayer,
}: GetFinalPlayerSettingsProps): {
  credentials: IAPICredentialsRaw;
  settings: Partial<IAPIConfigRaw>;
} {
  // TODO: isStartOverAsReplayAvailable missing in oneplayer-types: update or remove obsolete key
  const { isStartOverAsReplayAvailable, startLiveProgramFromBeginning } = (baseSettings?.params as any) || {};

  const newSettings = {
    ...baseSettings,
    uiLanguage: locale,
    params: {
      ...baseSettings?.params,
      enableAd,
      offerZone,
      offerLocation: offerLocation && isTOfferLocation(offerLocation) ? offerLocation : undefined,
      isTabKeyNavigationCapturedByPlayer: true,
      // Hodor sends "startAt" in milliseconds but onePlayer needs seconds
      // That's the only place it needs to be converted
      // !warning! don't add startAt === 0 for OnePlayer when startAt is undefined on finalPlayerSettings
      startAt: baseSettings?.params?.startAt ? msToSeconds(baseSettings?.params.startAt) : undefined,
    },
    variant: getPublicConfig().PLAYER.ONE_PLAYER.VARIANT as any,
    events: {
      onExitButtonClicked: () => {
        onKillPlayer?.();
        onExit?.();
      },
      // TODO: incorrect (?) event types in oneplayer-types
      // temporary fix
      onError: (err: { data: LaunchingPlayerError }) => {
        if (
          isClientSide() &&
          (window as any).sendPlayerError &&
          typeof (window as any).sendPlayerError === 'function'
        ) {
          // Useful for Newrelic
          (window as any).sendPlayerError(err, baseSettings);
        }
        return err?.data ? sendLaunchingPlayerError(err.data) : null;
      },
      onChannelChange: ({ data }) => dispatch(changeChannel({ epgID: data.epgID })),
      // TODO: onPlayerStateChange?: () => void;
      onPlayerStateChange: (({ data }) => {
        if (data === PlayerPlaybackState.Ended && baseSettings?.contentFunctionalType === TRAILER) {
          onKillPlayer?.();
        }
      }) as () => void,
      // TODO: onVodContentLaunched?: () => void;
      onVodContentLaunched: ({ data }) => dispatch(sendLaunchPlayerTracking({ data })),
      // TODO: onLiveContentLaunched?: () => void;
      onLiveContentLaunched: ({ data }) =>
        dispatch(
          sendLaunchPlayerTracking({
            data,
            startFromBeginning: startLiveProgramFromBeginning,
            isStartOverAsReplayAvailable,
          })
        ),
      // TODO: onMultiContentLaunched?: () => void;
      onMultiContentLaunched: ({ data }) => dispatch(sendLaunchPlayerTracking({ data, isMultilive: true })),
      // TODO: onEndOfAvailableContent?: () => void;
      onEndOfAvailableContent: (() => {
        onKillPlayer?.();
      }) as () => void,
    } as any,
  } satisfies Partial<IAPIConfigRaw>;

  return { credentials, settings: newSettings };
}
