import { HeaderNavigation, HeaderNavigationProps } from '@canalplus/mycanal-sharedcomponent';
import { ApiV2NavigationItem } from '@dce-front/hodor-types/api/v2/authenticate/definitions';
import { useMemo } from 'react';
import { transformHeaderNavigation } from '../../../helpers/navigation/navigation-helper';
import I18n from '../../../lang';

export type HeaderNavigationMyCanalProps = Omit<HeaderNavigationProps, 'dropdownTriggerTitle' | 'isMobile'> & {
  navigation?: ApiV2NavigationItem[];
};

function HeaderNavigationMyCanal({
  navigation,
  ...restHeaderNavigationProps
}: HeaderNavigationMyCanalProps): JSX.Element {
  const { t } = I18n.useTranslation();

  const navLinks = useMemo(() => transformHeaderNavigation(navigation), [navigation]);
  const dropdownTriggerTitle = t('Navigation.dropdownLabel');

  return (
    <HeaderNavigation {...restHeaderNavigationProps} dropdownTriggerTitle={dropdownTriggerTitle}>
      {navLinks}
    </HeaderNavigation>
  );
}

export default HeaderNavigationMyCanal;
