import { Binder, useActiveLayer } from '@canalplus/one-navigation';
import classNames from 'classnames';
import { useState } from 'react';
import { LAYER_MORE_SETTINGS_VIDEO } from '../../../../../../../helpers/oneNavigation/layers';
import I18n, { type MyCanalTranslationsStringPaths } from '../../../../../../../lang';
import ChoiceButton from '../../../../../../ChoiceButton/ChoiceButton';
import styles from './SettingDetail.css';

type Option = {
  label: MyCanalTranslationsStringPaths;
  value?: boolean;
};

type SettingDetailProps = {
  title: string;
  description?: string;
  options: Option[];
  currentValue?: boolean;
  handler: (value?: boolean) => void;
};

/**
 * Used to display a setting detail with a title, a description, and multiple choice buttons to select a setting
 *
 * @param title       title of the setting
 * @param description description of the setting
 * @param options     array of options for the setting
 * @param currentValue current selected value of the setting
 * @param handler     function to call when the setting is changed
 */
function SettingDetail({ title, description, options, currentValue, handler }: SettingDetailProps): JSX.Element {
  const { t } = I18n.useTranslation();
  const [settingValue, setSettingValue] = useState<boolean | undefined>(currentValue);

  useActiveLayer(LAYER_MORE_SETTINGS_VIDEO, true);

  const settingHandler = (newValue?: boolean) => () => {
    if (settingValue !== newValue) {
      setSettingValue(newValue);
      handler(newValue);
    }
  };

  return (
    <div className={styles.settingDetail__container}>
      <Binder layer={LAYER_MORE_SETTINGS_VIDEO}>
        <h1 className={styles.settingDetail__title}>{title}</h1>
        {description && <p className={styles.settingDetail__subTitle}>{description}</p>}
        {options.map((option) => (
          <ChoiceButton
            key={`settingDetail-${option.value}`}
            classname={classNames(styles.settingDetail__button, 'settingDetail__button')}
            handler={settingHandler(option.value)}
            id={`binder_hdr_setting_${option.value}`}
            label={t(option.label)}
            isChecked={settingValue === option.value}
          />
        ))}
      </Binder>
    </div>
  );
}

export default SettingDetail;
