import { HeaderLayout, HeaderTheme, useHeaderTheme } from '@canalplus/mycanal-sharedcomponent';
import { Binder } from '@canalplus/one-navigation';
import { useSelector } from 'react-redux';
import { MIDDLEWARE_HEADER } from '../../helpers/oneNavigation/middleware';
import { featDisplayHeaderMenuSelector } from '../../selectors/application/application-selectors';
import {
  isHeaderActiveOnTVSelector,
  isHeaderDarkSelector,
  isHeaderTransparentSelector,
} from '../../selectors/header/header-selectors';
import { displayTVModeSelector } from '../../store/slices/displayMode-selectors';
import { isSearchActiveSelector } from '../../store/slices/search-selectors';
import { HeaderLogoMyCanalConnected } from './HeaderLogoMyCanal/HeaderLogoMyCanalConnected';
import { HeaderNavigationMyCanalConnected } from './HeaderNavigationMyCanal/HeaderNavigationMyCanalConnected';
import { HeaderNavigationTvConnected } from './HeaderNavigationTv/HeaderNavigationTvConnected';
import { HeaderWithSearchLayoutMyCanalConnected } from './HeaderWithSearchLayoutMyCanal/HeaderWithSearchLayoutMyCanalConnected';
import { UserMenuMyCanalConnected } from './UserMenuMyCanal/UserMenuMyCanalConnected';

function HeaderMyCanal(): JSX.Element {
  const isTvDevice = useSelector(displayTVModeSelector);
  const isHeaderDark = useSelector(isHeaderDarkSelector);
  const isHeaderTransparent = useSelector(isHeaderTransparentSelector);
  const isSearchActive = useSelector(isSearchActiveSelector);
  const isHeaderActiveOnTV = useSelector(isHeaderActiveOnTVSelector);
  const featDisplayHeaderMenu = useSelector(featDisplayHeaderMenuSelector);

  const headerTheme = useHeaderTheme(isHeaderDark, isHeaderTransparent, isSearchActive);
  const isHeaderThemeTransparent = headerTheme === HeaderTheme.TRANSPARENT;

  return isTvDevice ? (
    <Binder data-template="header" enabled={featDisplayHeaderMenu && isHeaderActiveOnTV} middleware={MIDDLEWARE_HEADER}>
      <HeaderLayout
        logo={<HeaderLogoMyCanalConnected isHeaderTransparent={isHeaderThemeTransparent} />}
        navigation={<HeaderNavigationTvConnected />}
        userOptions={<UserMenuMyCanalConnected />}
        isHeaderTransparent={isHeaderThemeTransparent}
      />
    </Binder>
  ) : (
    <HeaderWithSearchLayoutMyCanalConnected>
      <HeaderLayout
        logo={<HeaderLogoMyCanalConnected isHeaderTransparent={isHeaderThemeTransparent} />}
        navigation={<HeaderNavigationMyCanalConnected isHeaderTransparent={isHeaderThemeTransparent} />}
        userOptions={<UserMenuMyCanalConnected />}
        isHeaderTransparent={isHeaderThemeTransparent}
      />
    </HeaderWithSearchLayoutMyCanalConnected>
  );
}

export default HeaderMyCanal;
