import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../../../../../helpers/hooks/useAppDispatch';
import { userChangeSettings } from '../../../../../../../../store/slices/user';
import { isTrailerMutedSelector, settingsSelector } from '../../../../../../../../store/slices/user-selectors';

/**
 * Users can mute or unmute the trailers
 * This value is stored in local storage and redux store
 */
export const useIsTrailerMuted = (): {
  isTrailerMuted: boolean;
  toggleIsTrailerMuted: () => void;
} => {
  const dispatch = useAppDispatch();

  const isTrailerMuted = useSelector(isTrailerMutedSelector);
  const settings = useSelector(settingsSelector);

  const toggleIsTrailerMuted = () => {
    dispatch(userChangeSettings({ ...settings, isTrailerMuted: !isTrailerMuted }));
  };

  return {
    isTrailerMuted,
    toggleIsTrailerMuted,
  };
};
